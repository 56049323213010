import React, { useState, useEffect, useContext } from "react";
import { useLoadScript } from "../hooks/load-script";
import { resources } from "../services/config";
import TranslationContext from "../contexts/translation-context";
import {
  getGovernorates,
  getSearchableCategories,
} from "../services/data-request-center";
import PriceRange from "../screens/home-components/find-box-components/price-range";
import AreaRange from "../screens/home-components/find-box-components/area-range";
import { Redirect } from "react-router-dom";

const AdSideSearchBox = ({handleSearchParams}) => {
  useLoadScript(resources.bootstrapSelectPicker, false);
  useLoadScript(resources.selectpickerLauncher, false);
  const translator = useContext(TranslationContext).findBox;
  const [rendered, setRendered] = useState(false);
  const [reset, setReset] = useState(false);
  const [categories, setSearchableCategories] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [adPurpose, setAdPurpose] = useState("sale");
  const [districts, setDistricts] = useState([]);
  const [searchParams, setSearchParams] = useState({
    adPurpose: 0,
    areaSegment: 0,
  });
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    rendered && window.refreshAllSelectPickers();
  }, [adPurpose]);
  
  useEffect(() => {
    callSearchableCategories();
    callGetGovernorates();
    window.refreshAllSelectPickers();
  }, []);

  useEffect(() => {
    districts.length > 0 && window.refreshAllSelectPickers();
  }, [districts]);

  const callSearchableCategories = async () => {
    const { data, status } = await getSearchableCategories();
    status === 200 && setSearchableCategories(data.searchableCategories);
    status === 200 && window.refreshAllSelectPickers();
  };

  const callGetGovernorates = async () => {
    const { data, status } = await getGovernorates();
    status === 200 && setGovernorates(data.governorates);
    status === 200 && window.refreshAllSelectPickers();
  };

  const getDistricts = (governorateId) => {
    const governorateDistricts = governorates.find(
      (governorate) => governorate.id == governorateId
    );
    setDistricts(governorateDistricts.districts);
  };

  const updateSearchParams = (param) => {
    const currentParams = { ...searchParams };
    setSearchParams({ ...currentParams, ...param });
  };

  const startSearch = () => {
      handleSearchParams(searchParams);
  };

  return (
    <React.Fragment>
        {redirect && (
        <Redirect
          to={{
            pathname: "/search-properties-results",
            state: { searchParams },
          }}
        />
      )}
      <h4 className="font-s-title text-s-primary mb-4">
        {translator.searchProperty}
      </h4>
      <div className="row">
        <div className="col d-flex mb-3" style={{ justifyContent: "center" }}>
          <div
            className="d-flex mx-2"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <div
              className={`p-1 bg-${
                adPurpose === "sale" ? "s-candy" : "dark"
              } pointer`}
              style={{ width: "90px", height: "35px" }}
              onClick={() => {
                setAdPurpose("sale");
                updateSearchParams({ adPurpose: 0 });
              }}
            >
              <span className="text-white font-s-regular font-s-text font-w-s-medium text-center">
                {translator.sale}{" "}
                <i
                  className={`fas fa-check-circle font-s-regular text-white ${
                    adPurpose !== "sale" && "d-none"
                  } `}
                ></i>
              </span>
            </div>
          </div>
          <div
            className="d-flex mx-2"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <div
              className={`p-1 bg-${
                adPurpose === "rent" ? "s-deepWarning" : "dark"
              } pointer`}
              style={{ width: "90px", height: "35px" }}
              onClick={() => {
                setAdPurpose("rent");
                updateSearchParams({ adPurpose: 1 });
              }}
            >
              <span className="text-white font-s-regular font-s-text font-w-s-medium text-center">
                {translator.rent}{" "}
                <i
                  className={`fas fa-check-circle font-s-regular text-white ${
                    adPurpose !== "rent" && "d-none"
                  } `}
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="find-home-item custom-select">
            <select
              className="selectpicker"
              title={translator.category}
              data-container="body"
              onChange={(event) => {
                updateSearchParams({ categoryId: event.target.value });
              }}
            >
              {categories.map((category) => (
                <option key={category.uuid} value={category.uuid}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="find-home-item custom-select">
            <select
              className="selectpicker"
              title={translator.location}
              data-hide-disabled="true"
              data-live-search="true"
              data-container="body"
              onChange={(event) => {
                getDistricts(event.target.value);
                updateSearchParams({
                  governorateId: event.target.value,
                });
              }}
            >
              {governorates.map((governate) => (
                <option key={governate.id} value={governate.id}>
                  {governate.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="find-home-item custom-select">
            <select
              className="selectpicker"
              title={translator.subLocation}
              data-hide-disabled="true"
              data-live-search="true"
              data-container="body"
              onChange={(event) => {
                updateSearchParams({ districtId: event.target.value });
              }}
            >
              {districts.length > 0 && (
                <option value="0">{translator.allCities}</option>
              )}
              {districts.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-6 pr-1">
          <div className="find-home-item custom-select">
            <select
              className="selectpicker"
              title={translator.priceRange}
              data-container="body"
              onChange={(event) => {
                updateSearchParams({
                  priceSegment: event.target.value,
                });
              }}
            >
              <PriceRange adPurpose={adPurpose} />
            </select>
          </div>
        </div>
        <div className="col-6 pl-1">
          <div className="find-home-item custom-select">
            <select
              className="selectpicker"
              title={translator.areaRange}
              data-container="body"
              onChange={(event) => {
                updateSearchParams({
                  areaSegment: event.target.value,
                });
              }}
            >
              <AreaRange />
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-md-12 col-sm-5 col-xs-12">
              <div className="find-home-item">
                <button
                  className="btn btn-block bg-s-primary text-white font-s-text font-s-medium font-w-s-medium text-capitalize"
                  style={{ height: "40px" }}
                  onClick={startSearch}
                >
                  {translator.search}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdSideSearchBox;
