import React, { Fragment, useState, useEffect, useContext } from "react";
import { getAllAgents } from "../../services/data-request-center";
import TranslationContext from "../../contexts/translation-context";
import LoadingComponent from "../../components/loading-component";
import PaginationBar from "../../components/ui-components/pagination-bar";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import { Link } from "react-router-dom";

const Agents = () => {
  const translator = {
    ...useContext(TranslationContext).ads,
    ...useContext(TranslationContext).others,
  };
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState(null);
  const { direction } = useContext(LayoutDirectionContext);
  const [paginate, setPaginate] = useState("");
  useEffect(() => {
    callGetAllAgents();
  }, []);

  useEffect(() => {
    paginate !== "" && callGetAllAgents();
  }, [paginate]);

  const callGetAllAgents = async () => {
    const { data, status } = await getAllAgents(paginate);
    if (status === 200) {
      setAgents(data);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingComponent />
      ) : (
        <section>
          <div
            className="d-inline-block w-100"
            style={{
              background: 'url("/assets/images/customizer/pattern/3.png")',
            }}
          >
            <div className="container-fluid pt-115 pb-60">
              <div className="card text-center py-5 px-5 card-limit-size">
                <div className="row mb-4">
                  <i className="fas fa-user-tie font-s-large text-s-pine"></i>{" "}
                  <h3 className="mx-2 text-capitalize font-s-title">
                    {translator.ourAgents}
                  </h3>
                </div>
                <div className="row">
                  {agents.agents.map((agent) => {
                    return (
                      <div className="col-sm-12 col-md-6 my-3">
                        <div className="d-flex flex-column py-5 border border-s-cream rounded">
                          <div className="d-flex flex-column mb-2">
                            <span className="font-s-title font-s-medium font-w-s-bold text-capitalize">
                              {agent.profile.name}
                            </span>
                            <span className="font-s-text text-s-primary font-s-small text-capitalize">
                              {translator.adsCount}{" "}
                              <span className="text-s-secondary">
                                {agent.profile.ads_count}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-center">
                            <Link to={`show-agent/${agent.id}`}>
                              <div
                                className="rounded border border-s-cream border-3"
                                style={{
                                  height: "300px",
                                  width: "250px",
                                  backgroundImage: `url(${agent.profile.profileImgUrl})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                            </Link>
                          </div>

                          <div className="d-flex flex-column mt-4 px-4">
                            <div className="d-flex justify-content-start align-items-center ">
                              <span className="font-s-title text-s-deepWarning font-s-regular text-capitalize">
                                {translator.directContacts}
                              </span>
                            </div>
                            <div className="d-flex justify-content-start align-items-center ">
                              <img
                                src="assets/images/icons/phone-2.png"
                                alt=""
                                style={{ height: "15px" }}
                              />
                              <div className="px-1"></div>
                              <span
                                className="overflow-auto"
                                style={{ direction: "ltr" }}
                              >
                                {agent.mobile}
                              </span>
                            </div>
                            <div className="d-flex justify-content-start align-items-center ">
                              <img
                                src="assets/images/icons/mail-close.png"
                                alt=""
                                style={{ height: "15px" }}
                              />
                              <div className="px-1"></div>
                              <span className="overflow-auto">
                                {agent.email}
                              </span>
                            </div>
                            <Link to={`show-agent/${agent.id}`}>
                            <button className="btn btn-block border-s-cream rounded mt-3">
                              <span className="font-s-text font-s-regular font-w-s-light text-s-secondary">
                                {translator.agentProfileShow}
                              </span>
                            </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {agents.last_page > 1 && (
                  <PaginationBar
                    pagesCount={agents.last_page}
                    currentPage={agents.current_page}
                    firstElement={agents.first_item}
                    lastElement={agents.last_item}
                    total={agents.total}
                    direction={direction}
                    translator={translator}
                    setPaginate={setPaginate}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Agents;
