import React, { useContext, Fragment, useState, useEffect } from "react";
import firebase from "firebase";
import "firebase/auth";
import {
  firebaseInitConfig,
  colorsPalette,
  resources
} from "../../services/config";
import { useLoadScript } from "../../hooks/load-script";
import TranslationContext from "../../contexts/translation-context";
import ProfileContext from '../../contexts/profile-context';
import Button from "@material-ui/core/Button";
import { PhonelinkRing } from "@material-ui/icons";
import ErrorHandler from "../../helpers/error-handler";
import { firebaseCodeVerification } from "../../services/data-request-center";

const PhoneAuthentication = ({profile}) => {
  useLoadScript(resources.firebase, true);
  let recaptchaVerifier;
  const [confirmationResponse, setConfirmationResponse] = useState("");
  const translations = useContext(TranslationContext).sign;
  const {setProfile} = useContext(ProfileContext);
  const [requsetVerification, setRequsetVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [displayStatus, setDisplayStatus] = useState("none");
  const [responseMessage, setResponseMessage] = useState();
  const [responseStatus, setResponseStatus] = useState();

  useEffect(() => {
    firebase.initializeApp(firebaseInitConfig);
    //setPhoneNumber();
    renderRecapcha();
    document.getElementById("send-code").click();
  }, []);

  const renderRecapcha = () => {
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recapcha-container",
      {
        size: "invisible",
        callback: function (response) {
          phoneAuth();
        },
      }
    );
    recaptchaVerifier.render();
  };

  const phoneAuth = async () => {
    var number = profile.mobile;
    try {
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(number, recaptchaVerifier);
      setConfirmationResponse(confirmationResult);
      setRequsetVerification(true);
      displayMessage(200, translations.codeSent, 5000);
    } catch (error) {
      displayMessage(500, translations.codeFailed, 5000);
    }
  };

  const codeverify = async () => {
    try {
      const status  = await firebaseCodeVerification(
        confirmationResponse,
        verificationCode
      );
      console.log("code verify status", status)
      if (status === 200) {
        let updatedProfile = {...profile};
        updatedProfile.isVerified = true;
        await displayMessage(200, translations.accountAtivated, 5000);
        setProfile(updatedProfile);
      } else {
        console.log("status not 200");
        displayMessage(status, translations.ativationFailed, 5000);
      }
    } catch (error) {
      const errorCode = error.code;
      console.log("error code", errorCode)
      if (errorCode === "auth/invalid-verification-code") {
        displayMessage(400, translations.codeInCorrect, 5000);
      } else if (errorCode === "auth/missing-verification-code") {
        console.log("undefined")
        displayMessage(400, translations.codeMissing, 5000);
      } else if (errorCode === "undefined") {
        console.log("undefined")
        displayMessage(500, translations.ativationFailed, 5000);
      }
    }
  };

  const setPhoneNumber = () => {
    /* let code = profile.code;
    let number = profile.phone;
    if (code.charAt(code.length - 1) === "0" && number.charAt(0) === "0") {
      phoneNumber = code + number.slice(1);
    } else {
      phoneNumber = code + number;
    } */
  };

  const displayMessage = (status, message, time) => {
    return new Promise((resolve) => {
      setResponseStatus(status);
      setResponseMessage(message);
      setDisplayStatus("block");
      setTimeout(() => {
        setDisplayStatus("none");
        setResponseStatus("");
        setResponseMessage("");
        resolve();
      }, time);
    });
  };

  return (
    <Fragment>
      <div
        style={{
          background: 'url("assets/images/customizer/pattern/3.png")',
        }}
        className="h-100 d-inline-block w-100"
      >
        <div className="container-fluid pt-115 pb-60">
          <div className="card bg-gray text-center card-limit-size">
            <div className="bg-gray" style={{ padding: "5px 20px" }}>
              <div className="pt-5">
                <img
                  src="assets/images/bg/qahraman-mobile-verification.svg"
                  height="200px"
                  className="card-img-top"
                  alt="qahrman-sign"
                />
              </div>
              <div className="card-body">
                <div className="mb-4">
                  <h2 className="text-s-secondary">{translations.welcome}</h2>
                </div>
                <div>
                  <h4 className="mb-2 text-s-secondary font-weight-bolder">
                    {translations.phoneVerification}
                  </h4>
                  <div className="form justify-content-center my-3">
                    <div className="row mb-2"></div>

                    <div className="my-3 col-sm-12 ">
                      {requsetVerification ? (
                        <div className="d-flex flex-column align-items-center">
                          <input
                            type="text"
                            style={{ maxWidth: "300px", height:"60px" }}
                            onChange={(event) =>
                              setVerificationCode(event.target.value)
                            }
                            className="form-control font-s-medium font-w-s-medium text-center"
                            placeholder={translations.enterVerification}
                          />
                          <div
                            style={{
                              width: "300px",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="large"
                              style={{
                                width: "300px",
                                backgroundColor: colorsPalette.secondary,
                                color: "white",
                              }}
                              onClick={codeverify}
                            >
                              <PhonelinkRing />

                              <span className="btn-text text-capitalize mx-1 my-auto">
                                {translations.reqActivationCode}
                              </span>
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="d-none flex-column align-items-center">
                          <div
                            style={{
                              width: "300px",
                            }}
                          >
                            <Button
                              id="send-code"
                              variant="contained"
                              color="primary"
                              size="large"
                              style={{ width: "300px" }}
                              onClick={() => phoneAuth()}
                            >
                              <PhonelinkRing />

                              <span className="btn-text text-capitalize mx-1 my-auto">
                                {translations.reqActivationCode}
                              </span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div id="recapcha-container"></div>
              </div>
              <div
                className="d-flex justify-content-start my-1 mx-3 "
                style={{ padding: "0px 20px" }}
              >
                <p className="text-muted font-weight-normal">
                  {translations.activationDisclaimer}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-sm-10 offset-sm-1 col-md-8 offset-md-2"
        style={{ display: displayStatus }}
      >
        <ErrorHandler status={responseStatus} message={responseMessage} />
      </div>
      
    </Fragment>
  );
};

export default PhoneAuthentication;
