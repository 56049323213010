import axios from "axios";

const success = (response) => {
  return response;
};

const failed = (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 599;
  if (expectedError) {
    return error.response;
  }
  return { data: null, status: 0 };
};

axios.interceptors.response.use(success, failed);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
