import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getUserFavorites } from "../../services/data-request-center";
import TranslationContext from "../../contexts/translation-context";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import LoadingComponent from "../../components/loading-component";
import MyAccount from "./my-account";
import PaginationBar from "../../components/ui-components/pagination-bar";

const MyFavorites = (props) => {
  const translator = useContext(TranslationContext).ads;
  const { direction } = useContext(LayoutDirectionContext);
  const [myFavorites, setMyFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginate, setPaginate] = useState("");

  useEffect(() => {
    callGetUserFavorites();
  }, []);

  useEffect(() => {
    myFavorites && setLoading(false);
  }, [myFavorites]);

  

  useEffect(() => {
    paginate !== "" && callGetUserFavorites();
  }, [paginate]);

  const callGetUserFavorites = async () => {
    const { data, status } = await getUserFavorites(paginate);
    status === 200 && setMyFavorites({...data.favorites});
  };

  return (
    <React.Fragment>
      <MyAccount routeInfo={props}>
        {loading ? (
          <LoadingComponent />
        ) : typeof myFavorites.favorites !== 'undefined' && Object.keys(myFavorites.favorites).length ? (
          <React.Fragment>
            <div className="d-flex">
              <h1 className="font-s-title">{translator.myFavorites}</h1>
            </div>
            <div className="d-flex align-items-baseline bg-s-primary py-1 my-3">
              <div style={{ flex: 1 }}>
                <i className="fas fa-camera text-s-cream font-s-medium"></i>
              </div>
              <div style={{ flex: 3 }}>
                <span className="text-s-cream font-s-title font-s-medium font-w-s-medium text-capitalize">
                  {translator.title}
                </span>
              </div>
              <div style={{ flex: 1 }}>
                <span className="text-s-cream font-s-title font-s-medium font-w-s-medium text-capitalize">
                  {translator.district}
                </span>
              </div>
              <div style={{ flex: 1 }}>
                <span className="text-s-cream font-s-title font-s-medium font-w-s-medium text-capitalize">
                  {translator.price}
                </span>
              </div>
            </div>
            {myFavorites.favorites.map((property, index) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-baseline border-bottom my-3">
                    <Link to={`/show-property-ad/${property.id}`}>
                      <div
                        className="bg-s-primary"
                        style={{ height: "75px", width: "125px", flex: 1 }}
                      >
                        <img
                          src={property.default_image}
                          className="h-100"
                          alt=""
                        />
                      </div>
                    </Link>

                    <div
                      className="flex-3 mx-2 text-s-start overflow-hidden"
                      style={{ flex: 3, maxHeight: "60px" }}
                    >
                      <Link to={`/show-property-ad/${property.id}`}>
                        <span className="text-s-primary font-s-text">
                          {property.title}
                        </span>
                      </Link>
                    </div>
                    <div style={{ flex: 1 }}>
                      <span className="text-s-primary font-s-text overflow-hidden">
                        {property.district.name}
                      </span>
                    </div>
                    <div style={{ flex: 1 }}>
                      <span className="text-s-primary font-s-regular font-s-text">
                        {property.price}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            {myFavorites.last_page > 1 && (
              <PaginationBar
                pagesCount={myFavorites.last_page}
                currentPage={myFavorites.current_page}
                firstElement={myFavorites.first_item}
                lastElement={myFavorites.last_item}
                total={myFavorites.total}
                direction={direction}
                translator={translator}
                setPaginate={setPaginate}
              />
            )}
          </React.Fragment>
        ) : (
          <span className="text-s-secondary font-s-text font-s-large">
            {translator.noFavorites}
          </span>
        )}
      </MyAccount>
    </React.Fragment>
  );
};

export default MyFavorites;
