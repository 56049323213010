import React, { useState, useEffect, useContext } from "react";
import MyAccount from "./my-account";
import LoadingComponent from "../../components/loading-component";
import TranslationContext from "../../contexts/translation-context";
import ProfileContext from "../../contexts/profile-context";
import {
  getContactsMethods,
  addNewContacts,
  deleteUserContacts,
} from "../../services/data-request-center";
import { useLoadStyle } from "../../hooks/load-style";

const MyContacts = (props) => {
  const translator = useContext(TranslationContext).sign;
  const { profile, setProfile } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [userContacts, setUserContacts] = useState([]);
  const [contactsMethods, setContactsMethods] = useState([]);
  const language = localStorage.getItem("language");
  const cssDirectory = language === "ar" ? "css-rtl" : "css";
  useLoadStyle("/assets/" + cssDirectory + "/custom/select-picker.css");
  const [activateNewContacts, setActivateNewContacts] = useState(false);
  const [rowsCounter, setRowCounter] = useState(0);
  const [validationActive, setValidationActive] = useState(false);
  const [activateForm, setActivateForm] = useState(false);
  const [newContactsValues, setNewContactsValues] = useState([]);

  useEffect(() => {
    profile.contacts.length > 0 && setUserContacts(profile.contacts);
    callGetContactsMethods();
    setLoading(true);
  }, []);

  useEffect(() => {
    !loading && window.refreshAllSelectPickers();
  }, [loading]);

  useEffect(() => {
    activateNewContacts && window.refreshAllSelectPickers();
  }, [activateNewContacts]);

  const callGetContactsMethods = async () => {
    const { data, status } = await getContactsMethods();
    if (status === 200) {
      setContactsMethods(data.contactsMethods);
      setLoading(false);
    }
  };

  const updaterowContactIcon = (contactId, iconClass) => {
    document.getElementById("select-contact").selectedIndex = 0;
    window.refreshAllSelectPickers();
    if (
      rowsCounter === 0 ||
      (rowsCounter > 0 && newContactsValues[rowsCounter - 1].value !== "")
    ) {
      const tempContainer = [...newContactsValues];
      tempContainer[rowsCounter] = {
        id: contactId,
        icon: iconClass,
        value: "",
      };
      setNewContactsValues([...tempContainer]);
      !activateForm && setActivateForm(true);
      setRowCounter(rowsCounter + 1);
    } else {
      setValidationActive(true);
    }
  };

  const updateNewContactsData = (value, index) => {
    setValidationActive(false);
    let tempContainer = [...newContactsValues];
    tempContainer[index] = {
      id: newContactsValues[index].id,
      icon: newContactsValues[index].icon,
      value: value,
    };
    setNewContactsValues([...tempContainer]);
  };

  const removeNewContactsData = (index) => {
    let tempContainer = [...newContactsValues];
    tempContainer.splice(index, 1);
    setNewContactsValues([...tempContainer]);
    setRowCounter(rowsCounter - 1);
  };

  const saveNewContacts = async () => {
    if (validationActive || newContactsValues.length === 0) return 0;
    let newContactsData = [];
    newContactsValues.map((contact) => {
      if (contact.value !== "") {
        newContactsData.push({
          contact_method_id: contact.id,
          value: contact.value,
        });
      }
    });
    if(newContactsData.length === 0) return 0;
    const { data, status } = await addNewContacts({
      contacts: newContactsData,
    });
    setUserContacts(data.contacts);
    setActivateNewContacts(false);
    setRowCounter(0);
    setValidationActive(false);
    setActivateForm(false);
    setNewContactsValues([]);
  };

  const deleteUserContact = async (contactId) => {
    const { status } = await deleteUserContacts(contactId);
    if (status === 200) {
      let tempContainer = [...userContacts];
      const index = tempContainer.findIndex(
        (contact) => contact.id === contactId
      );
      tempContainer.splice(index, 1);
      setUserContacts(tempContainer);
    }
  };

  return (
    <React.Fragment>
      <MyAccount routeInfo={props}>
        <div className="d-flex">
          <h1 className="font-s-title text-capitalize">
            {translator.myContacts}
          </h1>
        </div>
        <div className="row mb-5">
          <div className="col-12 my-3">
            {loading ? (
              <LoadingComponent />
            ) : (
              userContacts.length > 0 &&
              userContacts.map((userContact, index) => {
                return (
                  <div className="row" key={"user-contact-row-" + index}>
                    <div className="col-sm-12 col-md-6 offset-md-3">
                      <div className="input-group my-2">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text bg-s-primary align-items-center justify-content-center"
                            style={{ width: "40px" }}
                          >
                            <i
                              className={`${
                                userContact.contact_method
                                  ? userContact.contact_method.icon
                                  : userContact.method.icon
                              } text-s-secondary font-s-medium`}
                            ></i>
                          </span>
                        </div>
                        <input
                          className="form-control bg-s-cream font-s-text font-s-regular font-w-s-medium"
                          value={userContact.value}
                          disabled
                        />
                        <div className="input-group-prepend pointer">
                          <span
                            className="input-group-text text-s-candy pointer"
                            style={{ width: "40px" }}
                            onClick={() => deleteUserContact(userContact.id)}
                          >
                            <i className="fas fa-trash font-s-medium"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ position: "absolute" }}>
            <div className="dropdown-divider border-dark w-100 my-0"></div>
            <div
              className="d-flex flex-column justify-content-center  align-items-center"
              style={{ position: "relative", top: "-50px" }}
            >
              <div
                style={{ height: "100px", width: "100px" }}
                className="d-flex bg-s-primary border border-s-secondary regular-border rounded-circle justify-content-center align-items-center pointer"
                onClick={() => setActivateNewContacts(true)}
              >
                <i className="fas fa-phone-volume fa-3x text-s-secondary animate__animated animate__headShake animate__slower animate__repeat-3 fa-flip-horizontal "></i>
              </div>
              <span className="font-s-text font-s-large font-w-s-light text-s-secondary my-3 text-capitalize">
                {translator.contactAddition}
              </span>
            </div>
          </div>
          {activateNewContacts && (
            <React.Fragment>
              <div
                className="col-sm-12 col-md-6 offset-md-3"
                style={{ marginTop: "125px" }}
              >
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text bg-s-secondary align-items-center justify-content-center py-0"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <i className={`fa fa-plus text-white font-s-medium`}></i>
                    </span>
                  </div>
                  <select
                    id="select-contact"
                    defaultValue={0}
                    className="selectpicker form-control"
                    data-hide-disabled="true"
                    data-live-search="false"
                    onChange={(event) => {
                      updaterowContactIcon(
                        event.target.value,
                        event.target.options[event.target.selectedIndex].dataset
                          .icon
                      );
                    }}
                  >
                    <option value="0" disabled className="text-capitalize">
                      {translator.addContact}
                    </option>
                    {contactsMethods.map((contact) => (
                      <option
                        key={contact.name}
                        value={contact.id}
                        data-icon={contact.icon}
                      >
                        {contact.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 my-3">
                {newContactsValues.length > 0 &&
                  newContactsValues.map((newContact, index) => {
                    return (
                      <div className="row" key={"contact-row-" + index}>
                        <div className="col-sm-12 col-md-6 offset-md-3">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text bg-s-primary align-items-center justify-content-center"
                                style={{ width: "40px" }}
                              >
                                <i
                                  className={`${newContact.icon} text-white font-s-medium`}
                                ></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control bg-white font-s-text font-s-medium"
                              aria-describedby="basic-addon1"
                              value={newContact.value}
                              onChange={(event) => {
                                updateNewContactsData(
                                  event.target.value,
                                  index
                                );
                              }}
                            />
                            <div className="input-group-prepend pointer">
                              <span
                                className="input-group-text bg-cream text-white pointer"
                                style={{ width: "40px" }}
                                onClick={() => removeNewContactsData(index)}
                              >
                                <i className="fas fa-minus-circle text-s-candy font-s-medium"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="col-sm-12 col-md-6 offset-md-3">
                  {validationActive && (
                    <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
                      <i className="fas fa-exclamation-circle"></i>{" "}
                      {translator.required}
                    </span>
                  )}
                </div>
              </div>

              {newContactsValues.length > 0 && activateForm && (
                <div className="col-sm-12">
                  <div className="row" style={{ marginTop: "25px" }}>
                    <div className="col-sm-12 col-md-3 offset-md-3 mb-3">
                      <button
                        type="submit"
                        className="btn btn-block bg-s-primary uniform-height"
                        onClick={() => saveNewContacts()}
                      >
                        <span className="text-white font-s-medium font-w-s-medium fonts-s-text">
                          {translator.save}
                        </span>
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-3 mb-3">
                      <button className="btn btn-block bg-secondary uniform-height">
                        <span className="text-white font-s-medium font-w-s-medium fonts-s-text">
                          {translator.cancel}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          <div style={{ height: "75px" }}></div>
        </div>
      </MyAccount>
    </React.Fragment>
  );
};

export default MyContacts;
