export const articles = {
  ar: {
    articlesDept: "المدونة العقارية",
    articlesText:
      "مقالات يكتبها متخصصون فى مجال العقارات تتنوع بين النصائح و الأخبار ومعلومات متنوعة",
    articleDate: "تاريخ المقالة",
    articlesSerial: "سلسلة مقالات المدونة العقارية",
    readMore: "إقرأ المزيد ...",
    articleTips: 'نصائح و وصايا',
    articleNews: 'أخبار عقارية',
    articleFinishes: 'تشطيبات و ديكورات',

  },
  en: {
    articlesDept: "real estate blog",
    articlesText:
      "collection of articles written by specialists in real estate career varies between tips, news and miscellaneous information",
    articleDate: "article date",
    articlesSerial: "real estate blog articles series",
    readMore: 'Read more ...',
    articleTips: 'tips and recommendations',
    articleNews: 'real estate news',
    articleFinishes: 'finishes and decorations',
  },
};
