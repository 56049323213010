import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { colorsPalette } from "../services/config";

const LoadingComponent = () => {
  return (
    <div
      className="d-flex w-100 align-items-center justify-content-center"
      style={{ height: "100vh", backgroundColor: colorsPalette.primary }}
    >
      <CircularProgress
        size={125}
        thickness={3}
        style={{
          /* height: "100px",
          width: "100px", */
          color: colorsPalette.secondary,
        }}
      />
    </div>
  );
};

export default LoadingComponent;
