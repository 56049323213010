export const findBox = {
  ar: {
    category: "التصنيف",
    subCategory: "التصنيف الفرعى",
    title: "إبحث عن",
    subTitle: "عقارك هنا",
    advancedSearch: 'البحث المتقدم',
    location: "المحافظة",
    subLocation: "المنطقة",
    areaRange: "  حدد نطاق المساحة م٢",
    minArea: "الحد الأدنى للمساحة م٢",
    maxArea: "الحد الأقصى للمساحة م٢",
    bedRooms: "عدد غرف النوم",
    beadRooms: "عدد الحمامات ",
    priceRange: "حدد نطاق السعر",
    search: "إبحث",
    sale: "للبيع",
    rent: "للإيجار",
    selectGovernoerate: "حدد المحافظة المطلوبة",
    selectDistrict: "إختر مدينة أو منطقة",
    allCities: "كامل نطاق المحافظة",
    areaL_100: "أقل من ١٠٠ متر",
    areaB_100_200: "من ١٠٠ - ٢٠٠ متر",
    areaB_200_300: "من ٢٠٠ - ٣٠٠ متر",
    areaB_300_500: "من ٣٠٠ - ٥٠٠ متر",
    areaB_500_1000: "من ٥٠٠ - ١٠٠٠ متر",
    areaM_1000: "أكثر من ١٠٠٠ متر",
    rent_L_1k: "أقل من ١٠٠٠ ج.م",
    rent_B_1k_2k: "من ١٠٠٠ - ٢٠٠٠ ج.م",
    rent_B_2k_3k: "من ٢٠٠٠ - ٣٠٠٠ ج.م",
    rent_B_3k_5k: "من ٣٠٠٠ - ٥٠٠٠ ج.م",
    rent_B_5k_10k: "من ٥٠٠٠ - ١٠,٠٠٠ ج.م",
    rent_B_10k_20k: "من ١٠,٠٠٠ - ٢٠,٠٠٠ ج.م",
    rent_B_20k_50k: "من ٢٠,٠٠٠ - ٥٠,٠٠٠ ج.م",
    rent_M_50k: "أكثر من ٥٠,٠٠٠ ج.م",
    sale_L_100k: "أقل من ١٠٠ ألف ج.م",
    sale_B_100k_200k: "من ١٠٠ ألف - ٢٠٠ ألف ج.م",
    sale_B_200k_300k: "من ٢٠٠ ألف - ٣٠٠ ألف ج.م",
    sale_B_300k_500k: "من ٣٠٠ ألف - ٥٠٠ ألف ج.م",
    sale_B_500k_1m: "من ٥٠٠ ألف - ١ مليون ج.م",
    sale_B_1m_2m: "من مليون - ٢ مليون ج.م",
    sale_B_2m_3m: "من ٢ مليون - ٣ مليون ج.م",
    sale_B_3m_5m: "من ٣ مليون - ٥ مليون ج.م",
    sale_B_5m_10m: "من ٥ مليون - ١٠ مليون ج.م",
    sale_M_10m: "أكثر من ١٠ مليون ج.م",
    cancel: "إلغاء",
    searchProperty: "إبحث عن عقار",
    noResults: "لا توجد نتائج تطابق بحثك",
    searchError: "خطأ أثناء عملية البحث برجاء المحاولة لاحقاً",
    advancedSearchHint: 'إظهار فلاتر البحث المتقدم يتطلب تحديد التصنيف أولاً.',
    searchMandatoryHint: 'عملية البحث تتطلب إختيار المحافظة و المنطقة محل البحث.',
    choices: 'الاختيارات',
    select: 'اختر',
  },
  en: {
    category: "Category",
    subCategory: "Sub Category",
    title: "find your",
    subTitle: "property here",
    advancedSearch: 'advanced search',
    location: "Location",
    subLocation: "Sub Location",
    areaRange: "Select area range (sq m)",
    minArea: "Minimum area (sq m)",
    maxArea: "Maximum area (sq m)",
    bedRooms: "Bedrooms count",
    beadRooms: "Beadrooms count",
    priceRange: "Price range",
    search: "Search",
    sale: "For Sale",
    rent: "For Rent",
    selectGovernoerate: "Select governorate to determine search domain",
    selectDistrict: "Select District",
    allCities: "all governorate districts",
    areaL_100: "less than 100 m",
    areaB_100_200: "from 100 - 200 m",
    areaB_200_300: "from 200 - 300 m",
    areaB_300_500: "from 300 - 500 m",
    areaB_500_1000: "from 500 - 1000 m",
    areaM_1000: "more than 1000 m",
    rent_L_1k: "less than 1000 EGP",
    rent_B_1k_2k: "from 1000 - 2000 EGP",
    rent_B_2k_3k: "from 2000 - 3000 EGP",
    rent_B_3k_5k: "from 3000 - 5000 EGP",
    rent_B_5k_10k: "from 5000 - 10,000 EGP",
    rent_B_10k_20k: "from 10,000 - 20,000 EGP",
    rent_B_20k_50k: "from 20,000 - 50,000 EGP",
    rent_M_50k: "more than 50,000 EGP",
    sale_L_100k: "less than 100,000 EGP",
    sale_B_100k_200k: "from 100,000 - 200,000 EGP",
    sale_B_200k_300k: "from 200,000 - 300,000 EGP",
    sale_B_300k_500k: "from 300,000 - 500,000 EGP",
    sale_B_500k_1m: "from 500,000 - 1,000,000 EGP",
    sale_B_1m_2m: "from 1,000,000 - 2,000,000 EGP",
    sale_B_2m_3m: "from 2,000,000 - 3,000,000 EGP",
    sale_B_3m_5m: "from 3,000,000 - 5,000,000 EGP",
    sale_B_5m_10m: "from 5,000,000 - 10,000,000 EGP",
    sale_M_10m: "more than 10 million",
    cancel: "Cancel",
    searchProperty: "Search For Property",
    noResults: "No results found match your search parameters",
    searchError: "Search operation failed, please try again later.",
    advancedSearchHint: 'To use search filters you have to select category at first.',
    searchMandatoryHint: 'Searching operation require selecting the searching domain governorate & district.',
    choices: 'choices:',
    select: 'select',
  },
};
