import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  RouteSigned,
  RouteUnSigned,
  RouteActivated,
  RouteCompleted,
  RouteNonActivated,
} from "./services/route-protector";
import Home from "./screens/home";
import SignIn from "./screens/sign/sign-in";
import SignUp from "./screens/sign/sign-up";
import PhoneAuthentication from "./screens/sign/phone-authentication";
import PropertiesAdsCreation from "./screens/properties-ads/create-ad";
import PropertiesAdsShow from "./screens/properties-ads/show-ad";
import PageNotFound from './screens/404';
import FinishAd from './screens/properties-ads/create-ad-components/finish-ad';
import SearchResults from './screens/search-properties/search-results';
import MyProperties from './screens/profile/my-properties';
import MyFavorites from './screens/profile/my-favorites';
import MyProfile from './screens/profile/my-profile';
import MyContacts from './screens/profile/my-contacts';
import ChangePassword from './screens/profile/change-password';
import AdvancedSearch from './screens/search-properties/advanced-search';
import ArticleScreen from './screens/articles/article-screen';
import ArticlesScreen from './screens/articles/articles-screen';
import Agents from "./screens/agents/agents";
import ShowAgent from "./screens/agents/show-agent";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/404" exact component={PageNotFound} />
      <Route path="/finish-ad" component={FinishAd} />
      <Route path="/show-property-ad/:id" exact component={PropertiesAdsShow} />
      <Route
        path="/search-properties-results"
        exact
        render={(props) => <SearchResults {...props} />}
      />
      <Route path='/advanced-search' component={AdvancedSearch} />
      <Route path='/show-article/:id' component={ArticleScreen} />
      <Route path='/show-articles/:id' exact component={ArticlesScreen} />
      <Route path='/agents' exact component={Agents} />
      <Route path='/show-agent/:id' exact component={ShowAgent} />
      <RouteUnSigned path="/sign-in" component={SignIn} />
      <RouteUnSigned path="/sign-up" component={SignUp} />
      <RouteUnSigned path="/register-agent" component={SignUp} />
      <RouteNonActivated
        path="/phone-authentication"
        component={PhoneAuthentication}
      />
      <RouteActivated
        path="/create-property-ad"
        component={PropertiesAdsCreation}
      />
      <RouteActivated path="/my-properties-ads" component={MyProperties} />
      <RouteActivated path="/my-favorites" component={MyFavorites} />
      <RouteSigned path="/my-profile" component={MyProfile} />
      <RouteSigned path="/my-contacts" component={MyContacts} />
      <RouteActivated path="/change-password" component={ChangePassword} />
      <Redirect from="/" to="/" />
    </Switch>
  );
};

export default Routes;
