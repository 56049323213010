import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TranslationContext from "../../contexts/translation-context";
import { colorsPalette } from "../../services/config";
import { Tooltip } from "@material-ui/core";

const AdsListView = ({ propertiesAds, handleToggleFavorite }) => {
  const translator = useContext(TranslationContext).ads;
  return (
    <React.Fragment>
      {propertiesAds.length > 0 ? (
        propertiesAds.map((propertyAd) => (
          <div
            className="d-flex flat-item justify-content-start bg-s-primary p-3 shadow-lg mb-4 w-100 rounded"
            key={propertyAd.id}
          >
            <div
              className="flat-item-image col-5 px-0 bg-s-primary rounded"
              style={{
                height: "200px",
              }}
            >
              <Link to={`/show-property-ad/${propertyAd.id}`}>
                <div
                  className="rounded"
                  style={{
                    height: "200px",
                  }}
                >
                  <img
                    src={propertyAd.default_image}
                    className="h-100 w-100 rounded"
                    style={{ border: "1px solid " + colorsPalette.cream }}
                    alt=""
                  />
                </div>
                {
                  <div
                    className="set-cover-btn p-2 d-flex"
                    style={{
                      zIndex: "2",
                      borderRadius: "50%",
                      backgroundColor: "rgba(0,0,0,0.7)",
                    }}
                  >
                    <Tooltip title={translator.favorite} aria-label="add">
                      <i
                        className={`${
                          propertyAd.is_favorited ? "fas" : "far"
                        } fa-heart text-s-candy font-s-medium pointer`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleToggleFavorite(
                            propertyAd.is_favorited,
                            propertyAd.id
                          );
                        }}
                      ></i>
                    </Tooltip>
                  </div>
                }
              </Link>

              <div className="flat-link">
                <Link to={`/show-property-ad/${propertyAd.id}`}>
                  <span className="font-s-text font-s-regular font-w-s-light ">
                    {translator.details}
                  </span>
                </Link>
              </div>
            </div>

            <div className="d-flex flex-column col-7">
              <div
                className="d-flex justify-content-end mb-3"
                style={{ height: "35px" }}
              >
                {/*  <span
                style={{ width: "90px" }}
                className={` bg-s-${
                  propertyAd.ad_purpose === 0 ? "candy" : "deepWarning"
                } text-white font-s-regular font-s-text font-w-s-medium text-nowrap p-1  `}
              >
                {propertyAd.ad_purpose === 0
                  ? translator.sale
                  : translator.rent}
              </span> */}
                <div className="bg-s-secondary py-1 px-2 ">
                  <span className="text-white font-s-medium font-s-text font-w-s-medium text-nowrap">{`${propertyAd.price} ${translator.egp}`}</span>
                </div>
              </div>
              <div
                className="d-flex justify-content-start"
                style={{ height: "40px" }}
              >
                <div className=" p-2 ">
                  <i className="fas fa-building text-s-secondary font-s-medium"></i>
                  <span className="text-white font-s-medium font-s-text font-w-s-light text-nowrap mx-2">
                    {propertyAd.category.name}
                  </span>
                </div>
              </div>
              <div
                className="d-flex justify-content-start"
                style={{ height: "40px" }}
              >
                <div className=" p-2 ">
                  <i class="fas fa-map-marker-alt text-s-secondary font-s-medium"></i>
                  <span className="text-white font-s-medium font-s-text font-w-s-light text-nowrap mx-2">
                    {propertyAd.district.name}
                  </span>
                </div>
              </div>
              <div
                className="d-flex justify-content-start p-2"
                style={{ height: "40px" }}
              >
                <li>
                  <i class="fas fa-ruler-combined text-s-secondary font-s-medium"></i>
                  <span className="text-white font-s-regular mx-2">
                    {`${propertyAd.area} ${translator.meter}`}
                  </span>
                </li>
                <span className="mx-2"></span>
                <li>
                  <i
                    className={`${propertyAd.presentable_features[0].icon} text-s-secondary font-s-medium`}
                  ></i>
                  <span className="text-white font-s-regular mx-2">
                    {propertyAd.presentable_features[0].value}
                  </span>
                </li>
                <span className="mx-2"></span>
                <li>
                  <i
                    className={`${propertyAd.presentable_features[1].icon} text-s-secondary font-s-medium`}
                  ></i>
                  <span className="text-white font-s-regular mx-2">
                    {propertyAd.presentable_features[1].value}
                  </span>
                </li>
              </div>
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ height: "40px" }}
              >
                <div
                  className="col-8 px-0 text-nowrap"
                  style={{ overflow: "auto" }}
                >
                  <span className="d-flex text-s-cream font-s-text">
                    {`${propertyAd.title.substr(0, 50)} `}
                  </span>
                </div>
                <div
                  className="col-4 px-2 text-nowrap"
                  style={{ overflow: "auto" }}
                >
                  <Link to={`/show-property-ad/${propertyAd.id}`}>
                    <span className="text-s-secondary font-s-regular font-s-text font-w-s-light mx-1 pointer">
                      ... {translator.details}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="d-flex justify-content-center w-100">
          <h3 className="text-s-deepWarning font-s-title">
            {translator.noResults}
          </h3>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdsListView;
