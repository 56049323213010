import { Fragment, useContext, useEffect, useState } from "react";
import TranslationContext from "../../contexts/translation-context";
import MasterDataContext from "../../contexts/master-data-context";
import { Link } from "react-router-dom";

const LastAgents = ({ screenWidth }) => {
  const translator = useContext(TranslationContext).home;
  const { masterData } = useContext(MasterDataContext);
  const { lastAgents } = masterData;
  const [groupElementsNo, setGroupElementsNo] = useState(0);
  const [agentsGroups, setagentsGroups] = useState([]);

  useEffect(() => setGroupsElementsNumber(), [screenWidth]);

  useEffect(() => showActiveArticles(), [groupElementsNo]);

  const setGroupsElementsNumber = () => {
    if (screenWidth >= 992) setGroupElementsNo(4);
    else if (screenWidth < 768) setGroupElementsNo(1);
    else setGroupElementsNo(3);
  };

  const showActiveArticles = () => {
    const agentsCount = Array.isArray(lastAgents) ? lastAgents.length : 0;
    const modulusRest = agentsCount % groupElementsNo;
    //const totalGroups = (modulusRest === 0 ? 0 : 1) + (agentsCount - modulusRest) / groupElementsNo; //use this when need to add rest ads to last group
    const totalGroups = (agentsCount - modulusRest) / groupElementsNo;
    const agentsGroupsShadow = [];
    var subGroup = [];
    for (var i = 1; i <= totalGroups; i++) {
      for (
        var n = i * groupElementsNo - groupElementsNo;
        n < i * groupElementsNo;
        n++
      ) {
        n < agentsCount && subGroup.push(n);
      }
      agentsGroupsShadow.push(subGroup);
      subGroup = [];
    }
    setagentsGroups([...agentsGroupsShadow]);
  };

  return (
    <Fragment>
      <div className="featured-flat-area pb-30">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title-2 text-center">
                <div className="d-flex justify-content-center align-items-baseline mb-10">
                  <i className={`fas fa-user-tie text-s-pine ${screenWidth >= 768 ? 'display-4' : 'font-s-xlarge'}`}></i>
                  <span className={`text-s-primary font-s-title ${screenWidth >= 768 ? 'display-4' : 'font-s-xlarge'} mx-2`}>
                    {translator.ourAgents}
                  </span>
                </div>
                <p className="font-s-text font-w-s-light">{translator.newAgentsText}</p>
              </div>
            </div>
          </div>
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {agentsGroups.map((group, index) => (
                <div
                  key={`carousel-item-${index + 1}`}
                  className={`carousel-item ${index === 0 && "active"} `}
                >
                  <div className="row">
                    {typeof lastAgents !== "undefined" &&
                      lastAgents.length > 0 &&
                      group.map((index) => {
                        return (
                          <div className="col">
                            <div className="single-agent rounded">
                            <Link to={`show-agent/${lastAgents[index].id}`}>
                              <div
                                className="agent-image"
                                style={{
                                  backgroundImage: `url(${lastAgents[index].profile.profileImgUrl})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                                <img
                                  style={{ height: "300px", opacity: "0" }}
                                  src={lastAgents[index].profile.profileImgUrl}
                                  alt=""
                                />
                              </div>
                              </Link>
                              <div className="agent-info" style={{height:'55px'}}>
                                <div className="agent-name">
                                  <h6 className="text-capitalize">
                                    <a className="text-s-title">
                                      {lastAgents[index].profile.name}
                                    </a>
                                  </h6>
                                  <p className="text-s-title font-w-s-light text-capitalize">
                                    {translator.agent}
                                  </p>
                                </div>
                              </div>
                              <div className="agent-info-hover ">
                                <div className="">
                                  <ul className="px-2">
                                    <li className="d-flex align-items-center">
                                      <img
                                        src="assets/images/icons/phone-2.png"
                                        alt=""
                                        style={{ height: "15px" }}
                                      />
                                      <div className="px-1"></div>
                                      <div style={{ direction: "ltr" }}>
                                        <span className="text-s-title">
                                          {lastAgents[index].mobile}
                                        </span>
                                      </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <img
                                        src="assets/images/icons/mail-close.png"
                                        alt=""
                                        style={{ height: "15px" }}
                                      />
                                      <div className="px-1"></div>
                                      <span className="overflow-auto">
                                        {lastAgents[index].email}
                                      </span>
                                    </li>
                                    <div className="border-top border-s-secondary my-1"></div>
                                    <li className="d-flex align-items-center justify-content-center">
                                      <div>
                                        <span className="text-s-title text-capitalize">
                                          <a className="text-s-title text-s-primary font-s-regular overflow-auto">
                                            {lastAgents[index].profile.name}
                                          </a>
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LastAgents;
