export const sign = {
  ar: {
    welcome: "مرحباً بك في عقار ماكس",
    facebook: " الدخول بحساب فيسبوك",
    google: " الدخول بحساب جوجل",
    alt: "أو",
    signNormal: "تسجيل الدخول برقم الموبايل",
    signUpNormal: "التسجيل برقم الموبايل",
    registerAgent: 'تسجيل حساب وكيل عقارى',
    mobile: "رقم الموبايل",
    password: "كلمة المرور",
    signIn: "تسجيل الدخول",
    signUp: "تسجيل الحساب",
    forget: "نسيت كلمة المرور؟",
    register: "ليس لديك حساب؟ إنضم الآن",
    login: "لديك حساب؟ تسجيل الدخول",
    fName: "الاسم الأول",
    lName: "الاسم الأخير",
    disclaimer:
      "* قد تتطلب عملية التسجيل تزويد بعض المعلومات الأخرى لاحقاً بناءاً علي وسيلة التسجيل المستخدمة ",
    interCode: "كود الدولة",
    phoneVerification: "التحقق من رقم الهاتف",
    reqActivationCode: "أرسل كود التفعيل",
    activationDisclaimer:
      "هذا الإجراء يتم لمرة واحدة لتأكيد رقم هاتفك المسجل. شكراً لتفهمك",
    codeSent: "تم إرسال كود التفعيل بنجاح علي رقم هاتفك المسجل.",
    codeFailed: "فشل إرسال كود التفعيل برجاء المحاولة مرة أخرى لاحقاً.",
    enterVerification: "أدخل كود التحقق",
    accountAtivated:
      "تم تفعيل حسابك بنجاح. تستيطع الآن التمتع بكافة خدمات منصة عقار ماكس",
    codeInCorrect:
      "الكود الذى قمت بإدخاله غير صحيح, تأكد من إدخال الكود بشكل صحيح",
    ativationFailed:
      "فشل الإتصال بخدمة التحقق من رقم هاتفك المسجل. رجاءاً المحاولة مرة أخرى",
    registrationSuccess:
      "تم تسجيل حسابك بنجاح. أهلاً بك في منصة عقار ماكس, جارى تحويلك لتأكيد رقم هاتفك المسجل",
    codeMissing:
      " لم تقم بإدخال كود التعريف. من فضلك أدخل الكود ثم اضغط زر الإرسال ",
    loginSuccess: "تم تسجيل دخولك بنجاح. سيتم تحويلك إلى الصفحة الرئيسية",
    loginFailed: "فشل عملية تسجيل الدخول. برجاء إعادة المحاولة لاحقاً",
    myData: "بياناتى",
    myAds: "إعلاناتى",
    myFavs: "مفضلاتى",
    noFav: 'ليس لديك أى إعلانات مفضلة فى قائمتك.',
    email: "البريد الإلكترونى",
    myAccount: "حسابى",
    updateProfile: "تحديث ملفك الشخصى",
    changeNo: "تغيير الرقم",
    addNo: "إضافة رقم",
    newNo: "تسجيل رقم هاتف جديد",
    confirm: "تأكيد",
    changePassword: "تغيير كلمة المرور",
    currentPassword: "كلمة المرور الحالية",
    currentPasswordText: "أدخل كلمة مرورك الحالية",
    newPassword: "كلمة المرور الجديدة",
    newPasswordText: "أدخل كلمة المرور الجديدة",
    changeImg: "تغيير / إضافة صورة الحساب",
    signing: "جارى التحقق من بياناتك لتسجيل الدخول. برجاء الإنتظار قليلاً",
    apiSign: "تم التحقق من بياناتك بنجاح و جارى تسجيل دخولك",
    completeProfile: "إكمال ملفك الشخصى",
    mobileDisclaimer:
      "تسجيل رقم الهاتف يتيح لك إضافة الإعلانات و طلب معلومات الاتصال الخاصة بالمعلنين",
    profileUpdatedSuccess: "تم تحديث بيانات حسابك بنجاح.",
    profileUpdatedFailed: "فشل عملية تحديث بيانات حسابك.",
    noUpdates: "لم تقم بتعديل أى بيانات للتحديث. ",
    myContacts: "بيانات الاتصال",
    contactsMethods: "وسائل الاتصال",
    save: "حفظ",
    cancel: "إلغاء",
    addContact: "أضف وسيلة اتصال ",
    contactAddition: "إضافة وسيلة اتصال",
    required: "يجب تعيين قيمة لهذا الحقل",
    agentUnActive: 'حسابك ما زال تحت المراجعة من قبل مراقبى المنصة',
  },
  en: {
    welcome: "Welcome to Aqar Max",
    facebook: "Sign in with Facebook",
    google: "Sign in with Google",
    alt: "OR",
    signNormal: "sign in with mobile number",
    signUpNormal: "sign up with mobile number",
    registerAgent: 'register agent account',  
    mobile: "mobile no",
    password: "password",
    signIn: "Sign in",
    signUp: "Sign up",
    forget: "Forgot Password?",
    register: "Not user? Sign Up",
    login: "Already user? Sign In",
    fName: "first name",
    lName: "last name",
    disclaimer:
      "* Some data might be required later to complete registration depend on used registration method.",
    interCode: "Country Code",
    phoneVerification: "phone number verification",
    reqActivationCode: "send activation code",
    activationDisclaimer:
      "This procedure is done once to confirm your registered phone number. Thanks for understanding.",
    codeSent:
      "The activation code was successfully sent on your registered phone number.",
    codeFailed: "Failure to send the activation code. Please try again later.",
    enterVerification: "Enter verification code",
    accountAtivated:
      "Your account has been successfully activated. Now you can enjoy all of Qahraman services, enjoy",
    codeInCorrect:
      "The code you have entered is incorrect. Please make sure of enter valid verification code.",
    ativationFailed:
      "Failure to verify your registred phone number. Please try again.",
    registrationSuccess:
      "Your account has been successfully registred. Welcome to Aqar Max, Please wait while you will be redirected to phone authentication page",
    codeMissing:
      "You did not input the verification code. Please enter the code then press send button ",
    loginSuccess:
      " Welcome to Aqar Max, you have successfully signed in. You will be redirected to main page now",
    loginFailed: "Sign in Failed, please try again later.",
    myData: "My data",
    myAds: "My ads",
    myFavs: "My favorites",
    noFav: 'You have not any favorited ads in your list.',
    email: "Email",
    myAccount: "My Account",
    updateProfile: "Update profile",
    changeNo: "change no",
    addNo: "add no",
    newNo: "New phone number registration",
    confirm: "Confirm",
    changePassword: "Password Change",
    currentPassword: "Current Password",
    currentPasswordText: "enter your current password",
    newPassword: "New Password",
    newPasswordText: "enter new password",
    changeImg: "Change / Add profile image",
    signing: "Please wait while checking you sign in data.",
    apiSign:
      "Your data have been successfully verified and currently sending your data to sign in to your account.",
    completeProfile: "Complete profile",
    mobileDisclaimer:
      "Phone no registration enable you to create ads and get advertisers' contacts.",
    profileUpdatedSuccess: "Your account data has been successfully updated.",
    profileUpdatedFailed: "Updating your account data has failed.",
    noUpdates: "You did not change any data to update.",
    myContacts: "my contacts",
    contactsMethods: "contacts methods",
    save: "save",
    cancel: "cancel",
    addContact: "Add contact method",
    contactAddition: "new contact addition",
    required: "This field must be filled",
    agentUnActive : 'Your account is under review by platform moderators',
  },
};
