import React from "react";
import Header from "./header";
import MobileMenu from "./mobile-menu";
import Footer from "./footer";

const Layouts = (props) => {
  return (
    <React.Fragment>
      <div className="wrapper flexed-wrapper">
        <Header />
        <MobileMenu />
        {props.children}
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layouts;
