import { useEffect } from "react";

export const useLoadScript = (scriptSource, asyncType, defer = false) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.defer = defer;
    script.src = scriptSource;
    script.async = asyncType;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export const loadScript = (
  scriptSource,
  asyncType,
  defer = false
) => {
  const script = document.createElement("script");
  script.defer = defer;
  script.src = scriptSource;
  script.async = asyncType;
  document.body.appendChild(script);
};
