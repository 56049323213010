import React, { Fragment, useContext, useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import TranslationContext from "../../../contexts/translation-context";
import AdsCreationContext from "../../../contexts/not-public/ads-creation-context";
import StepperButtonsBar from "./stepper-buttons-bar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Benefits = ({
  handleBack,
  handleNext,
  handleReset,
  activeStep,
  steps,
  setAllFormData,
}) => {
  const classes = useStyles();
  const translator = useContext(TranslationContext).ads;
  const { categoryFeatures } = useContext(AdsCreationContext);
  const [checkCategoryFeatures, setCheckCategoryFeatures] = useState(false);
  let featuresValues = [];

  useEffect(() => {
    Object.keys(categoryFeatures).length > 0 && setCheckCategoryFeatures(true);
  }, [categoryFeatures]);

  const handleInput = (featureId) => {
    let featureIndex = featuresValues.findIndex(
      (feature) => feature.feature_id === featureId
    );
    featureIndex === -1
      ? featuresValues.push({ feature_id: featureId, feature_value: true })
      : featuresValues.splice(featureIndex, 1);
  };

  const submitForm = () => {
    setAllFormData("benefits", { features: featuresValues });
    handleNext();
  };

  return (
    <Fragment>
      <div className="my-4">
        <h1 className="text-capitalize text-s-secondary">
          {translator.options}
        </h1>
      </div>
      <div className="mt-5">
        {checkCategoryFeatures ? (
          <div className="row">
            {typeof categoryFeatures.boolean !== "undefined" &&
              categoryFeatures.boolean.map((feature) => (
                <div key={feature.id} className="col-sm-4 col-md-3 col-lg-2">
                  <div className="form-group align-contents-start">
                    <label>{feature.name}</label>
                    <Checkbox
                      id={`${feature.id}-input`}
                      type="checkbox"
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="form-control"
                      style={{ padding: "0" }}
                      onChange={() => handleInput(feature.id)}
                    />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="alert d-flex flex-column my-50">
            <h1 className="my-50">{translator.noFeatures}</h1>
            <i className="fas fa-info-circle display-1 my-50"></i>
          </div>
        )}
      </div>
      <StepperButtonsBar
        handleBack={handleBack}
        activeStep={activeStep}
        handleReset={handleReset}
        steps={steps}
        submitForm={submitForm}
      />
    </Fragment>
  );
};

export default Benefits;
