import React, { useContext, useState, useEffect } from "react";
import TranslationContext from "../../contexts/translation-context";
import SideBarItems from "./components/side-bar-items";

const MyAccount = (props) => {
  const translator = useContext(TranslationContext).sign;
  const [screenWidth, setScreenWidth] = useState();
  const [view, setView] = useState("col-md-6");

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    screenWidth <= 1150 && setView("columns");
  }, [screenWidth]);

  window.addEventListener("resize", function () {
    setScreenWidth(window.innerWidth);
  });

  return (
    <React.Fragment>
      <section  className="page-wrapper h-100">
        <div
          style={{
            background: 'url("/assets/images/customizer/pattern/3.png")',
          }}
          className="d-inline-block h-100 w-100 page-content-background"
        >
          <div className="container-fluid pt-115 pb-60 h-100">
            <div
              className={`card bg-gray text-center py-5 h-100 ${
                screenWidth > 1250 && "card-limit-size"
              } `}
            >
              <div
                className={`${
                  screenWidth < 1250 ? "container-fluid" : "container"
                }`}
              >
                <div className="row">
                  <div
                    className={`${screenWidth <= 1250 ? "col-12" : "col-2"}`}
                  >
                    <div className="d-flex flex-column">
                      <div className={`accordion ${screenWidth <= 1250 ? "d-block" : "d-none"} mb-4`} id="accordionExample">
                        <table className="table mb-0">
                          <thead
                            className="collapsed bg-s-primary text-s-cream font-s-medium font-s-title font-w-s-medium"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <tr>
                              <th className="d-flex justify-content-between align-items-between">{translator.myAccount} <i className="fas fa-bars px-1"></i></th>
                            </tr>
                          </thead>
                        </table>
                        <div
                          id="collapseOne"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <table className="table table-bordered table-hover">
                            <SideBarItems routeInfo={props.routeInfo} />
                          </table>
                        </div>
                      </div>
                      <table className={`table table-bordered table-hover ${screenWidth <= 1250 && "d-none"}`}>
                        <thead className="bg-s-primary text-s-cream font-s-medium font-s-title font-w-s-medium">
                          <tr>
                            <th>{translator.myAccount}</th>
                          </tr>
                        </thead>
                        <SideBarItems routeInfo={props.routeInfo} />
                      </table>
                    </div>
                  </div>
                  <div
                    className={`${screenWidth <= 1250 ? "col-12" : "col-10"}`}
                  >
                    {props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default MyAccount;
