import React, { useContext, useEffect } from "react";
import ScreenSizeContext from "../contexts/screen-size-context";
import SliderSection from "./home-components/slider-section";
import LastAddedAds from "./home-components/last-ads";
import Articles from "./home-components/articles";
import SubscribeArea from "./home-components/subscribe-area";
import LastAgents from "./home-components/last-agents";
import Features from "./home-components/features";

const Home = () => {
  const screenWidth = useContext(ScreenSizeContext).screenWidth;
  const screenHeight = useContext(ScreenSizeContext).screenHeight;

  useEffect(() => {
    window.runCarousel();
  }, []);
  console.log(screenWidth, screenHeight);

  return (
    <React.Fragment>
      <SliderSection screenWidth={screenWidth}/>
      <LastAddedAds screenWidth={screenWidth} />

      <Features />
      <LastAgents screenWidth={screenWidth} />
      <Articles screenWidth={screenWidth} />
      <SubscribeArea />
    </React.Fragment>
  );
};

export default Home;
