import React, { Fragment } from 'react';

const PageNotFound = () => {
    return ( <Fragment>
        <div className="w-100 h-100 d-flex" style={{backgroundImage: "url('/assets/images/bg/404.jpg')", height:"100%"}}>
        <h1>page not found</h1>

        </div>
    </Fragment> );
}
 
export default PageNotFound;