import React, { useState, useEffect, useContext, createRef } from "react";
import ProfileContext from "../../contexts/profile-context";
import TranslationContext from "../../contexts/translation-context";
import InternationalCodes from "../../components/international-codes";
import { updateProfile } from "../../services/data-request-center";
import { useLoadStyle } from "../../hooks/load-style";
import MyAccount from "./my-account";
import { Button } from "@material-ui/core";
import ErrorHandler from "../../helpers/error-handler";
import CircularProgress from "@material-ui/core/CircularProgress";

const MyProfile = (props) => {
  useLoadStyle("/assets/css/custom/select-picker.css");
  const { profile, setProfile } = useContext(ProfileContext);
  const [currentProfile, setCurrentProfile] = useState(profile);
  const translator = useContext(TranslationContext).sign;
  const [loading, setLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState();
  const [view, setView] = useState("col-md-6");
  const [firstName, setFirstName] = useState(profile.firstName);
  const [lastName, setLastName] = useState(profile.lastName);
  const [email, setEmail] = useState(profile.email);
  const [profileImg, setProfileImg] = useState(profile.profileImgUrl);
  const [newProfileImg, setNewProfileImg] = useState();
  const [selectCountry, setSelectCountry] = useState(false);
  const [countryCode, setCountryCode] = useState(profile.code);
  const [countryId, setCountryId] = useState(null);
  const [phoneNo, setPhoneNo] = useState(profile.phone);
  const [mobile, setMobile] = useState(profile.mobile);
  const [mobileShow, setMobileShow] = useState(profile.mobile);
  const inputFile = createRef();
  const [displayStatus, setDisplayStatus] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [responseStatus, setResponseStatus] = useState();

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    screenWidth <= 1000 && setView("col-sm-12");
  }, [screenWidth]);

  useEffect(() => {
    setProfileImg(currentProfile.profileImgUrl);
  }, [currentProfile]);

  useEffect(() => {
    countryCode !== null &&
      phoneNo !== null &&
      setMobileShow(countryCode + "-" + phoneNo);
  }, [mobile]);

  const readImage = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve({ image: reader.result, file });
      };
      reader.readAsDataURL(file);
    });
  };

  const appendImages = async (event) => {
    if (event.target.files.length === 1) {
      try {
        let image = await readImage(event.target.files[0]);
        setNewProfileImg(image);
        setProfileImg(image.image);
      } catch (errors) {}
    } else {
      //Alert Warning Message
    }
  };

  const callUpdateProfile = async () => {
    if (
      firstName !== currentProfile.firstName ||
      lastName !== currentProfile.lastName ||
      email !== currentProfile.email ||
      profileImg !== currentProfile.profileImgUrl ||
      countryCode + phoneNo !== currentProfile.code + currentProfile.phone
    ) {
      setLoading(true);
      const newProfile = new FormData();
      newProfile.append("_method", "PUT");
      newProfile.append("first_name", firstName);
      newProfile.append("last_name", lastName);
      newProfile.append("email", email);
      newProfile.append("country_id", countryId);
      newProfile.append("mobile", phoneNo);
      profileImg !== currentProfile.profileImgUrl &&
        newProfile.append("profile_image", newProfileImg.file);
      const { data, status, profileData } = await updateProfile(newProfile);
      if (status === 200) {
        setLoading(false);
        setProfile(profileData);
        setCurrentProfile(profileData);
        setMobile(profileData.mobile);
        setPhoneNo(profileData.phone);
        setMobileShow(profileData.mobile);
        await displayMessage(status, translator.profileUpdatedSuccess, 4000);
      } else if (status === 422) {
        await displayMessage(status, data.message, 4000);
        setLoading(false);
      } else {
        await displayMessage(status, translator.loginFailed, 4000);
        setLoading(false);
      }
    } else {
      await displayMessage(400, translator.noUpdates, 4000);
    }
  };

  const displayMessage = (status, message, time) => {
    return new Promise((resolve) => {
      setResponseStatus(status);
      setResponseMessage(message);
      setDisplayStatus(true);
      setTimeout(() => {
        setDisplayStatus(false);
        setResponseStatus("");
        setResponseMessage("");
        resolve();
      }, time);
    });
  };

  window.addEventListener("resize", function () {
    setScreenWidth(window.innerWidth);
  });
  return (
    <React.Fragment>
      <MyAccount routeInfo={props}>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-s-title" id="exampleModalLabel">
                  {translator.newNo}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i
                        className="fas fa-globe fa-lg text-s-secondary"
                        style={{ width: "40px" }}
                      ></i>
                    </span>
                  </div>
                  <select
                    className="selectpicker form-control"
                    title={translator.category}
                    data-live-search="true"
                    data-container="body"
                    data-size="10"
                    onChange={(event) => {
                      let code =
                        event.target.options[event.target.selectedIndex].dataset
                          .code;
                      setCountryCode(code);
                      setCountryId(event.target.value);
                      setSelectCountry(true);
                    }}
                  >
                    <InternationalCodes />
                  </select>
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i
                        className="fa fa-mobile fa-lg text-s-secondary"
                        style={{ width: "40px" }}
                      ></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control bg-white"
                    placeholder={translator.mobile}
                    onChange={(event) => {
                      setPhoneNo(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn bg-s-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    if (phoneNo !== null) {
                      !selectCountry && setCountryId("68");
                      !selectCountry && setCountryCode("+20");
                      setMobile(
                        (countryCode === null ? "+20" : countryCode) + phoneNo
                      );
                    }
                  }}
                >
                  <span className="text-white font-s-text font-s-regular font-w-s-medium">
                    {translator.confirm}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mb-3">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            style={{ display: "none" }}
            ref={inputFile}
            onChange={(event) => appendImages(event)}
          />
          <label htmlFor="contained-button-file" className="w-100">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-baseline">
                  <i className="fas fa-cloud-upload-alt text-white font-s-medium"></i>
                  <span className="text-white font-s-text font-s-regular font-w-s-medium mx-1">
                    {translator.changeImg}
                  </span>
                </div>
                <div
                  className="border border-light bg-dark mx-2 "
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img src={profileImg} />
                </div>
              </div>
            </Button>
          </label>
        </div>
        <div className="row">
          <div className={view}>
            <div className="d-flex">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text text-capitalize"
                    style={{ width: "125px" }}
                  >
                    {translator.fName}
                  </span>
                </div>
                <input
                  type="text"
                  className={`form-control font-s-text font-s-regular ${
                    !profile.firstName && "is-invalid"
                  } `}
                  placeholder={profile.firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className={view}>
            <div className="d-flex">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text text-capitalize"
                    style={{ width: "125px" }}
                  >
                    {translator.lName}
                  </span>
                </div>
                <input
                  type="text"
                  className={`form-control font-s-text font-s-regular ${
                    !profile.lastName && "is-invalid"
                  } `}
                  placeholder={profile.lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className={view}>
            <div className="d-flex">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text text-capitalize"
                    style={{ width: "125px" }}
                  >
                    {translator.mobile}
                    {!profile.mobile && (
                      <i className="fas fa-lightbulb text-s-deepWarning mx-1 animate__animated animate__flash animate__repeat-3"></i>
                    )}
                  </span>
                </div>
                <input
                  type="text"
                  className={`form-control font-s-text font-s-regular ${
                    localStorage.language === "ar" && "text-s-end"
                  } `}
                  readOnly
                  placeholder={mobileShow}
                  style={{ direction: "ltr" }}
                />
                {(!profile.isVerified || !profile.mobile) && (
                  <div
                    className="input-group-append pointer"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <span className="input-group-text bg-s-primary text-white font-s-text font-s-small font-w-s-medium d-flex align-items-baseline text-capitalize">
                      <i className="fas fa-mobile text-s-deepWarning font-s-medium mx-1"></i>
                      {profile.mobile &&
                        !profile.isVerified &&
                        translator.changeNo}
                      {!profile.mobile && translator.addNo}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={view}>
            <div className="d-flex">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text text-capitalize"
                    style={{ width: "125px" }}
                  >
                    {translator.email}
                  </span>
                </div>
                <input
                  type="text"
                  className={`form-control font-s-text font-s-regular ${
                    !profile.email && "is-invalid"
                  } `}
                  placeholder={profile.email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <button
              className="btn btn-block bg-s-primary d-flex align-items-center justify-content-center"
              style={{ height: "50px" }}
              onClick={() => callUpdateProfile()}
            >
              <div className="d-flex align-items-baseline">
                {loading ? (
                  <CircularProgress
                    className="mx-1 text-s-secondary"
                    style={{ height: "22px", width: "22px" }}
                  />
                ) : (
                  <i className="fas fa-user-edit text-white font-s-medium mx-1"></i>
                )}
                <span className="text-white font-s-text font-s-medium font-w-s-medium">
                  {translator.updateProfile}
                </span>
              </div>
            </button>
            {!profile.mobile && (
              <div className="d-flex ">
                <span className="text-muted font-s-xsmall font-s-text text-s-start">
                  <i className="fas fa-lightbulb text-s-deepWarning mx-1 animate__animated animate__flash animate__repeat-3"></i>
                  {translator.mobileDisclaimer}
                </span>
              </div>
            )}
          </div>
          {profile.userTypeId === 4 && profile.activeAgent === 0 && (
            <div className="col-12 mt-4">
              <div className="d-flex">
                <i className="fas fa-exclamation-triangle text-s-deepWarning font-s-regular"></i>
                <span className="font-s-text text-muted font-s-regular mx-2">
                  {translator.agentUnActive}
                </span>
              </div>
            </div>
          )}
        </div>
        {displayStatus && (
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
              <ErrorHandler status={responseStatus} message={responseMessage} />
            </div>
          </div>
        )}
      </MyAccount>
    </React.Fragment>
  );
};

export default MyProfile;
