export const others = {
  ar: {
    
    ourAgents: "الوكلاء العقاريون",
    newAgentsText: "الوكلاء العقاريون المنضمون للمنصة مؤخراً",
    agent: "وكيل عقارى",
    adsCount: "عدد العقارات:",
    directContacts: "الاتصال المباشر",
    contacts: 'وسائل الاتصال',
    agentProfileShow: "عرض ملف الوكيل",
    agentAds: 'العقارات المعروضة',
  },
  en: {
    
    ourAgents: "brokers",
    newAgentsText: "The latest borkers whom have joined to our platform",
    agent: "real estate broker",
    adsCount: "properties count:",
    directContacts: "direct contacts",
    contacts: 'contacts methods',
    agentProfileShow: "view agent profile",
    agentAds: 'shown properties'
  },
};
