import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import Layouts from "./screens/layouts/layouts";
import { useLoadScript } from "./hooks/load-script";

const App = () => {
  useLoadScript("/assets/js/main.js", false);
  useLoadScript("/assets/js/custom-js/display-direction.js", false);

  return (
    <div className="App">
      <BrowserRouter>
        <Layouts>
          <Routes />
        </Layouts>
      </BrowserRouter>
    </div>
  );
};

export default App;
