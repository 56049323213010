import { Fragment, useState, useEffect, useContext } from "react";
import { getArticlesTypes } from "../../services/data-request-center";
import TranslationContext from "../../contexts/translation-context";
import LoadingComponent from "../../components/loading-component";
import { Link } from "react-router-dom";

const ArticlesScreen = (props) => {
  const typeId = props.match.params.id;
  const translator = useContext(TranslationContext).articles;
  const [loading, setLoading] = useState(true);
  const [articlesTypes, setArticlesTypes] = useState(null);
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    articlesTypes === null ? callGetArticlesTypes() : fillArticles();
  }, [props.match.params.id]);

  useEffect(() => {
    if (articlesTypes !== null) {
      fillArticles();
      setLoading(false);
    }
  }, [articlesTypes]);

  const callGetArticlesTypes = async () => {
    const { data, status } = await getArticlesTypes();
    status === 200 && setArticlesTypes(data.articles_types);
  };

  const fillArticles = () => {
    const typeIndex = articlesTypes.findIndex((type) => type.id == typeId);
    setArticles({ ...articlesTypes[typeIndex] });
  };
  return (
    <Fragment>
      {loading ? (
        <LoadingComponent />
      ) : (
        <section className="h-100">
          <div
            className="d-inline-block w-100 h-100"
            style={{
              background: 'url("/assets/images/customizer/pattern/3.png")',
            }}
          >
            <div className="container-fluid  pt-115 pb-60 h-100">
              <div className="card text-center py-5  px-5 card-limit-size h-100">
                <div className="mt-4 d-flex">
                  <i className="fas fa-book-reader text-s-secondary font-s-xlarge"></i>

                  <span className="font-s-title font-s-xlarge text-s-primary font-w-s-bold text-capitalize mx-2">
                    {translator.articlesDept}
                  </span>
                </div>
                <div
                  className="bg-s-primary my-2 py-0 px-2 rounded"
                  style={{ width: "fit-content" }}
                >
                  <span className="font-s-title font-s-regular text-s-secondary font-w-s-bold text-capitalize">
                    {articles.name}
                  </span>
                </div>
                <hr className="mt-2 mb-5"></hr>
                <div className="">
                  {articles.articles.map((article) => (
                    <Link to={`/show-article/${article.id}`}>
                      <div className="d-flex p-1 mb-5">
                        <div style={{ width: "80px" }}>
                          <img
                            src={article.article_image}
                            className="rounded"
                          />
                        </div>
                        <div className="d-flex flex-column align-items-start px-2">
                          <span className="font-s-title text-s-primary font-s-regular font-w-s-medium">
                            {article.title}
                          </span>
                          <span className="font-s-title text-muted font-s-small font-w-s-medium">
                            {article.create_at}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default ArticlesScreen;
