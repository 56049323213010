import React, { Fragment, useContext, useState } from "react";
import { useEffect , useLayoutEffect} from "react";
import TranslationContext from "../contexts/translation-context";
import { getInternationalCodes } from "../services/data-request-center";

const InternationalCodes = () => {
  const translator = useContext(TranslationContext).sign;
  const [internationalCodes, setInternationalCodes] = useState([]);
  const [rendered, setRendered] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRendered(true);
    callGetInternationalCodes();
  }, []);

  useLayoutEffect(() => {
    !loading && window.refreshAllSelectPickers();
  }, [loading]);

  useEffect(() => {
    internationalCodes.length > 0 && setLoading(false);
  }, [internationalCodes]);

  useEffect(() => {
    rendered && window.refreshAllSelectPickers();
  }, [loading]);

  const callGetInternationalCodes = async () => {
    const { data, status } = await getInternationalCodes();
    status === 200 && setInternationalCodes(data.countriesCodes);
  };

  return (
    <Fragment>
      <option disabled selected={loading}>
        {translator.interCode}
      </option>
      {loading ? (
        <Fragment></Fragment>
      ) : (
        internationalCodes.map((country) => (
          <option key={country.id} value={country.id} data-code={country.code} selected={country.id === 68 && true}>
            {`${country.name} ( ${country.code} ) `}
          </option>
        ))
      )}
    </Fragment>
  );
};

export default InternationalCodes;
