export const home = {
  ar: {
    articlesDept: "إعرف عقار",
    articlesText:
      "مقالات يكتبها متخصصون فى مجال العقارات تتنوع بين النصائح و الأخبار ومعلومات متنوعة",
    articleDate: "تاريخ المقالة",
    articlesSerial: "سلسلة مقالات إعرف عقار",
    readMore: "إقرأ المزيد ...",
    articleTips: "نصائح و وصايا",
    articleNews: "أخبار عقارية",
    articleFinishes: "تشطيبات و ديكورات",
    ourAgents: "الوكلاء العقاريون",
    newAgentsText: "الوكلاء العقاريون المنضمون للمنصة مؤخراً",
    agent: "وكيل عقارى",
    why:'لماذا',
    platformName:'مو للتداول العقارى',
    appDescText: 'منصة مختصة بتداول و تسويق و تطوير العقارات يلتقى فيها شركات التطوير و التسويق العقارى من جانب مع العملاء الراغبين فى بيع أو شراء العقارات.' ,
    credibility: 'المصداقية',
    credibilityText: 'المنصة طرف محايد هدفه إتاحة الفرصة لكل الأطراف لتحقيق أهدافه',
    safety: 'الأمان',
    safetyText:'المنصة توفر مناخ آمن بفضل التعامل مع الشركات والوكلاء الموثوقين فقط',
    transparency: 'الشفافية',
    transparencyText: 'المنصة توفر لكل الأطراف من شركات و أفراد معلومات عن تعاملات ومستوي الثقة للأطراف الأخرى',
    monitoring: 'الرقابة',
    monitoringText: 'المنصة تراقب الإعلانات التى تنشر عليها و تتلقى تقارير العملاء و تتخذ رد الفعل المناسب فى حالات الغش أو التضليل',
    awareness: 'التوعية',
    awarenessText:'المنصة تقدم مقالات متخصصة بهدف التوعية لعملاءها لتحسين قدرتهم على اتخاذ قرارات الاستثمار الأفضل',
    coverage: 'التغطية',
    coverageText: 'المنصة تتيح لجميع عملاءها فى كل مكان معلومات محدثة عن سوق العقارات فى أى منطقة',
  },
  en: {
    articlesDept: "learn real estate",
    articlesText:
      "collection of articles written by specialists in real estate career varies between tips, news and miscellaneous information",
    articleDate: "article date",
    articlesSerial: "learn real estate articles series",
    readMore: "Read more ...",
    articleTips: "tips and recommendations",
    articleNews: "real estate news",
    articleFinishes: "finishes and decorations",
    ourAgents: "brokers",
    newAgentsText: "The latest borkers whom have joined to our platform",
    agent: "real estate broker",
    why:'why',
    platformName:'Mo Real Estate',
    appDescText: 'a realestate platform for development, marketing and trading properties which combine development enterprises, marketing enterprises with people who are interested in trading in properties',
    credibility: 'credibility',
    credibilityText: 'the platform is a neutral party with the aim of providing all parties with the opportunity to achieve its goals',
    safety: 'safety',
    safetyText:'the platform provides a safe environment by dealing with companies and trusted agents only',
    transparency: 'transparency',
    transparencyText: 'the platform provides information to all companies and individuals on the transactions and level of confidence of other parties',
    monitoring: 'monitoring',
    monitoringText: 'the platform monitors published advertisements & receives clients` reports and takes appropriate responses in cases of fraud or misinformation',
    awareness: 'awareness',
    awarenessText: 'the platform provides specialized articles aimed at raising awareness among its clients to improve their ability to make better investment decisions.',
    coverage: 'coverage',
    coverageText: 'the platform provides all its customers everywhere with updated information on the real estate market in any region',
  },
};
