import React, { Fragment, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import {
  House,
  Check,
  PhotoLibrary,
  Apartment,
  Ballot,
  Tv,
} from "@material-ui/icons";
import { colorsPalette } from "../../../services/config";
import TranslationContext from "../../../contexts/translation-context";
import BasicInfo from "./basic-info";
import SecondaryInfo from "./secondary-info";
import Benefits from "./benefits";
import Media from "./media";
import Ad from "./ad";

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: colorsPalette.primary,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: colorsPalette.secondary,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: colorsPalette.primary,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: colorsPalette.secondary,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <House />,
    2: <Apartment />,
    3: <Ballot />,
    4: <PhotoLibrary />,
    5: <Tv />,
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

let allFormsData = {
  basic: null,
  secondary: null,
  benefits: null,
  media: null,
  ad: null,
};

const AdCreationStepper = ({ setFullData }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const translator = useContext(TranslationContext).ads;
  const steps = [
    translator.basicInfo,
    translator.subInfo,
    translator.options,
    translator.gallery,
    translator.ad,
  ];

  const [stepperCounter, setStepperCounter] = useState(0);
  const previewSteps = ["basic", "secondary", "benefits", "media", "ad"];
  const [currentStep, setCurrentStep] = useState("basic");

  useEffect(() => {
    setCurrentStep(previewSteps[stepperCounter]);
  }, [stepperCounter]);

  const prepareData = () => {
    const formData = new FormData();
    const features = [
      ...allFormsData.secondary.features,
      ...allFormsData.benefits.features,
    ];
    const images = [...allFormsData.media.files];
    images.forEach((image, index) => {
      formData.append("images[" + index + "]", image.file);
    });
    formData.append("ad_purpose", allFormsData.basic.ad_purpose);
    formData.append("category_id", allFormsData.basic.category_id);
    formData.append("district_id", allFormsData.basic.district_id);
    formData.append("lat", allFormsData.basic.lat);
    formData.append("lng", allFormsData.basic.lng);
    formData.append("price", allFormsData.basic.price);
    formData.append("is_negotiable", allFormsData.basic.is_negotiable);
    formData.append("payment_method", allFormsData.basic.payment_method);
    formData.append("area", allFormsData.basic.area);
    formData.append("features", JSON.stringify(features));
    formData.append("video_url", allFormsData.media.video_url);
    formData.append("default_image_index", allFormsData.media.coverImg);
    formData.append("title", allFormsData.ad.title);
    formData.append("description", allFormsData.ad.description);
    setFullData(formData);
  };

  const setAllFormData = (key, data) => {
    allFormsData[key] = data;
    key === "ad" && prepareData();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStepperCounter(stepperCounter + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStepperCounter(stepperCounter - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setStepperCounter(0);
  };
  return (
    <Fragment>
      <div className={classes.root}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="my-5">
          <form>
            <div className={currentStep === "basic" ? "d-block" : "d-none"}>
              <BasicInfo
                handleBack={handleBack}
                handleNext={handleNext}
                handleReset={handleReset}
                activeStep={activeStep}
                steps={steps}
                setAllFormData={setAllFormData}
              />
            </div>
            <div className={currentStep === "secondary" ? "d-block" : "d-none"}>
              <SecondaryInfo
                handleBack={handleBack}
                handleNext={handleNext}
                handleReset={handleReset}
                activeStep={activeStep}
                steps={steps}
                setAllFormData={setAllFormData}
              />
            </div>
            <div className={currentStep === "benefits" ? "d-block" : "d-none"}>
              <Benefits
                handleBack={handleBack}
                handleNext={handleNext}
                handleReset={handleReset}
                activeStep={activeStep}
                steps={steps}
                setAllFormData={setAllFormData}
              />
            </div>
            <div className={currentStep === "media" ? "d-block" : "d-none"}>
              <Media
                handleBack={handleBack}
                handleNext={handleNext}
                handleReset={handleReset}
                activeStep={activeStep}
                steps={steps}
                setAllFormData={setAllFormData}
              />
            </div>
            <div className={currentStep === "ad" ? "d-block" : "d-none"}>
              <Ad
                handleBack={handleBack}
                handleNext={handleNext}
                handleReset={handleReset}
                activeStep={activeStep}
                steps={steps}
                setAllFormData={setAllFormData}
              />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AdCreationStepper;
