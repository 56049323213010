import React from "react";

const SubscribeArea = () => {
  return (
    <React.Fragment>
      <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-12">
              <div className="section-title text-white">
                <h3>SUBSCRIBE</h3>
                <h2 className="h1">NEWSLETTER</h2>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-12">
              <div className="subscribe">
                <form action="#">
                  <input
                    type="text"
                    name="subscribe"
                    placeholder="Enter yur email here..."
                  />
                  <button type="submit" value="send">
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscribeArea;
