import React, { useEffect } from "react";
import FindBoxSimple from "./find-box-simple";

const SliderSection = ({ screenWidth }) => {

  useEffect(() => {
    window.refreshAllSelectPickers();
  }, []);

  

  return (
    <React.Fragment>
      <div className={`bg-3 bg-opacity-black-70 pt-${screenWidth >= 768 ? '115' : '50'} pb-60`}>
        <div
          className="wow fadeInUp"
          data-wow-duration="1.5s"
          data-wow-delay="0.5s"
        >
          <FindBoxSimple />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SliderSection;
