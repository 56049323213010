import React, { Fragment, useContext, useState } from "react";
import { colorsPalette } from "../../../services/config";
import TranslationContext from "../../../contexts/translation-context";
import StepperButtonsBar from "./stepper-buttons-bar";

const Ad = ({ handleBack, handleReset, activeStep, steps, setAllFormData }) => {
  const translator = useContext(TranslationContext).ads;
  const [adTitle, setAdTitle] = useState("");
  const [adText, setAdText] = useState("");
  const [validationActive, setValidationActive] = useState(false);
  let validations = {
    titleValid: adTitle !== "",
    textValid: adText !== "",
  };

  const handleInput = (event, inputName) => {
    const value = event.target.value;
    if (inputName === "adTitle") {
      setAdTitle(value);
    } else if (inputName === "adText") {
      setAdText(value);
    }
  };

  const submitForm = () => {
    let checkValidationsResult = checkValidations();
    if (checkValidationsResult) {
      setAllFormData("ad", { title: adTitle, description: adText });
    }
  };

  const checkValidations = () => {
    const validationsStatus = validations.titleValid && validations.textValid;
    validationsStatus ? setValidationActive(false) : setValidationActive(true);
    return validationsStatus;
  };

  return (
    <Fragment>
      <div className="mb-5" style={{ minHeight: "600px" }}>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              style={{ width: "60px", justifyContent: "center" }}
            >
              <i
                className="fa fa-bullhorn"
                style={{ color: colorsPalette.secondary, fontSize: "30px" }}
              ></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder={translator.adTitle}
            style={{ height: "60px" }}
            onChange={(event) => handleInput(event, "adTitle")}
          />
          {validationActive && !validations.titleValid && (
            <div className="col-12">
              <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
                <i className="fas fa-exclamation-circle"></i>{" "}
                {translator.required}
              </span>
            </div>
          )}
        </div>
        <div className="input-group input-group-lg">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              style={{ width: "60px", justifyContent: "center" }}
            >
              <i
                className="fa fa-tv"
                style={{ color: colorsPalette.secondary, fontSize: "30px" }}
              ></i>
            </span>
          </div>
          <textarea
            className="form-control ad-text"
            placeholder={translator.adTextPlaceholder}
            onChange={(event) => handleInput(event, "adText")}
          ></textarea>
          {validationActive && !validations.textValid && (
            <div className="col-12">
              <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
                <i className="fas fa-exclamation-circle"></i>{" "}
                {translator.required}
              </span>
            </div>
          )}
        </div>
      </div>
      <StepperButtonsBar
        handleBack={handleBack}
        activeStep={activeStep}
        handleReset={handleReset}
        steps={steps}
        submitForm={submitForm}
      />
      <div
        className="row mt-4 mx-1 p-0"
        style={{ position: "absolute", bottom: "10px" }}
      >
        <p className="text-muted font-weight-normal">
          <i className="fa fa-exclamation-circle text-s-secondary"></i>{" "}
          {translator.adDisclaimer}
        </p>
      </div>
    </Fragment>
  );
};

export default Ad;
