import React, { useContext } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { colorsPalette } from "../../../services/config";
import TranslationContext from "../../../contexts/translation-context";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const StepperButtonsBar = ({ handleBack, handleReset, activeStep, steps, submitForm }) => {
  const classes = useStyles();
  const translations = useContext(TranslationContext).ads;

  return (
    <React.Fragment>
      
            {activeStep === steps.length ? (
              <div>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    onClick={handleBack}
                    className={`${classes.button} text-white`}
                    style={{
                      width: "150px",
                      backgroundColor: colorsPalette.secondary,
                      boxShadow:
                        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                    }}
                  >
                    {translations.back}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    className={classes.button}
                    style={{ width: "150px" }}
                  >
                    {activeStep === steps.length - 1
                      ? translations.finish
                      : translations.next}
                  </Button>
                </div>
              </div>
            )}
    </React.Fragment>
  );
};

export default StepperButtonsBar;
