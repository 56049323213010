import React, { Fragment, useEffect, useState } from "react";
import AdCreationStepper from "./create-ad-components/stepper";
import AdsCreationContext from "../../contexts/not-public/ads-creation-context";
import { useLoadStyle } from "../../hooks/load-style";
import FinishAd from "./create-ad-components/finish-ad";
import LoadingComponent from "../../components/loading-component";
import {
  getCategories,
  getGovernorates,
  getCategoryFeatures,
} from "../../services/data-request-center";
import {createAd} from '../../services/data-request-center';
import ErrorHandler from "../../helpers/error-handler";

const PropertiesAdsCreation = () => {
  const language = localStorage.getItem("language");
  const cssDirectory = language === "ar" ? "css-rtl" : "css";
  useLoadStyle("/assets/" + cssDirectory + "/custom/select-picker.css");
  const [categories, setCategories] = useState();
  const [governorates, setGovernorates] = useState();
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(false);
  const [categoryResponse, setCategoryResponse] = useState(false);
  const [governoratesResponse, setGovernoratesResponse] = useState(false);
  const [categoryFeatures, setCategoryFeatures] = useState([]);
  const [fullData, setFullData] = useState(null);
  const [adId, setAdId] = useState();
  const [displayStatus, setDisplayStatus] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [responseStatus, setResponseStatus] = useState();

  useEffect(() => {
    callGetCategories();
    callGetGovernorates();
  }, []);

  useEffect(() => {
    categoryResponse && governoratesResponse && setLoading(false);
  }, [categoryResponse, governoratesResponse]);

  useEffect(() => {
    !loading && window.refreshAllSelectPickers();
  }, [loading]);

  useEffect(() => {
    fullData !== null && submitForm();
  }, [fullData]);

  const callGetCategories = async () => {
    const { data, status } = await getCategories();
    if (status === 200) {
      setCategories(data.categories);
      setCategoryResponse(true);
    }
  };

  const callGetGovernorates = async () => {
    const { data, status } = await getGovernorates();
    if (status === 200) {
      setGovernorates(data.governorates);
      setGovernoratesResponse(true);
    }
  };

  const callGetCategoryFeatures = async (categoryId) => {
    const { data, status } = await getCategoryFeatures(categoryId);
    if (status === 200) {
      typeof data.category.features !== "undefined" &&
        setCategoryFeatures(data.category.features);
    }
  };

  const submitForm = async () => {
    setLoading(true);
    const { data, status } = await createAd(fullData);
    if (status === 200) {
      console.log(data);
      setAdId(data.property_ad_id);
      setFinished(true);
      setLoading(false);
    }else if (status === 422) {
      await displayMessage(status, data.message, 4000);
      setLoading(false);
    }else {
      await displayMessage(status, 4000);
      setLoading(false);
    }
  };

  const displayMessage = (status, message, time) => {
    return new Promise((resolve) => {
      setResponseStatus(status);
      setResponseMessage(message);
      setDisplayStatus(true);
      setTimeout(() => {
        setDisplayStatus(false);
        setResponseStatus("");
        setResponseMessage("");
        resolve();
      }, time);
    });
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingComponent />
      ) : finished ? (
        <FinishAd adId={adId} />
      ) : (
        <section id="page-content" className="page-wrapper">
          <div
            style={{
              background: 'url("assets/images/customizer/pattern/3.png")',
            }}
            className="d-inline-block w-100"
          >
            <div className="container-fluid  pt-115 pb-60">
              <div className="card text-center py-5  px-5 card-limit-size">
                <AdsCreationContext.Provider
                  value={{
                    categories,
                    governorates,
                    callGetCategoryFeatures,
                    categoryFeatures,
                  }}
                >
                  <AdCreationStepper setFullData={setFullData} />
                </AdsCreationContext.Provider>
              </div>
            </div>
          </div>
          {displayStatus && (
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
              <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                <ErrorHandler
                  status={responseStatus}
                  message={responseMessage}
                />
              </div>
            </div>
          )}
        </section>
      )}
    </Fragment>
  );
};

export default PropertiesAdsCreation;
