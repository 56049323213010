import React, {useContext} from "react";
import { loadScript } from "../../../hooks/load-script";
import { resources } from "../../../services/config";
import ScreenSizeContext from "../../../contexts/screen-size-context";

const MapPreview = ({ lat, lng, language }) => {
  loadScript(resources.mapPollyfill, false);
  loadScript(resources.mapPlaces, false);
  loadScript(resources.mapLauncher, true);
  const screenWidth = useContext(ScreenSizeContext).screenWidth;
  return (
    <React.Fragment>
      <input type="hidden" id="lat" value={lat} />
      <input type="hidden" id="lng" value={lng} />
      <div id="map" style={{ height: `${screenWidth >= 768 ? '400': (screenWidth * 9/ 16)}px` }}></div>
    </React.Fragment>
  );
};

export default MapPreview;
