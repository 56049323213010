import React, { useContext } from "react";
import TranslationContext from "../contexts/translation-context";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ErrorHandler = ({ status, message, bgColor }) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const classes = useStyles();
  const translations = useContext(TranslationContext).errors;
  let alertType = "";
  let alertMessage = "";

  const success = status >= 200 && status < 300;
  const warning = status >= 400 && status < 500;
  const error = status >= 500;

  if (success) {
    alertType = "success";
    alertMessage = message ? message : translations.success;
  } else if (warning) {
    alertType = "warning";
    if (status === 400) {
      alertMessage = message ? message : translations.badRequest;
    } else if (status === 401) {
      alertMessage = translations.unauthorized;
    } else if (status === 403) {
      alertMessage = translations.forbidden;
    } else if (status === 404) {
      alertMessage = translations.notFound;
    } else if (status === 422) {
      alertMessage = message;
    } else {
      alertMessage = translations.badRequest;
    }
  } else if (error) {
    alertType = "error";
    alertMessage = translations.server;
  } 

  return (
    <div className={classes.root}>
      {
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => handleClose}
        >
          <Alert
            onClose={() => handleClose}
            severity={alertType}
            style={{
              backgroundColor: bgColor,
              
            }}
            
          >
            <div className="font-s-text font-w-s-light font-s-medium px-3">{alertMessage}</div>
          </Alert>
        </Snackbar>
      }
    </div>
  );
};

export default ErrorHandler;
