import React, { Fragment, useContext, useEffect, useState } from "react";
import { colorsPalette } from "../../../services/config";
import TranslationContext from "../../../contexts/translation-context";
import AdsCreationContext from "../../../contexts/not-public/ads-creation-context";
import StepperButtonsBar from "./stepper-buttons-bar";

const SecondaryInfo = ({
  handleBack,
  handleNext,
  handleReset,
  activeStep,
  steps,
  setAllFormData,
}) => {
  const translator = useContext(TranslationContext).ads;
  const { categoryFeatures } = useContext(AdsCreationContext);
  const [checkCategoryFeatures, setCheckCategoryFeatures] = useState(false);
  const [featuresValues, setFeaturesValues] = useState([]);
  const [validationActive, setValidationActive] = useState(false);
  let mandatoryList = [];
  const [mandatory, setMandatory] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Object.keys(categoryFeatures).length > 0) {
      setCheckCategoryFeatures(true);
      typeof categoryFeatures.string !== "undefined" &&
        categoryFeatures.string.map(
          (feature) => feature.is_mandatory && mandatoryList.push(feature.id)
        );
      typeof categoryFeatures.extra !== "undefined" &&
        categoryFeatures.extra.map(
          (feature) => feature.is_mandatory && mandatoryList.push(feature.id)
        );
      setMandatory([...mandatoryList]);
    }
  }, [categoryFeatures]);

  useEffect(() => {
    window.refreshAllSelectPickers();
  }, [checkCategoryFeatures]);

  const handleInput = (value, featureId) => {
    if (value !== "0" && value !== "") {
      let restMandatory = [...mandatory];
      if (restMandatory.includes(featureId)) {
        let index = restMandatory.indexOf(featureId);
        index > -1 && restMandatory.splice(index, 1);
        setMandatory([...restMandatory]);
      }
      let existIndex = -1;
      let tempFeatures = [...featuresValues];
      featuresValues.map((item, index) => {
        item.feature_id === featureId && (existIndex = index);
      });
      if (existIndex > -1) {
        tempFeatures.splice(existIndex, 1);
      }
      tempFeatures = [
        ...tempFeatures,
        {
          feature_id: featureId,
          feature_value: value,
        },
      ];
      setFeaturesValues([...tempFeatures]);
    }
  };

  const submitForm = () => {
    setValidationActive(true);
    let checkValidationsResult = checkValidations();
    if (checkValidationsResult) {
      setAllFormData("secondary", { features: featuresValues });
      handleNext();
    }
  };

  const checkValidations = () => {
    const validationsStatus = mandatory.length === 0;
    return validationsStatus;
  };

  return (
    <Fragment>
      <div className="h-100">
      <h1 className="my-4 text-s-secondary">
                {translator.propDetails}
              </h1>
        {checkCategoryFeatures ? (
          <div className="h-100">
            <div className="my-4">
              
              <div className="row">
                {typeof categoryFeatures.string !== "undefined" &&
                  categoryFeatures.string.map((feature) => (
                    <div
                      key={feature.id}
                      className="form-group mb-3 col-sm-12 col-md-4"
                      style={{ textAlign: "start" }}
                    >
                      <label>{feature.name}</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className={`${feature.icon}  fa-lg`}
                              style={{ color: colorsPalette.secondary }}
                            ></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(event) =>
                            handleInput(event.target.value, feature.id)
                          }
                        />
                      </div>
                      {validationActive && mandatory.includes(feature.id) && (
                        <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
                          <i className="fas fa-exclamation-circle"></i>{" "}
                          {translator.required}
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            {typeof categoryFeatures.extra !== "undefined" &&
              categoryFeatures.extra.map((feature) => (
                <div key={feature.id}>
                  <div className="dropdown-divider"></div>
                  <div className="my-4">
                    <h1 className="my-4 text-capitalize text-s-secondary">
                      {feature.name}
                    </h1>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 offset-md-3 my-4">
                        <select
                          className="selectpicker form-control"
                          title={translator.select}
                          data-hide-disabled="true"
                          data-live-search="true"
                          style={{
                            backgroundColor:
                              colorsPalette.skyBlue + "!important",
                          }}
                          onChange={(event) =>
                            handleInput(event.target.value, feature.id)
                          }
                        >
                          {feature.extra_options.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 offset-md-3">
                      {validationActive && mandatory.includes(feature.id) && (
                        <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
                          <i className="fas fa-exclamation-circle"></i>{" "}
                          {translator.required}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="alert d-flex flex-column my-50">
            <h1 className="my-50">{translator.noFeatures}</h1>
            <i className="fas fa-info-circle display-1 my-50"></i>
          </div>
        )}
      </div>
      <StepperButtonsBar
        handleBack={handleBack}
        activeStep={activeStep}
        handleReset={handleReset}
        steps={steps}
        submitForm={submitForm}
      />
    </Fragment>
  );
};

export default SecondaryInfo;
