import React, {useContext} from 'react';
import TranslationContext from "../../contexts/translation-context";
import MyAccount from "./my-account";
import { useState } from 'react';

const ChangePassword = (props) => {
    const translator = useContext(TranslationContext).sign;
    const [showPassword, setShowPassword] = useState(false);
    return (
      <React.Fragment>
        <MyAccount routeInfo={props}>
          <div className="d-flex mb-4">
            <h1 className="font-s-title">{translator.changePassword}</h1>
          </div>
          <div className="d-flex">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" style={{ width: "150px" }}>
                  {translator.currentPassword}
                </span>
              </div>
              <input
                type="password"
                className="form-control font-s-text font-s-regular"
                placeholder={translator.currentPasswordText}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" style={{ width: "150px" }}>
                  {translator.newPassword}
                </span>
              </div>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control font-s-text font-s-regular"
                placeholder={translator.newPasswordText}
              />
              <div
                className="input-group-append pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                <span className="input-group-text bg-s-primary text-white font-s-text font-s-small font-w-s-medium d-flex ">
                  <i
                    className={`fas fa-${
                      showPassword ? "eye" : "eye-slash"
                    } text-s-${
                      showPassword ? "candy" : "secondary"
                    } font-s-medium mx-1`}
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-block bg-s-primary"
              style={{ height: "50px" }}
            >
              <span className="text-white font-s-text font-s-medium font-w-s-medium">
                {translator.changePassword}
              </span>
            </button>
          </div>
        </MyAccount>
      </React.Fragment>
    );
}
 
export default ChangePassword;