import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import {
  getSearchResults,
  toggleFavorite,
} from "../../services/data-request-center";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import TranslationContext from "../../contexts/translation-context";
import LoadingComponent from "../../components/loading-component";
import AdSide from "../../components/ad-side";
import AdsColumnsView from "./columns-view";
import AdsListView from "./list-view";
import PaginationBar from "./pagination-bar";
import { Redirect } from "react-router-dom";
import ErrorHandler from "../../helpers/error-handler";

const SearchResults = (props) => {
  const translator = useContext(TranslationContext).ads;
  const { direction } = useContext(LayoutDirectionContext);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("list");
  const [propertiesAds, setpropertiesAds] = useState({});
  const [screenWidth, setScreenWidth] = useState();
  const [paginate, setPaginate] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [displayStatus, setDisplayStatus] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [results, setResults] = useState(false);

  useLayoutEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    screenWidth <= 1150 && setView("columns");
  }, [screenWidth]);

  useEffect(() => {
    typeof props.location.state !== "undefined"
      ? setSearchParams(props.location.state.searchParams)
      : setRedirect(true);
  }, []);

  useEffect(() => {
    Object.keys(searchParams).length > 0 && callGetSearchResults();
  }, [searchParams]);

  useEffect(() => {
    if (Object.keys(propertiesAds).length > 0) {
      setLoading(false);
    }
  }, [propertiesAds]);

  useEffect(() => {
    paginate !== "" && callGetSearchResults();
  }, [paginate]);

  const callGetSearchResults = async () => {
    const { data, status } = await getSearchResults(searchParams, paginate);
    if (status === 200) {
      setpropertiesAds(data.propertiesAds);
      setResults(true);
      setLoading(false);
    } else {
      setpropertiesAds({ ads: [] });
      setResults(false);
      setLoading(false);
      await displayMessage(status, data.message, 4000);
    }
  };

  window.addEventListener("resize", function () {
    setScreenWidth(window.innerWidth);
  });

  let propertyAdIndex;
  const callToggleFavorite = async (favorited, adId) => {
    const { status } = await toggleFavorite(favorited, adId);
    if (status === 200) {
      propertyAdIndex = propertiesAds.ads.findIndex(
        (property) => property.uuid === adId
      );
      const tempPropertiesAds = { ...propertiesAds };
      tempPropertiesAds.ads[propertyAdIndex].favorited = !favorited;
      setpropertiesAds(tempPropertiesAds);
    }
  };

  const displayMessage = (status, message, time) => {
    return new Promise((resolve) => {
      setResponseStatus(status);
      setResponseMessage(message);
      setDisplayStatus(true);
      setTimeout(() => {
        setDisplayStatus(false);
        setResponseStatus("");
        setResponseMessage("");
        resolve();
      }, time);
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <React.Fragment>
          {redirect && <Redirect to="/" />}
          <LoadingComponent />
        </React.Fragment>
      ) : (
        <section className="page-wrapper">
          <div
            style={{
              background: 'url("/assets/images/customizer/pattern/3.png")',
            }}
            className="d-inline-block w-100"
          >
            <div className="featured-flat-area pt-115 pb-60">
              <div
                className={`card text-center py-5 ${
                  screenWidth > 600 && "mx-50"
                } `}
              >
                <div className="container">
                  <div className="row">
                    <div class="col-lg-8 order-lg-1">
                      {results ? (
                        <div className="d-flex flex-column justify-content-between h-100">
                          <div class="row px-1">
                            <div className="d-flex justify-content-between bg-s-primary py-1  mb-4  px-3 w-100">
                              <div className="d-flex align-items-baseline text-white font-s-regular">
                                <i className="fas fa-search"></i>
                                <span className="px-1">
                                  {`${translator.resultsIn} ${
                                    searchParams.adPurpose === 0
                                      ? translator.sale
                                      : translator.rent
                                  } ${translator.inGovernorate}
                                ${propertiesAds.governorate}`}
                                </span>
                              </div>
                              {screenWidth > 1150 && (
                                <div className="d-flex align-items-baseline ">
                                  <i
                                    className={`fas fa-th-large text-${
                                      view === "columns"
                                        ? "s-secondary"
                                        : "white"
                                    } fa-2x mx-2 pointer `}
                                    onClick={() => setView("columns")}
                                  ></i>
                                  <i
                                    className={`far fa-list-alt text-${
                                      view === "list" ? "s-secondary" : "white"
                                    } fa-2x mx-2 pointer `}
                                    onClick={() => setView("list")}
                                  ></i>
                                </div>
                              )}
                            </div>

                            {view === "columns" ? (
                              <AdsColumnsView
                                propertiesAds={propertiesAds.ads}
                                handleToggleFavorite={callToggleFavorite}
                              />
                            ) : (
                              <AdsListView
                                propertiesAds={propertiesAds.ads}
                                handleToggleFavorite={callToggleFavorite}
                              />
                            )}
                          </div>
                          {propertiesAds.last_page > 1 && (
                            <PaginationBar
                              pagesCount={propertiesAds.last_page}
                              currentPage={propertiesAds.current_page}
                              firstElement={propertiesAds.first_item}
                              lastElement={propertiesAds.last_item}
                              total={propertiesAds.total}
                              direction={direction}
                              translator={translator}
                              setPaginate={setPaginate}
                            />
                          )}
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className="bg-s-primary d-flex h-100 align-items-center justify-content-center">
                            <span className="text-s-deepWarning font-s-title font-s-xlarge">
                              {translator.searchError}
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="col-lg-4 order-lg-2 bg-light py-5 px-1">
                      <AdSide handleSearchParams={setSearchParams} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {displayStatus && (
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <ErrorHandler status={responseStatus} message={responseMessage} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchResults;
