import React, { useState, useContext, useEffect } from "react";
import App from "./App";

import LayoutDirectionContext from "./contexts/layout-direction-context";
import ScreenSizeContext from "./contexts/screen-size-context";
import TranslationContext from "./contexts/translation-context";
import MasterDataContext from "./contexts/master-data-context";
import ProfileContext from "./contexts/profile-context";
import toggleDirectionHelper from "./helpers/toggle-direction-helper";
import LoadingComponent from "./components/loading-component";
import {
  loadProfile,
  getGovernorates,
  getSearchableCategories,
  getArticles,
  prepareProfileData,
  getLastAddedAds,
  getLastAgents,
} from "./services/data-request-center";

const ContextsComponents = () => {
  const initLayoutDirection = useContext(LayoutDirectionContext);
  const initTranslations = useContext(TranslationContext);
  const [language, setLanguage] = useState(initLayoutDirection.language);
  const [direction, setDirection] = useState(initLayoutDirection.direction);
  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  const [translations, setTranslations] = useState(initTranslations[language]);
  const [loading, setLoading] = useState(true);
  const [masterData, setMasterData] = useState({});
  const [toggleRender, setToggleRender] = useState(0);
  const [profile, setProfile] = useState({ isLogged: false });
  const [searchableCategories, setSearchableCategories] = useState({});
  const [governorates, setGovernorates] = useState({});
  const [articles, setArticles] = useState({});
  const [propertiesAds, setPropertiesAds] = useState({});
  const [lastAgents, setLastAgents] = useState({});
  const [profileProcess, setProfileProcess] = useState(false);
  const [locationsProcess, setLocationsProcess] = useState(false);
  const [searchableCategoriesProcess, setSearchableCategoriesProcess] =
    useState(false);
  const [articlesProcess, setArticlesProcess] = useState(false);
  const [propertiesProcess, setPropertiesProcess] = useState(false);
  const [lastAgentsProcess, setLastAgentsProcess] = useState(false);

  useEffect(() => {
    callLoadProfile();
    callSearchableCategories();
    callGetGovernorates();
    callArticles();
    callGetLastAddedAds();
    callGetLastAgents();
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    if (
      profileProcess &&
      locationsProcess &&
      searchableCategoriesProcess &&
      articlesProcess &&
      propertiesProcess &&
      lastAgentsProcess
    ) {
      setMasterData({
        ...searchableCategories,
        ...governorates,
        ...articles,
        ...propertiesAds,
        ...lastAgents,
      });
      setLoading(false);
    }
  }, [
    profileProcess,
    locationsProcess,
    searchableCategoriesProcess,
    articlesProcess,
    propertiesProcess,
    lastAgentsProcess,
  ]);

  useEffect(() => {
    setTranslations(initTranslations[language]);
  }, [language]);

  window.addEventListener("resize", function () {
    setScreenWidth(window.innerWidth);
    if (window.innerHeight < window.innerWidth && window.innerHeight < 768)
      setScreenHeight(window.innerWidth * 1.6);
    else if (window.innerHeight < 700) setScreenHeight(700);
  });

  const callLoadProfile = async () => {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken !== null) {
      const { data, status } = await loadProfile();
      if (status === 200) {
        let profileData = prepareProfileData(data.user, data.user.profile);
        handleSetProfile(profileData);
      } else if (status > 400 && status < 500) {
        localStorage.removeItem("accessToken");
      }
    }
    setProfileProcess(true);
  };

  const callSearchableCategories = async () => {
    const { data, status } = await getSearchableCategories();
    if (status === 200) {
      setSearchableCategories({
        searchableCategories: data.searchableCategories,
      });
      setSearchableCategoriesProcess(true);
    }
  };

  const callGetGovernorates = async () => {
    const { data, status } = await getGovernorates();
    status === 200 &&
      setGovernorates({
        governorates: data.governorates,
      });
    setLocationsProcess(true);
  };

  const callArticles = async () => {
    const { data, status } = await getArticles();
    if (status === 200) {
      setArticles({ articles: data.articles });
      setArticlesProcess(true);
    }
  };

  const callGetLastAddedAds = async () => {
    const { data, status } = await getLastAddedAds();
    if (status === 200) {
      setPropertiesAds(data);
      setPropertiesProcess(true);
    }
  };

  const callGetLastAgents = async () => {
    const { data, status } = await getLastAgents();
    console.log(data);
    if (status === 200) {
      setLastAgents(data);
      setLastAgentsProcess(true);
    }
  };

  const toggleStyleDirection = () => {
    toggleDirectionHelper(setLanguage, setDirection);
  };

  const layoutDirectionInfo = {
    language: language,
    direction: direction,
    toggleDirection: toggleStyleDirection,
  };

  const handleSetProfile = (profile) => {
    profile.language &&
      localStorage.getItem("language") !== profile.language &&
      toggleStyleDirection();
    setProfile(profile);
  };

  const handleSetmasterData = (masterData) => {
    setMasterData(masterData);
    setToggleRender(toggleRender + 1);
  };

  return (
    <React.Fragment>
      <LayoutDirectionContext.Provider value={layoutDirectionInfo}>
        <ScreenSizeContext.Provider value={{ screenWidth, screenHeight }}>
          <TranslationContext.Provider value={translations}>
            <ProfileContext.Provider
              value={{ profile, setProfile: handleSetProfile }}
            >
              <MasterDataContext.Provider
                value={{
                  masterData,
                  setMasterData: handleSetmasterData,
                  toggleRender,
                }}
              >
                {loading ? <LoadingComponent /> : <App />}
              </MasterDataContext.Provider>
            </ProfileContext.Provider>
          </TranslationContext.Provider>
        </ScreenSizeContext.Provider>
      </LayoutDirectionContext.Provider>
    </React.Fragment>
  );
};

export default ContextsComponents;
