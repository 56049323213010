import { useEffect } from "react";

export const useLoadStyle = (styleSource) => {
  

  useEffect(() => {
    const head = document.getElementsByTagName("HEAD")[0];
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = styleSource;
    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, [styleSource]);
}
 
