import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import ScreenSizeContext from "../../contexts/screen-size-context";
import TranslationContext from "../../contexts/translation-context";
import ProfileContext from "../../contexts/profile-context";
import { colorsPalette } from "../../services/config";
import AdSide from "../../components/ad-side";
import MapPreview from "./show-ad-components/map-preview";
import LoadingComponent from "../../components/loading-component";
import PageNotFound from "../404";
import { Redirect } from "react-router-dom";
import {
  getPropertyAd,
  toggleFavorite,
} from "../../services/data-request-center";
import { Divider, Tooltip, Button } from "@material-ui/core";
import { MaterialGridList } from "../../components/ui-components/material-grid-list";

const PropertiesAdsShow = (props) => {
  const adId = props.match.params.id;
  const screenWidth = useContext(ScreenSizeContext).screenWidth;
  const language = useContext(LayoutDirectionContext).language;
  const translator = useContext(TranslationContext).ads;
  const { profile } = useContext(ProfileContext);
  const [loading, setLoading] = useState(true);
  const [propertyAd, setPropertyAd] = useState(null);
  const [imageOnDisplay, setImageOnDisplay] = useState();
  const [mapPosition, setMapPosition] = useState({ lat: 0, lng: 0 });
  const [notFound, setNotFound] = useState(false);
  const [favorited, setFavorited] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [redirect, setRedirect] = useState(false);

  useLayoutEffect(() => {
    document.getElementById("scrollUp").click();
  }, []);

  useEffect(() => {
    Object.keys(searchParams).length > 0 && setRedirect(true);
  }, [searchParams]);

  const callGetPropertyAd = async () => {
    const { data, status } = await getPropertyAd(adId);
    if (status === 200) {
      setPropertyAd(data.propertyAd);
      setMapPosition({ lat: data.propertyAd.lat, lng: data.propertyAd.lng });
      setImageOnDisplay(data.propertyAd.default_image);
      setFavorited(data.propertyAd.is_favorited);
    } else if (status === 422) {
      setNotFound(true);
      setLoading(false);
    } else {
      console.log(data);
    }
  };

  const callToggleFavorite = async () => {
    const { status } = await toggleFavorite(favorited, adId);
    status === 200 && setFavorited(!favorited);
  };

  useEffect(() => {
    callGetPropertyAd();
  }, []);

  useEffect(() => {
    if (propertyAd !== null) {
      setLoading(false);
    }
  }, [propertyAd]);

  return (
    <React.Fragment>
      {redirect && (
        <Redirect
          to={{
            pathname: "/search-properties-results",
            state: { searchParams },
          }}
        />
      )}
      {loading ? (
        <LoadingComponent />
      ) : notFound ? (
        <Redirect to="/404" />
      ) : (
        <section>
          <div
            className="d-inline-block w-100"
            style={{
              background: 'url("/assets/images/customizer/pattern/3.png")',
            }}
          >
            <div
              className={`container-fluid ${
                screenWidth >= 768 ? "pt-115 pb-60" : ""
              } `}
            >
              <div
                className={`card text-center ${
                  screenWidth >= 768 ? "py-5 px-5" : "py-4 px-1"
                }  card-limit-size`}
              >
                <div
                  className={`${
                    screenWidth >= 768 ? "container" : "container-fluid"
                  }`}
                >
                  <div className="row">
                    <div
                      className={`col-sm-12 ${
                        screenWidth > 1000 ? "col-md-8" : "col-md-12"
                      }`}
                    >
                      {propertyAd.ad_status === 0 && (
                        <div
                          className="bg-s-deepWarning py-1 mb-2"
                          style={{
                            borderBottom: "2px solid " + colorsPalette.cream,
                            borderTop: "2px solid " + colorsPalette.cream,
                          }}
                        >
                          <p className="text-white font-s-text font-s-small font-w-s-medium my-0">
                            <i className="fas fa-bullhorn"></i>{" "}
                            {translator.underReview}
                          </p>
                        </div>
                      )}
                      <div>
                        <div className="d-flex">
                          <div>
                            <span
                              className={`badge bg-s-${
                                propertyAd.ad_purpose === 0
                                  ? "candy"
                                  : "deepWarning"
                              } text-white font-s-text font-s-${
                                screenWidth >= 768 ? "regular" : "small"
                              } p-2`}
                            >
                              {propertyAd.ad_purpose === 0
                                ? translator.sale
                                : translator.rent}
                            </span>
                          </div>
                          {screenWidth >= 768 ? (
                            <h3 className="mx-3" style={{ textAlign: "start" }}>
                              {propertyAd.title}
                            </h3>
                          ) : (
                            <span
                              className="text-s-primary font-s-small mx-1"
                              style={{ textAlign: "start" }}
                            >
                              {propertyAd.title}
                            </span>
                          )}
                        </div>
                        <div
                          className={` d-flex py-1 px-3 bg-s-cream my-2`}
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="d-flex mx-1 my-1"
                            style={{ alignItems: "baseline" }}
                          >
                            <i class="fas fa-calendar-alt font-s-small"></i>
                            {screenWidth >= 768 ? (
                              <p className="font-s-small font-s-text font-s-light my-0 mx-1">
                                {translator.date} :
                              </p>
                            ) : (
                              <span className="mx-1"></span>
                            )}

                            <p className="font-s-small font-s-text font-s-light m-0">
                              {propertyAd.create_at}
                            </p>
                          </div>
                          <div
                            className="d-flex mx-1 my-2"
                            style={{ alignItems: "baseline" }}
                          >
                            <i class="fas fa-glasses font-s-small"></i>
                            {screenWidth >= 768 ? (
                              <p className="font-s-small font-s-text font-s-light my-0 mx-1">
                                {translator.views} :
                              </p>
                            ) : (
                              <span className="mx-1"></span>
                            )}

                            <p className="font-s-small font-s-text font-s-light m-0">
                              {propertyAd.views}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className="bg-s-cream rounded"
                            style={{
                              height: screenWidth >= 768 ? "450px" : "250px",
                            }}
                          >
                            <img
                              src={imageOnDisplay}
                              className="h-100 pointer"
                              alt="displayed-ad-img"
                            />
                          </div>
                          <div className="my-3">
                            {screenWidth >= 768 ? (
                              <MaterialGridList
                                images={propertyAd.images}
                                handleSetImageOnDisplay={setImageOnDisplay}
                              />
                            ) : (
                              <div className="px-3">
                                <div
                                  className="bg-s-cream py-2 px-2 scrolling-wrapper row flex-row flex-nowrap"
                                  style={{
                                    height: "100px",
                                    overflow: "auto",
                                  }}
                                >
                                  {propertyAd.images.map((image) => (
                                    <div
                                      key={Math.random()}
                                      className="h-100 mx-1 bg-white"
                                      onClick={() => setImageOnDisplay(image)}
                                      style={{
                                        display: "inline-block",
                                        backgroundImage: `url(${image})`,
                                        backgroundSize: "cover",
                                        width:'100px'
                                      }}
                                    ></div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                          {profile.isLogged && (
                            <div
                              className="d-flex py-2 px-3 bg-s-cream my-2"
                              style={{ justifyContent: "space-between" }}
                            >
                              <Tooltip title={translator.favorite} arrow>
                                <i
                                  className={` ${
                                    favorited ? "fas" : "far"
                                  } fa-heart text-s-candy font-s-${
                                    screenWidth >= 768 ? "large" : "medium"
                                  } pointer`}
                                  onClick={() => callToggleFavorite()}
                                ></i>
                              </Tooltip>
                              <Tooltip title={translator.report} arrow>
                                <i
                                  className={`fas fa-exclamation-circle text-s-deepWarning font-s-${
                                    screenWidth >= 768 ? "large" : "medium"
                                  } pointer`}
                                ></i>
                              </Tooltip>
                            </div>
                          )}
                          <div className="my-5">
                            <div className="mb-3 text-s-start">
                              <h4 className="text-s-primary text-s-start">
                                <i className="fas fa-home text-s-primary font-s-large"></i>{" "}
                                {translator.propinfo}
                              </h4>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="d-flex">
                                  <p
                                    className={`font-s-text text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-nowrap mx-1 w-50`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {translator.category} :
                                  </p>
                                  <p
                                    className={`font-s-text ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-s-primary text-nowrap`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {propertyAd.category.parent.name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex">
                                  <p
                                    className={`font-s-text text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-nowrap mx-1 w-50`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {translator.type} :
                                  </p>
                                  <p
                                    className={`font-s-text ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-s-primary text-nowrap`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {propertyAd.category.name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex">
                                  <p
                                    className={`font-s-text text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-nowrap mx-1 w-50`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {translator.governorate} :
                                  </p>
                                  <p
                                    className={`font-s-text ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-s-primary text-nowrap`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {propertyAd.city.name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex">
                                  <p
                                    className={`font-s-text text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-nowrap mx-1 w-50`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {translator.district} :
                                  </p>
                                  <p
                                    className={`font-s-text ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-s-primary text-nowrap`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {propertyAd.district.name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex">
                                  <p
                                    className={`font-s-text text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-nowrap mx-1 w-50`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {translator.area} :
                                  </p>
                                  <p
                                    className={`font-s-text ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-s-primary text-nowrap`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {propertyAd.area} {translator.meter}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex">
                                  <p
                                    className={`font-s-text text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-nowrap mx-1 w-50`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {translator.location} :
                                  </p>
                                  <p
                                    className={`font-s-text ${
                                      screenWidth >= 768
                                        ? "font-s-regular font-w-s-medium"
                                        : "font-s-small font-w-s-light"
                                    } text-s-primary text-nowrap`}
                                    style={{ textAlign: "start" }}
                                  >
                                    {propertyAd.lat !== null
                                      ? translator.lookMap
                                      : translator.noLocation}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {propertyAd.lat !== null && (
                            <div className="p-1 rounded bg-s-primary mb-4">
                              <MapPreview
                                lat={mapPosition.lat}
                                lng={mapPosition.lng}
                                language={language}
                              />
                            </div>
                          )}
                          <Divider />
                          <div className="my-5">
                            <div className="mb-3 text-s-start">
                              <h4 className="text-s-primary text-s-start">
                                <i className="fas fa-list-ul text-s-primary font-s-large"></i>{" "}
                                {translator.subInfo}
                              </h4>
                            </div>
                            <div className="row">
                              {typeof propertyAd.features.string !==
                                "undefined" &&
                                propertyAd.features.string.map((feature) => (
                                  <div className="col-md-6 my-2">
                                    <div
                                      className="px-1 d-flex bg-s-primary rounded p-3"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="d-flex">
                                        <p
                                          className={`font-s-text text-s-secondary ${
                                            screenWidth >= 768
                                              ? "font-s-regular font-w-s-medium"
                                              : "font-s-small font-w-s-light"
                                          } text-nowrap mx-1 my-0`}
                                        >
                                          {feature.name} :
                                        </p>
                                        <p
                                          className={`font-s-text font-s-regular font-w-s-light text-s-cream text-nowrap mx-1 my-0`}
                                        >
                                          {feature.value}
                                        </p>
                                      </div>

                                      <i
                                        className={`${feature.icon} text-s-cream font-s-medium`}
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                              {typeof propertyAd.features.extra !==
                                "undefined" &&
                                propertyAd.features.extra.map((feature) => (
                                  <div className="col-md-6 my-2">
                                    <div
                                      className="px-1 d-flex bg-s-primary rounded p-3"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="d-flex">
                                        <p
                                          className={`font-s-text text-s-secondary ${
                                            screenWidth >= 768
                                              ? "font-s-regular font-w-s-medium"
                                              : "font-s-small font-w-s-light"
                                          } text-nowrap mx-1 my-0`}
                                        >
                                          {feature.name} :
                                        </p>
                                        <p
                                          className={`font-s-text  ${
                                            screenWidth >= 768
                                              ? "font-s-regular font-w-s-light"
                                              : "font-s-small font-w-s-light"
                                          } text-s-cream text-nowrap mx-1 my-0`}
                                        >
                                          {feature.value}
                                        </p>
                                      </div>

                                      <i
                                        className={`${feature.icon} text-s-cream font-s-medium`}
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <Divider />
                          <div className="my-5">
                            <div className="mb-3 text-s-start">
                              <h4 className="text-s-primary text-s-start">
                                <i className="fas fa-spa text-s-primary font-s-large"></i>{" "}
                                {translator.features}
                              </h4>
                            </div>
                            <div className="row">
                              {typeof propertyAd.features.boolean !==
                                "undefined" &&
                                propertyAd.features.boolean.map((feature) => (
                                  <div
                                    className={`col-sm-4 col-md-4 my-2 ${
                                      screenWidth >= 768
                                        ? ""
                                        : "d-flex justify-content-center"
                                    }`}
                                  >
                                    <div
                                      className={`p-3 bg-s-primary rounded w-100 `}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ flexDirection: "column" }}
                                      >
                                        <lable
                                          className="text-s-secondary font-s-regular font-w-s-light font-s-text text-nowrap text-capitalize mb-2"
                                          style={{ overflow: "hidden" }}
                                        >
                                          {feature.name}
                                        </lable>
                                        <i
                                          className={`${feature.icon} ${
                                            screenWidth >= 768
                                              ? "fa-3x"
                                              : "fa-2x"
                                          } text-white`}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <Divider />
                          <div className="my-5">
                            <div className="mb-3 text-s-start">
                              <h4 className="text-s-primary text-s-start">
                                <i className="fas fa-align-justify text-s-primary font-s-large"></i>{" "}
                                {translator.adDescription}
                              </h4>
                              <p
                                className={`text-s-primary font-s-text font-s-${
                                  screenWidth >= 768 ? "medium" : "regular"
                                } font-w-s-light`}
                              >
                                {propertyAd.description}
                              </p>
                            </div>
                            <div className="mb-3">
                              <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                  className="embed-responsive-item"
                                  src={propertyAd.video_url}
                                  allowFullScreen
                                ></iframe>
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div className="my-5">
                            <div
                              className={`mb-3 text-s-${
                                screenWidth >= 768 ? "start" : "center"
                              }`}
                            >
                              <h4 className={`text-s-primary text-s-start`}>
                                <i className="fas fa-money-bill-alt text-s-primary font-s-large"></i>{" "}
                                {translator.pricing}
                              </h4>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div
                                  className={`d-flex flex-column`}
                                >
                                  <lable
                                    className={`text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-medium font-w-s-medium"
                                        : "font-s-regular font-w-s-light"
                                    } font-s-text mb-2`}
                                  >
                                    {translator.price}
                                  </lable>
                                  <p
                                    className={`text-s-primary ${
                                      screenWidth >= 768
                                        ? "font-s-medium font-w-s-medium"
                                        : "font-s-regular font-w-s-light"
                                    } font-s-text`}
                                  >
                                    {propertyAd.price} {translator.egp}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div
                                  className={`d-flex flex-column`}
                                >
                                  <lable
                                    className={`text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-medium font-w-s-medium"
                                        : "font-s-regular font-w-s-light"
                                    } font-s-text mb-2`}
                                  >
                                    {translator.negotiable}
                                  </lable>
                                  <p
                                    className={`text-s-primary ${
                                      screenWidth >= 768
                                        ? "font-s-medium font-w-s-medium"
                                        : "font-s-regular font-w-s-light"
                                    } font-s-text`}
                                  >
                                    {propertyAd.is_negotiable === 1
                                      ? translator.yes
                                      : translator.no}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div
                                  className={`d-flex flex-column`}
                                >
                                  <lable
                                    className={`text-s-secondary ${
                                      screenWidth >= 768
                                        ? "font-s-medium font-w-s-medium"
                                        : "font-s-regular font-w-s-light"
                                    } font-s-text mb-2`}
                                  >
                                    {translator.payment}
                                  </lable>
                                  <p
                                    className={`text-s-primary ${
                                      screenWidth >= 768
                                        ? "font-s-medium font-w-s-medium"
                                        : "font-s-regular font-w-s-light"
                                    } font-s-text`}
                                  >
                                    {propertyAd.payment_method}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div className="my-5">
                            <div className="mb-3 text-s-start">
                              <h4 className="text-s-primary text-s-start">
                                <i className="fas fa-id-card text-s-primary font-s-large"></i>{" "}
                                {translator.advertisor}
                              </h4>
                            </div>
                            <div
                              className="p-4 rounded"
                              style={{
                                background:
                                  'url("/assets/images/customizer/pattern/3.png")',
                                border: colorsPalette.secondary + " 1px solid",
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex">
                                  <i className="fas fa-user-tie text-s-secondary font-s-medium"></i>
                                  <p className="text-s-primary font-s-medium font-w-s-medium font-s-text px-2">
                                    {propertyAd.owner.owner_name}
                                  </p>
                                </div>
                                {propertyAd.owner.owner_company && (
                                  <div className="d-flex">
                                    <i className="far fa-copyright text-s-secondary font-s-medium"></i>
                                    <p className="text-s-primary font-s-medium font-w-s-medium font-s-text px-2"></p>
                                  </div>
                                )}

                                <Button variant="outlined" color="primary">
                                  <div
                                    className="d-flex"
                                    style={{ alignItems: "baseline" }}
                                  >
                                    <i className="fas fa-mobile font-s-medium"></i>
                                    <span className="font-s-regular font-w-s-light mx-1 my-0">
                                      {translator.showContacts}
                                    </span>
                                  </div>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </div>
                      </div>
                    </div>
                    {screenWidth >= 768 && (
                      <div
                        className={`col-sm-12 ${
                          screenWidth > 1000 ? "col-md-4" : "col-md-12"
                        } bg-light py-5`}
                      >
                        <AdSide handleSearchParams={setSearchParams} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default PropertiesAdsShow;
