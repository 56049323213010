export const ads = {
  ar: {
    basicInfo: "المعلومات الأساسية",
    subInfo: "المعلومات التفصيلية",
    options: "المزايا و الكماليات",
    gallery: "معرض الصور",
    ad: "الإعلان",
    finish: "إنهاء",
    next: "التالى",
    back: "السابق",
    sale: "للبيع",
    rent: "للإيجار",
    category: "تصنيف العقار",
    type: "نوع العقار",
    governorate: "المحافظة",
    district: "المدينة أو المنطقة",
    price: "السعر",
    negotiable: "قابل للتفاوض؟",
    payment: "نظام الدفع",
    cash: "كاش",
    premiums: "تقسيط",
    both: "كلاهما",
    paymentOptions: "كاش أو تقسيط",
    area: "المساحة",
    meter: "م٢",
    features: "المزايا و الكماليات",
    noFeatures: "لا توجد معلومات إضافية مطلوبة. من فضلك إضغط التالى",
    propDetails: "تفاصيل العقار",
    select: "إختر من القائمة",
    adDisclaimer:
      "وصف إعلانك بصورة منسقة تحتوى علي معلومات واضحة يزيد من فرص إهتمام الراغبين فى الشراء بإعلانك",
    optional: "إختيارى",
    youtubeDisclaimer:
      "إضافة رابط ڤيديو إختيارى . قد يرفع مقطع مصور لعقارك فرص الإهتمام بإعلانك",
    addimage: "إضافة صورة أو أكثر",
    imgDisclaimer:
      "صور العقار هي أهم وسائل جذب الإهتمام لإعلانك. إحرص على إضافة صور واضحة بجودة عالية .",
    adTitle: "ضع عنواناً معبراً بوضوح عن إعلانك",
    adTextPlaceholder: "أكتب نص الإعلان هنا",
    tipRemove: "حذف الصورة",
    tipAdCover: "تعيين الصورة الرئيسية للإعلان",
    propinfo: "معلومات العقار",
    location: "الموقع",
    lookMap: "أنظر الخريطة بالأسفل",
    noLocation: "لم يقم المُعلن بتحديد الموقع",
    pricing: "السعر و نظام الدفع",
    date: "تاريخ الإعلان",
    views: "المشاهدات",
    favorite: "أضف للمفضلة",
    report: "الإبلاغ عن مخالفة",
    adDescription: "وصف الإعلان",
    advertisor: "بيانات المُعلن",
    showContacts: "أظهر بيانات الإتصال",
    egp: "ج.م",
    yes: "نعم",
    no: "لا",
    underReview: "إعلانك ما زال قيد المراجعة",
    adSuccess: "تهانينا! تم إنشاء إعلانك بنجاح",
    adReviewP:
      "يقوم مراقبون كهرمان بمراجعة الإعلانات قبل نشرها. فى الغالب لن تستغرق عملية المراجعة أكثر من عدة ساعات.",
    publishNotifyP:
      ".سوف يصلك إشعاراً بنشر الإعلان على عنوان بريدك الإلكترونى المسجل فى ملفك الشخصى. إذا كنت لم تقم بإضافة عنوان بريد إلكترونى يمكنك تعديل ملفك من الرابط التالى",
    modifyProfile: "تعديل الملف الشخصى",
    watchAdP:
      "يمكنك الإطلاع على إعلانك فى أي وقت من خلال رابط 'إعلاناتى' الموجود بالقائمة المنسدلة أسفل زر تغيير اللغة أو من خلال الضغط على الرابط التالى",
    wathcAd: "مشاهدة الإعلان",
    featuredAProp: "عقارات مميزة",
    lastProperties: 'أحدث العقارات',
    featuredText:
      "أحدث الإعلانات المنشورة على المنصة بواسطة المعلنين",
    details: "إعرض العقار",
    resultsIn: "نتائج البحث عن عقارات",
    inGovernorate: "داخل محافظة",
    displayItems: "عرض العناصر",
    fromTotal: "من إجمالى",
    search: "إبحث",
    noResults: "لا توجد نتائج تطابق بحثك",
    myAds: "إعلاناتى",
    expired: "منتهى الصلاحية",
    adImage: "صورة الإعلان",
    noProperties: "لا توجد لديك إعلانات حتى الآن",
    title: "العنوان",
    searchError: "خطأ أثناء عملية البحث برجاء المحاولة لاحقاً",
    required: "يجب تعيين قيمة لهذا الحقل",
    maxImg: "حد أقصى ١٥ صورة",  
    imgRequired: "إضافة بعض الصور أساسى لتسجيل إعلانك",
    mapRequired: "تحديد موقع العقار على الخريطة أساسى لإتمام التسجيل.",
    status: "الحالة",
    views: "المشاهدات",
    expiry: "الصلاحية",
    noFavorites: "لا توجد لديك إعلانات فى مفضلتك.",
    myFavorites: "مفضلاتى",
    moreNewAds: 'المزيد من أحدث العقارات',
    showMore: 'عرض المزيد',
    viewers: 'بيانات المشاهدين',
    viewerInterested: 'المشاهد إطّلع على وسائل الاتصال الخاصة بك',
    sponsorship: 'مساحة إعلانية',
  },
  en: {
    basicInfo: "Basic Info",
    subInfo: "Detailed Info",
    options: "Benefits and Luxury",
    gallery: "Photo Gallery",
    ad: "Advertisment",
    finish: "Finish",
    next: "Next",
    back: "Back",
    sale: "For Sale",
    rent: "For Rent",
    category: "Property Category",
    type: "Property Type",
    governorate: "Governorate",
    district: "City or District",
    price: "Price",
    negotiable: "Negotiable?",
    payment: "Payment method",
    cash: "Cash",
    premiums: "Installment",
    both: "Both",
    paymentOptions: "Cash or Installment",
    area: "Area",
    meter: "m²",
    features: "Features & Benefits",
    noFeatures: "No Additional Details required. Please click next",
    propDetails: "Property Details",
    select: "Select from menu",
    adDisclaimer:
    "Describe your announcement in a coordinated manner that contains clear information increases the chances of interested buyers in your announcement.",
    optional: "optional",
    youtubeDisclaimer:
    "Addition of video link is optional. However adding a video of your property increses the chances of interested buyers in your announcement.",
    addimage: "Add image or more",
    imgDisclaimer:
    "Images are the best attraction method to your advertisment. Try hard to add clear & high quality images.",
    adTitle: "Add clear title that express your advertisment.",
    adTextPlaceholder: "Whrite your ad text here.",
    tipRemove: "remove image",
    tipAdCover: "set advertisment cover image",
    propinfo: "Property Information",
    location: "Location",
    lookMap: "look at map below",
    noLocation: "The location was not determined by the advertiseer",
    pricing: "Price & Payment Method",
    date: "Ad date",
    views: "Views",
    favorite: "Add to favorite",
    report: "Report ad",
    adDescription: "Ad Description",
    advertisor: "Advertisor Info",
    showContacts: "Show contacts info",
    egp: "EGP",
    yes: "Yes",
    no: "No",
    underReview: "Your ad is still under review",
    adSuccess:
    "Congratulations! Your advertisment has been successfully created",
    adReviewP:
    "Qahraman moderators are going to reviews your ad before published within next several hours.",
    publishNotifyP:
    "You will receive a notification once your add get approved to publish. If you still has no email in your profile you can modify your profile from next link.",
    modifyProfile: "modify your profile",
    watchAdP:
    "You can see your advertisment at any time through my advertisments which placed in the drop down menu under the language button or through press the next link",
    wathcAd: "watch my ad",
    featuredAProp: "FEATURED PROPERTY",
    lastProperties: 'latest properties',
    featuredText:
    "last added ads which published on platform by the advertisers",
    details: "Show Property",
    resultsIn: "Search results about properties",
    inGovernorate: "within domain of",
    displayItems: "Display items",
    fromTotal: "of total",
    search: "Search",
    noResults: "No results found match your search parameters",
    myAds: "my advertisments",
    expired: "Expired",
    adImage: "Ad Image",
    noProperties: "You don't have any ads on.",
    title: "title",
    searchError: "Search operation failed, please try again later.",
    required: "This field must be filled",
    maxImg: "maximum 15 images",  
    imgRequired: "Add some images to proceed in creation your property ad",  
    mapRequired: "Property location on map is mandatory", 
    status: "status", 
    views: "Views",
    expiry: "Expiry",
    noFavorites: "You don't have any favorites ads.",
    myFavorites: "my favorites",
    moreNewAds: 'more new properties',
    showMore: 'show more',
    viewers: 'viewers info',
    viewerInterested: 'The viewer got your contacts.',
    sponsorship: 'sponsorship space',
  },
};
