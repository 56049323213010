import React, { useContext, useState, useEffect } from "react";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import ScreenSizeContext from "../../contexts/screen-size-context";
import TranslationContext from "../../contexts/translation-context";
import MasterDataContext from "../../contexts/master-data-context";
import { useLoadStyle } from "../../hooks/load-style";
import { resources } from "../../services/config";
import PriceRange from "./find-box-components/price-range";
import AreaRange from "./find-box-components/area-range";
import { Link, Redirect } from "react-router-dom";

const FindBoxSimple = () => {
  useLoadStyle(resources.animateCss);
  const { direction } = useContext(LayoutDirectionContext);
  const screenWidth = useContext(ScreenSizeContext).screenWidth;
  const translator = useContext(TranslationContext).findBox;
  const { masterData } = useContext(MasterDataContext);
  const { searchableCategories, governorates } = masterData;
  const [adPurpose, setAdPurpose] = useState("sale");
  const [searchElementsAppearance, setSearchElementsAppearance] = useState({
    governorate: true,
    district: false,
  });
  const [rendered, setRendered] = useState(false);
  const [reset, setReset] = useState(false);
  const [govoernateSelected, setGovoernateSelected] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [searchParams, setSearchParams] = useState({
    adPurpose: 0,
    areaSegment: 0,
  });
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    rendered &&
      masterData.searchableCategories &&
      masterData.governorates &&
      window.refreshAllSelectPickers();
  }, [masterData]);

  useEffect(() => {
    rendered && window.refreshAllSelectPickers();
  }, [adPurpose]);

  useEffect(() => {
    districts.length > 0 && updateElemetsAppearance("governorate");
  }, [districts]);

  useEffect(() => {
    searchElementsAppearance.district && window.refreshAllSelectPickers();
  }, [searchElementsAppearance]);

  useEffect(() => {
    rendered && window.refreshAllSelectPickers();
    if (reset) {
      updateElemetsAppearance("district");
      setAdPurpose("sale");
      setGovoernateSelected(false);
      setDistricts([]);
      setReset(false);
    }
  }, [reset]);

  const updateElemetsAppearance = (element) => {
    element === "governorate" &&
      setSearchElementsAppearance({ governorate: false, district: true });
    element === "district" &&
      setSearchElementsAppearance({ governorate: true, district: false });
  };

  const getDistricts = (governorateId) => {
    const governorateDistricts = governorates.find(
      (governorate) => governorate.id == governorateId
    );
    setDistricts(governorateDistricts.districts);
  };

  const updateSearchParams = (param) => {
    const currentParams = { ...searchParams };
    setSearchParams({ ...currentParams, ...param });
  };

  const startSearch = () => {
    setRedirect(true);
  };

  return (
    <React.Fragment>
      {redirect && (
        <Redirect
          to={{
            pathname: "/search-properties-results",
            state: { searchParams },
          }}
        />
      )}
      <div
        className="find-home-box py-4 card-limit-size"
        style={{
          marginRight: "0",
          marginLeft: "0",
          margin: "auto",
          width: "90%",
        }}
      >
        {reset ? (
          <div></div>
        ) : (
          <React.Fragment>
            <div className="section-title text-white mb-1">
              <div className="row">
                {screenWidth >= 768 && (
                  <div className="col">
                    <h3 className="text-uppercase">{translator.title}</h3>
                    <h2 className="h1 text-uppercase">{translator.subTitle}</h2>
                  </div>
                )}

                <div
                  className="col d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <div
                    className="d-flex mx-2"
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <div
                      className={`p-2 mb-2 bg-${
                        adPurpose === "sale" ? "s-candy" : "dark"
                      } rounded pointer`}
                      style={{ width: "120px" }}
                      onClick={() => {
                        setAdPurpose("sale");
                        updateSearchParams({ adPurpose: 0 });
                      }}
                    >
                      <p className={`text-white ${screenWidth >= 768 ? 'font-s-medium font-w-s-medium ' : 'font-s-regular font-w-s-light'} font-s-text text-center`}>
                        {translator.sale}{" "}
                        <i
                          className={`fas fa-check-circle font-s-medium text-white ${
                            adPurpose !== "sale" && "d-none"
                          } `}
                        ></i>
                      </p>
                    </div>
                  </div>
                  <div
                    className="d-flex mx-2"
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <div
                      className={`p-2 mb-2 bg-${
                        adPurpose === "rent" ? "s-deepWarning" : "dark"
                      } rounded pointer`}
                      style={{ width: "120px" }}
                      onClick={() => {
                        setAdPurpose("rent");
                        updateSearchParams({ adPurpose: 1 });
                      }}
                    >
                      <p className={`text-white ${screenWidth >= 768 ? 'font-s-medium font-w-s-medium ' : 'font-s-regular font-w-s-light'} font-s-text text-center`}>
                        {translator.rent}{" "}
                        <i
                          className={`fas fa-check-circle font-s-medium text-white ${
                            adPurpose !== "rent" && "d-none"
                          } `}
                        ></i>
                      </p>
                    </div>
                  </div>
                </div>
                {screenWidth >= 768 && (
                  <div
                    className={`col d-flex justify-content-end align-items-center`}
                  >
                    <Link to="/advanced-search">
                      <span
                        className={`text-s-cream font-s-title font-s-large font-w-s-medium text-capitalize`}
                      >
                        {translator.advancedSearch}
                      </span>
                    </Link>
                    <span className="mx-1"></span>
                    <Link to="/advanced-search">
                      <i
                        className={`fa fa-angle-double-${
                          direction === "right" ? "left" : "right"
                        } fa-3x text-s-secondary`}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="find-homes">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="d-flex flex-column">
                    <div>
                      <p style={{ color: "transparent" }} className="mb-0">
                        .
                      </p>
                    </div>
                    <div>
                      <div
                        className="find-home-item custom-select"
                        style={{
                          marginBottom: `${
                            screenWidth >= 768 ? "30px" : "15px"
                          }`,
                        }}
                      >
                        <select
                          className="selectpicker"
                          title={translator.category}
                          data-container="body"
                          onChange={(event) => {
                            updateSearchParams({
                              categoryId: event.target.value,
                            });
                          }}
                        >
                          {searchableCategories.map((category) => (
                            <option key={category.uuid} value={category.uuid}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="d-flex flex-column">
                    <div>
                      <p
                        className={`text-s-secondary font-s-small font-w-s-medium mb-0 ${
                          govoernateSelected &&
                          "animate__animated animate__tada"
                        } `}
                      >
                        {govoernateSelected
                          ? translator.selectDistrict
                          : translator.selectGovernoerate}{" "}
                        <i className="fas fa-hand-point-down text-s-deepWarning font-s-medium"></i>
                      </p>
                    </div>
                    <div
                      style={{
                        display: searchElementsAppearance.governorate
                          ? "block"
                          : "none",
                      }}
                    >
                      <div className="find-home-item custom-select">
                        <select
                          className="selectpicker"
                          title={translator.location}
                          data-hide-disabled="true"
                          data-live-search="true"
                          data-container="body"
                          onChange={(event) => {
                            setGovoernateSelected(true);
                            getDistricts(event.target.value);
                            updateSearchParams({
                              governorateId: event.target.value,
                            });
                          }}
                        >
                          {governorates.map((governate) => (
                            <option key={governate.id} value={governate.id}>
                              {governate.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: searchElementsAppearance.district
                          ? "block"
                          : "none",
                      }}
                    >
                      <div className="find-home-item custom-select">
                        <select
                          className="selectpicker"
                          title={translator.subLocation}
                          data-hide-disabled="true"
                          data-live-search="true"
                          data-container="body"
                          onChange={(event) => {
                            updateSearchParams({
                              districtId: event.target.value,
                            });
                          }}
                        >
                          {districts.length > 0 && (
                            <option value="0">{translator.allCities}</option>
                          )}
                          {districts.map((district) => (
                            <option key={district.id} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="find-home-item custom-select">
                    <select
                      className="selectpicker"
                      title={translator.priceRange}
                      data-container="body"
                      onChange={(event) => {
                        updateSearchParams({
                          priceSegment: event.target.value,
                        });
                      }}
                    >
                      <PriceRange adPurpose={adPurpose} />
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="find-home-item custom-select">
                    <select
                      className="selectpicker"
                      title={translator.areaRange}
                      data-container="body"
                      onChange={(event) => {
                        updateSearchParams({
                          areaSegment: event.target.value,
                        });
                      }}
                    >
                      <AreaRange />
                    </select>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div className="find-home-item my-0">
                        <button
                          className={`button-1 btn-hover-1 font-s-text ${screenWidth >= 768 ? 'font-s-medium font-w-s-medium ' : 'font-s-regular font-w-s-medium'} border-0`}
                          onClick={startSearch}
                        >
                          {translator.search}
                        </button>
                      </div>
                      <div className="find-home-item my-0 mx-3">
                        <button
                          className={`button-1 btn-hover-1 bg-s-deepWarning font-s-text ${screenWidth >= 768 ? 'font-s-medium font-w-s-medium ' : 'font-s-regular font-w-s-medium'} border-0`}
                          onClick={() => setReset(true)}
                        >
                          {translator.cancel}
                        </button>
                      </div>
                    </div>
                    {screenWidth < 768 && (
                      <Link to="/advanced-search">
                        <div className="btn ">
                          <i
                            className="fas fa-filter font-s-medium text-s-secondary"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default FindBoxSimple;
