import { Fragment, useState, useEffect, useContext } from "react";
import { useLoadScript } from "../../hooks/load-script";
import { useLoadStyle } from "../../hooks/load-style";
import { resources } from "../../services/config";
import TranslationContext from "../../contexts/translation-context";
import {
  getGovernorates,
  getAdvancedSearchableCategories,
} from "../../services/data-request-center";
import PriceRange from "../home-components/find-box-components/price-range";
import AreaRange from "../home-components/find-box-components/area-range";
import { Checkbox } from "@material-ui/core";
import { colorsPalette } from "../../services/config";
import { Redirect } from "react-router-dom";

const AdvancedSearch = () => {
  const language = localStorage.getItem("language");
  const cssDirectory = language === "ar" ? "css-rtl" : "css";
  useLoadStyle("/assets/" + cssDirectory + "/custom/select-picker.css");
  useLoadScript(resources.bootstrapSelectPicker, false);
  useLoadScript(resources.selectpickerLauncher, false);
  const translator = useContext(TranslationContext).findBox;
  const [redirect, setRedirect] = useState(false);
  const [rendered, setRendered] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [advancedSearchableCategories, setAdvancedSearchableCategories] =
    useState([]);
  const [advancedFeatures, setAdvancedFeatures] = useState([]);
  const [selectedAdvancedSearchable, setSelectedAdvancedSearchable] = useState(
    {}
  );
  const [governorates, setGovernorates] = useState([]);
  const [adPurpose, setAdPurpose] = useState("sale");
  const [districts, setDistricts] = useState([]);
  const [searchParams, setSearchParams] = useState({
    adPurpose: 0,
    areaSegment: 0,
    features: [],
  });
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [actionType, setActionType] = useState("plus");
  const [choices, setChoices] = useState({});

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    rendered && window.refreshAllSelectPickers();
  }, [adPurpose]);

  useEffect(() => {
    callGetGovernorates();
    callGetAdvancedSearchableCategories();
  }, []);

  useEffect(() => {
    districts.length > 0 && window.refreshAllSelectPickers();
  }, [districts]);

  useEffect(() => {
    if (selectedCategory != 0) prepareAdvancedSearchList();
  }, [selectedCategory]);

  useEffect(() => {
    window.refreshAllSelectPickers();
  }, [selectedAdvancedSearchable]);

  const prepareAdvancedSearchList = () => {
    const advancedSearchCategoryId = advancedSearchableCategories.findIndex(
      (item) => item.uuid == selectedCategory
    );
    setSelectedAdvancedSearchable({
      ...advancedSearchableCategories[advancedSearchCategoryId][
        "searchable_features"
      ],
    });
  };

  const callGetAdvancedSearchableCategories = async () => {
    const { data, status } = await getAdvancedSearchableCategories();
    status === 200 &&
      setAdvancedSearchableCategories([
        ...data["advancedSearchableCategories"],
      ]);
    status === 200 && window.refreshAllSelectPickers();
  };

  const callGetGovernorates = async () => {
    const { data, status } = await getGovernorates();
    status === 200 && setGovernorates(data.governorates);
    status === 200 && window.refreshAllSelectPickers();
  };

  const getDistricts = (governorateId) => {
    const governorateDistricts = governorates.find(
      (governorate) => governorate.id == governorateId
    );
    setDistricts(governorateDistricts.districts);
  };

  const updateSearchParams = (param) => {
    setSearchParams({ ...searchParams, ...param });
  };

  const checkValueExist = (featureId, value) => {
    var advancedFeaturesShadow = [...advancedFeatures];

    var featureIndex = advancedFeaturesShadow.findIndex(
      (advancedFeature) => advancedFeature["feature_id"] === featureId
    );
    if (featureIndex > -1) {
      var valueList = [
        ...advancedFeaturesShadow[featureIndex]["feature_value"],
      ];
      if (valueList.includes(value)) {
        setActionType("minus");
      } else {
        setActionType("plus");
      }
    }
  };

  const updateFeaturesList = (featureId, type, value = 0) => {
    var advancedFeaturesShadow = [...advancedFeatures];
    var featureIndex = advancedFeaturesShadow.findIndex(
      (advancedFeature) => advancedFeature["feature_id"] === featureId
    );
    if (featureIndex > -1) {
      if (type === "boolean") {
        advancedFeaturesShadow.splice(featureIndex, 1);
      }
      if (type === "string") {
        var valueList = [
          ...advancedFeaturesShadow[featureIndex]["feature_value"],
        ];
        if (valueList.includes(currentInputValue)) {
          valueList.splice(valueList.indexOf(currentInputValue), 1);
          advancedFeaturesShadow[featureIndex]["feature_value"] = [
            ...valueList,
          ];
        } else {
          valueList.push(currentInputValue);
          advancedFeaturesShadow[featureIndex]["feature_value"] = [
            ...valueList,
          ];
        }
        var choicesShadow = { ...choices };
        choicesShadow[featureId] = [...valueList];

        setChoices({ ...choicesShadow });
      }
      if (type === "extra") {
        advancedFeaturesShadow[featureIndex]["feature_value"] = value;
      }
    } else {
      var featureValue;
      if (type === "string") featureValue = [currentInputValue];
      if (type === "extra") featureValue = value;
      if (type === "boolean") featureValue = true;
      advancedFeaturesShadow.push({
        feature_id: featureId,
        feature_value: featureValue,
      });
      if (type === "string") {
        var newFeature = {};
        newFeature[featureId] = featureValue;
        setChoices({ ...choices, ...newFeature });
      }
    }

    setAdvancedFeatures([...advancedFeaturesShadow]);
    setCurrentInputValue("");
    setActionType("plus");
  };

  const startSearch = () => {
    searchParams["features"] = JSON.stringify([...advancedFeatures]);
    setRedirect(true);
  };

  return (
    <Fragment>
      {redirect && (
        <Redirect
          to={{
            pathname: "/search-properties-results",
            state: { searchParams },
          }}
        />
      )}
      <section className="">
        <div
          className="d-inline-block w-100"
          style={{
            background: 'url("/assets/images/customizer/pattern/3.png")',
          }}
        >
          <div className="container-fluid  pt-115 pb-60">
            <div className="card text-center py-5  px-5 card-limit-size">
              <div className="row mb-5">
                <i className="fas fa-filter font-s-large text-s-secondary"></i>{" "}
                <h3 className="mx-2 text-capitalize font-s-title">
                  {translator.advancedSearch}
                </h3>
              </div>
              <div className="row justify-content-between mx-1 mb-4 p-0">
                <ul className="text-s-start">
                  <li style={{ listStyle: "square" }}>
                    <span className="text-muted font-weight-normal">
                      {translator.advancedSearchHint}
                    </span>
                  </li>
                  <li style={{ listStyle: "square" }}>
                    <span className="text-muted font-weight-normal">
                      {translator.searchMandatoryHint}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div
                  className="col d-flex mb-3"
                  style={{ justifyContent: "center" }}
                >
                  <div
                    className="d-flex mx-2"
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <div
                      className={`p-1 bg-${
                        adPurpose === "sale" ? "s-candy" : "dark"
                      } pointer`}
                      style={{ width: "90px", height: "35px" }}
                      onClick={() => {
                        setAdPurpose("sale");
                        updateSearchParams({ adPurpose: 0 });
                      }}
                    >
                      <span className="text-white font-s-regular font-s-text font-w-s-medium text-center">
                        {translator.sale}{" "}
                        <i
                          className={`fas fa-check-circle font-s-regular text-white ${
                            adPurpose !== "sale" && "d-none"
                          } `}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-flex mx-2"
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <div
                      className={`p-1 bg-${
                        adPurpose === "rent" ? "s-deepWarning" : "dark"
                      } pointer`}
                      style={{ width: "90px", height: "35px" }}
                      onClick={() => {
                        setAdPurpose("rent");
                        updateSearchParams({ adPurpose: 1 });
                      }}
                    >
                      <span className="text-white font-s-regular font-s-text font-w-s-medium text-center">
                        {translator.rent}{" "}
                        <i
                          className={`fas fa-check-circle font-s-regular text-white ${
                            adPurpose !== "rent" && "d-none"
                          } `}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="find-home-item custom-select">
                    <select
                      className="selectpicker"
                      title={translator.category}
                      data-container="body"
                      onChange={(event) => {
                        setSelectedCategory(event.target.value);
                        updateSearchParams({ categoryId: event.target.value });
                      }}
                    >
                      {advancedSearchableCategories.map((category) => (
                        <option key={category.uuid} value={category.uuid}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="find-home-item custom-select">
                    <select
                      className="selectpicker"
                      title={translator.location}
                      data-hide-disabled="true"
                      data-live-search="true"
                      data-container="body"
                      onChange={(event) => {
                        getDistricts(event.target.value);
                        updateSearchParams({
                          governorateId: event.target.value,
                        });
                      }}
                    >
                      {governorates.map((governate) => (
                        <option key={governate.id} value={governate.id}>
                          {governate.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="find-home-item custom-select">
                    <select
                      className="selectpicker"
                      title={translator.subLocation}
                      data-hide-disabled="true"
                      data-live-search="true"
                      data-container="body"
                      onChange={(event) => {
                        updateSearchParams({ districtId: event.target.value });
                      }}
                    >
                      {districts.length > 0 && (
                        <option value="0">{translator.allCities}</option>
                      )}
                      {districts.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="find-home-item custom-select">
                    <select
                      className="selectpicker"
                      title={translator.priceRange}
                      data-container="body"
                      onChange={(event) => {
                        updateSearchParams({
                          priceSegment: event.target.value,
                        });
                      }}
                    >
                      <PriceRange adPurpose={adPurpose} />
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="find-home-item custom-select">
                    <select
                      className="selectpicker"
                      title={translator.areaRange}
                      data-container="body"
                      onChange={(event) => {
                        updateSearchParams({
                          areaSegment: event.target.value,
                        });
                      }}
                    >
                      <AreaRange />
                    </select>
                  </div>
                </div>

                <div className="row">
                  {"string" in selectedAdvancedSearchable &&
                    selectedAdvancedSearchable["string"].length > 0 &&
                    selectedAdvancedSearchable["string"].map((feature) => (
                      <div
                        key={feature.id}
                        className="form-group mb-3 col-sm-12 col-md-6"
                        style={{ textAlign: "start" }}
                      >
                        <label>{feature.name}</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className={`${feature.icon}  fa-lg`}
                                style={{ color: colorsPalette.secondary }}
                              ></i>
                            </span>
                          </div>
                          <input
                            id={feature.id}
                            type="text"
                            className="form-control"
                            value={
                              document.getElementById(`${feature.id}`) ===
                              document.activeElement
                                ? currentInputValue
                                : ""
                            }
                            onChange={(event) => {
                              setCurrentInputValue(event.target.value);
                              checkValueExist(feature.id, event.target.value);
                            }}
                          />
                          <div
                            className="input-group-append"
                            onClick={() =>
                              updateFeaturesList(feature.id, "string")
                            }
                          >
                            <span className="input-group-text">
                              <i
                                id={`action-${feature.id}`}
                                className={`fas ${
                                  document.getElementById(`${feature.id}`) ===
                                  document.activeElement
                                    ? `fa-${actionType}-circle text-s-${
                                        actionType === "plus" ? "mint" : "candy"
                                      }`
                                    : "fa-plus-circle text-s-mint"
                                }   fa-lg`}
                              ></i>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <small className="form-text text-muted">
                            {translator.choices}
                          </small>
                          {feature.id in choices &&
                            choices[feature.id].map((value) => (
                              <div className="mx-1 px-2 py-0 bg-s-mint ">
                                <span
                                key={value}
                                className="text-white font-s-regular font-w-s-bold "
                              >
                                {value}
                              </span>
                              </div>
                              
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
                {"extra" in selectedAdvancedSearchable &&
                  selectedAdvancedSearchable["extra"].length > 0 &&
                  selectedAdvancedSearchable["extra"].map((feature) => (
                    <div key={feature.id} className="col-sm-12 col-md-4 my-5">
                      <label>{feature.name}</label>

                      <select
                        className="selectpicker form-control"
                        title={translator.select}
                        data-hide-disabled="true"
                        data-live-search="true"
                        style={{
                          backgroundColor: colorsPalette.skyBlue + "!important",
                        }}
                        onChange={(event) =>
                          updateFeaturesList(
                            feature.id,
                            "extra",
                            event.target.value
                          )
                        }
                      >
                        {feature.extra_options.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                {"boolean" in selectedAdvancedSearchable &&
                  selectedAdvancedSearchable["boolean"].length > 0 &&
                  selectedAdvancedSearchable["boolean"].map((feature) => (
                    <div
                      key={feature.id}
                      className="col-sm-4 col-md-3 col-lg-2"
                    >
                      <div className="form-group align-contents-start">
                        <label>{feature.name}</label>
                        <Checkbox
                          id={`${feature.id}-input`}
                          type="checkbox"
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          className="form-control"
                          style={{ padding: "0" }}
                          onChange={() => {
                            updateFeaturesList(feature.id, "boolean");
                          }}
                        />
                      </div>
                    </div>
                  ))}

                <div className="col-12">
                  <div className="row">
                    <div className="col-md-12 col-sm-5 col-xs-12">
                      <div className="find-home-item">
                        <button
                          className="btn btn-block bg-s-secondary text-white font-s-text font-s-medium font-w-s-medium text-capitalize"
                          style={{ height: "40px" }}
                          onClick={() => startSearch()}
                        >
                          {translator.search}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AdvancedSearch;
