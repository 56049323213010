import React, { Fragment, useState, useEffect, useContext } from "react";
import { showAgent } from "../../services/data-request-center";
import TranslationContext from "../../contexts/translation-context";
import LoadingComponent from "../../components/loading-component";
import PaginationBar from "../../components/ui-components/pagination-bar";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import { Link } from "react-router-dom";
import { colorsPalette } from "../../services/config";
import { Tooltip } from "@material-ui/core";

const ShowAgent = (props) => {
  const agentId = props.match.params.id;
  const translator = {
    ...useContext(TranslationContext).ads,
    ...useContext(TranslationContext).agents,
    ...useContext(TranslationContext).others,
  };
  const [loading, setLoading] = useState(true);
  const [agent, setAgent] = useState(null);
  const [ads, setAds] = useState(null);
  const { direction } = useContext(LayoutDirectionContext);
  const [paginate, setPaginate] = useState("");
  const [view, setView] = useState("list");
  const [screenWidth, setScreenWidth] = useState();

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    callShowAgent();
  }, []);

  useEffect(() => {
    screenWidth < 991 ? setView("columns") : setView("list");
  }, [screenWidth]);

  useEffect(() => {
    paginate !== "" && callShowAgent();
  }, [paginate]);

  const callShowAgent = async () => {
    const { data, status } = await showAgent(agentId, paginate);
    console.log(data);
    if (status === 200) {
      setAgent(data.agent);
      setAds(data.propertiesAds);
      setLoading(false);
    }
  };

  window.addEventListener("resize", function () {
    setScreenWidth(window.innerWidth);
  });

  return (
    <Fragment>
      {loading ? (
        <LoadingComponent />
      ) : (
        <section>
          <div
            className="d-inline-block w-100"
            style={{
              background: 'url("/assets/images/customizer/pattern/3.png")',
            }}
          >
            <div className="container-fluid pt-115 pb-60">
              <div className="card text-center py-5 card-limit-size">
                <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                  <div
                    style={{
                      height: "150px",
                      width: "150px",
                      backgroundImage: `url(${agent.profile.profileImgUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                    }}
                    className="rounded-circle border border-s-cream border-3"
                  ></div>
                  <span className="font-s-title text-s-primary font-s-medium font-w-s-light text-capitalize mt-3">
                    {agent.profile.name}
                  </span>
                  <span className="font-s-title text-s-secondary font-s-medium font-w-s-light text-capitalize my-2">
                    {translator.agent}
                  </span>
                </div>
                <div className="px-5">
                  <div className="d-flex border border-s-cream py-3 px-5 rounded">
                    <div className="d-flex flex-column align-items-start mt-1">
                      <span className="font-s-title text-s-deepWarning font-s-medium mb-2 text-capitalize">
                        {translator.contacts}
                      </span>
                      {agent.contacts.map((contact) => (
                        <div className="d-flex align-items-baseline px-2 my-2">
                          <div style={{ width: "35px" }}></div>
                          <div
                            data-toggle="tooltip"
                            data-placement="top"
                            title={contact.method.name}
                          >
                            <i
                              className={`${contact.method.icon} font-s-medium text-s-secondary`}
                            ></i>
                          </div>
                          <div className="px-2"></div>
                          <span
                            className="font-s-text font-s-regular font-w-s-medium text-s-start"
                            style={{ direction: "ltr" }}
                          >
                            {contact.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className={`${view === "columns" && "row"} px-5 pt-5`}>
                  <div className="my-4">
                    <span className="font-s-title font-s-large font-w-s-medium text-s-deepWarning text-capitalize">
                      {translator.agentAds}
                    </span>
                  </div>

                  {ads.ads.map((propertyAd) => {
                    return (
                      <Fragment>
                        {view === "list" ? (
                          <div
                            className="d-flex flex-column py-3"
                            key={propertyAd.id}
                          >
                            <div className="d-flex flat-item justify-content-start bg-s-primary p-3 shadow-lg mb-4 rounded">
                              <div
                                className="flat-item-image col-5 px-0 bg-s-primary rounded"
                                style={{
                                  height: "200px",
                                }}
                              >
                                <Link to={`/show-property-ad/${propertyAd.id}`}>
                                  <div
                                    className="rounded"
                                    style={{
                                      height: "200px",
                                      backgroundImage: `url(${propertyAd.default_image})`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                  {
                                    <div
                                      className="set-cover-btn p-2 d-flex"
                                      style={{
                                        zIndex: "2",
                                        borderRadius: "50%",
                                        backgroundColor: "rgba(0,0,0,0.7)",
                                      }}
                                    ></div>
                                  }
                                </Link>

                                <div className="flat-link">
                                  <Link
                                    to={`/show-property-ad/${propertyAd.id}`}
                                  >
                                    <span className="font-s-text font-s-regular font-w-s-light ">
                                      {translator.details}
                                    </span>
                                  </Link>
                                </div>
                              </div>

                              <div className="d-flex flex-column col-7">
                                <div
                                  className="d-flex justify-content-between mb-3"
                                  style={{ height: "40px" }}
                                >
                                  <span
                                    style={{ width: "90px" }}
                                    className={` bg-s-${
                                      propertyAd.ad_purpose === 0
                                        ? "candy"
                                        : "deepWarning"
                                    } text-white font-s-regular font-s-text font-w-s-medium text-nowrap p-1  `}
                                  >
                                    {propertyAd.ad_purpose === 0
                                      ? translator.sale
                                      : translator.rent}
                                  </span>
                                  <div className="bg-s-secondary py-1 px-2 ">
                                    <span className="text-white font-s-medium font-s-text font-w-s-medium text-nowrap">{`${propertyAd.price} ${translator.egp}`}</span>
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-start"
                                  style={{ height: "40px" }}
                                >
                                  <div className=" p-2 ">
                                    <i className="fas fa-building text-s-secondary font-s-medium"></i>
                                    <span className="text-white font-s-medium font-s-text font-w-s-light text-nowrap mx-2">
                                      {propertyAd.category.name}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-start"
                                  style={{ height: "40px" }}
                                >
                                  <div className=" p-2 ">
                                    <i class="fas fa-map-marker-alt text-s-secondary font-s-medium"></i>
                                    <span className="text-white font-s-medium font-s-text font-w-s-light text-nowrap mx-2">
                                      {propertyAd.district.name}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-start p-2"
                                  style={{ height: "40px" }}
                                >
                                  <li>
                                    <i class="fas fa-ruler-combined text-s-secondary font-s-medium"></i>
                                    <span className="text-white font-s-regular mx-2">
                                      {`${propertyAd.area} ${translator.meter}`}
                                    </span>
                                  </li>
                                  <span className="mx-2"></span>
                                  <li>
                                    <i
                                      className={`${propertyAd.presentable_features[0].icon} text-s-secondary font-s-medium`}
                                    ></i>
                                    <span className="text-white font-s-regular mx-2">
                                      {propertyAd.presentable_features[0].value}
                                    </span>
                                  </li>
                                  <span className="mx-2"></span>
                                  <li>
                                    <i
                                      className={`${propertyAd.presentable_features[1].icon} text-s-secondary font-s-medium`}
                                    ></i>
                                    <span className="text-white font-s-regular mx-2">
                                      {propertyAd.presentable_features[1].value}
                                    </span>
                                  </li>
                                </div>
                                <div
                                  className="d-flex justify-content-start align-items-center"
                                  style={{ height: "40px" }}
                                >
                                  <div
                                    className="col-8 px-0 text-nowrap"
                                    style={{ overflow: "auto" }}
                                  >
                                    <span className="d-flex text-s-cream font-s-text">
                                      {`${propertyAd.title.substr(0, 50)} `}
                                    </span>
                                  </div>
                                  <div
                                    className="col-4 px-2 text-nowrap"
                                    style={{ overflow: "auto" }}
                                  >
                                    <Link
                                      to={`/show-property-ad/${propertyAd.id}`}
                                    >
                                      <span className="text-s-secondary font-s-regular font-s-text font-w-s-light mx-1 pointer">
                                        ... {translator.details}
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="col-md-6 col-sm-12 "
                            key={propertyAd.id}
                          >
                            <div className="flat-item rounded p-1">
                              <div className="flat-item-image">
                                <span
                                  className={`for-sale bg-s-${
                                    propertyAd.ad_purpose === 0
                                      ? "candy"
                                      : "deepWarning"
                                  } text-white font-s-text font-s-regular font-w-s-medium text-nowrap p-1`}
                                >
                                  {propertyAd.ad_purpose === 0
                                    ? translator.sale
                                    : translator.rent}
                                </span>
                                <Link
                                  to={`/show-property-ad/${propertyAd.id}`}
                                  style={{
                                    height: "250px",
                                  }}
                                >
                                  <img
                                    src={propertyAd.default_image}
                                    className="h-100"
                                    alt=""
                                  />
                                  {
                                    <div
                                      className="column-ad-image-fav p-2 d-flex"
                                      style={{
                                        zIndex: "2",
                                        borderRadius: "50%",
                                        backgroundColor: "rgba(0,0,0,0.7)",
                                      }}
                                    ></div>
                                  }
                                </Link>
                                <div className="flat-link">
                                  <Link
                                    to={`/show-property-ad/${propertyAd.id}`}
                                  >
                                    <span className="font-s-text font-s-regular font-w-s-light ">
                                      {translator.details}
                                    </span>
                                  </Link>
                                </div>
                                <ul className="flat-desc py-1">
                                  <li>
                                    <i class="fas fa-ruler-combined text-s-secondary font-s-medium"></i>
                                    <span className="text-white font-s-regular mx-2">
                                      {`${propertyAd.area} ${translator.meter}`}
                                    </span>
                                  </li>
                                  {propertyAd.presentable_features.length >
                                    0 && (
                                    <li>
                                      <i
                                        className={`${propertyAd.presentable_features[0].icon} text-s-secondary font-s-medium`}
                                      ></i>
                                      <span className="text-white font-s-regular mx-2">
                                        {
                                          propertyAd.presentable_features[0]
                                            .value
                                        }
                                      </span>
                                    </li>
                                  )}

                                  {propertyAd.presentable_features.length >
                                    1 && (
                                    <li>
                                      <i
                                        className={`${propertyAd.presentable_features[1].icon} text-s-secondary font-s-medium`}
                                      ></i>
                                      <span className="text-white font-s-regular mx-2">
                                        {
                                          propertyAd.presentable_features[1]
                                            .value
                                        }
                                      </span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              <div className="flat-item-info py-1">
                                <div className="d-flex justify-content-center flat-title-price bg-s-secondary  py-1 px-2">
                                  <span className="text-white font-s-regular font-s-text font-w-s-medium">
                                    {propertyAd.category.name}
                                  </span>
                                  <span className="text-white font-s-medium font-s-text font-w-s-medium mx-2">
                                    |
                                  </span>
                                  <span className="text-light font-s-regular font-s-text font-w-s-medium">
                                    {propertyAd.district.name}
                                  </span>
                                </div>

                                <div className="d-flex justify-content-center flat-title-price bg-s-primary  py-1 px-2">
                                  <span className="text-white font-s-medium font-s-text font-w-s-medium">{`${propertyAd.price} ${translator.egp}`}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
                <div className="px-5">
                    {ads.last_page > 1 && (
                  <PaginationBar
                    pagesCount={ads.last_page}
                    currentPage={ads.current_page}
                    firstElement={ads.first_item}
                    lastElement={ads.last_item}
                    total={ads.total}
                    direction={direction}
                    translator={translator}
                    setPaginate={setPaginate}
                  />
                )}
                </div>
                
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default ShowAgent;
