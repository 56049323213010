import React, {
  createRef,
  Fragment,
  useState,
  useContext,
  useEffect,
} from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import TranslationContext from "../../../contexts/translation-context";
import StepperButtonsBar from "./stepper-buttons-bar";
import imageCompression from "browser-image-compression";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

let images = [];
let files = [];
const Media = ({
  handleBack,
  handleNext,
  handleReset,
  activeStep,
  steps,
  setAllFormData,
}) => {
  const classes = useStyles();
  const translator = useContext(TranslationContext).ads;
  const inputFile = createRef();
  const [selectedImages, setSelectedImages] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [coverImg, setCoverImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [maxImgs, setMaxImgs] = useState(0);
  const [validationActive, setValidationActive] = useState(false);

  useEffect(() => {
    setMaxImgs(selectedImages.length);
  }, [selectedImages]);

  const options = {
    maxSizeMB: 0.512,
    onProgress: () => setLoading(true),
  };

  const readImage = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve({ image: reader.result, file });
      };
      reader.readAsDataURL(file);
    });
  };

  const prepareSelectedImages = async () => {
    images = [];
    for (let index = 0; index < files.length; index++) {
      try {
        const compressedFile = await imageCompression(files[index], options);
        const readyFile = await readImage(compressedFile);
        setLoading(false);
        images.push(readyFile);
        setSelectedImages([...selectedImages, ...images]);
      } catch (errors) {}
    }
  };

  const appendImages = (event) => {
    if (selectedImages.length + event.target.files.length <= 15) {
      coverImg === null && setCoverImg(0);
      files = [...event.target.files];
      prepareSelectedImages();
    } else {
      setMaxImgs(selectedImages.length + event.target.files.length);
    }
  };

  const removeImage = (targetFile) => {
    const index = selectedImages.findIndex((file) => file === targetFile);
    if (index < coverImg) {
      const nextCoverImgIndex = coverImg - 1;
      setCoverImg(nextCoverImgIndex);
    } else if (index === coverImg) setCoverImg(0);
    selectedImages.length === 1 && setCoverImg(null);
    const tempFilesContainer = selectedImages.filter(
      (file) => file !== targetFile
    );
    setSelectedImages([...tempFilesContainer]);
  };

  const ImagesPreview = () => {
    let previews = [];
    selectedImages.forEach((image, index) => {
      previews.push(
        <div key={Math.random()} className="col-sm-6 col-md-6 my-4">
          <div
            className="px-5 rounded"
            style={{
              backgroundColor: "rgba(31, 34, 62, 0.9)",
              position: "relative",
            }}
          >
            <img src={image.image} height="200px" alt="" />
            <div className="remove-image-btn">
              <Tooltip title={translator.tipRemove} aria-label="add">
                <i
                  className="far fa-times-circle fa-2x text-s-deepWarning"
                  onClick={() => removeImage(image)}
                ></i>
              </Tooltip>
            </div>
            <div className="set-cover-btn">
              <Tooltip title={translator.tipAdCover} aria-label="add">
                <i
                  className={`fa fa-check-circle fa-2x ${
                    coverImg === index ? "text-s-secondary" : "text-s-cream"
                  }`}
                  onClick={() => setCoverImg(index)}
                ></i>
              </Tooltip>
            </div>
          </div>
        </div>
      );
    });
    if (selectedImages.length > 0) return previews;
    return <Fragment></Fragment>;
  };

  const handleInput = (event) => {
    setVideoUrl(event.target.value);
  };

  const submitForm = () => {
    if (!loading) {
      setValidationActive(true);
      if (selectedImages.length > 0 && selectedImages.length <= 15) {
        setAllFormData("media", {
          video_url: videoUrl,
          files: selectedImages,
          coverImg,
        });
        handleNext();
      }
    }
  };

  return (
    <Fragment>
      <div className="my-5">
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              style={{ width: "50px", justifyContent: "center" }}
            >
              <i
                className="fa fa-youtube text-danger"
                style={{ fontSize: "28px" }}
              ></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder={translator.optional}
            style={{ height: "60px" }}
            onChange={(event) => handleInput(event)}
          />
        </div>
        <div className="row mx-1 p-0">
          <p className="text-muted font-weight-normal">
            <i className="fa fa-exclamation-circle text-s-secondary"></i>{" "}
            {translator.youtubeDisclaimer}
          </p>
        </div>
        <div className="my-5">
          <div className="dropdown-divider"></div>
        </div>
        <div>
          <div>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              ref={inputFile}
              onChange={(event) => appendImages(event)}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                {loading ? (
                  <div
                    style={{ height: "48px", width: "48px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div
                      className="spinner-border text-success"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <IconButton
                    aria-label="upload picture"
                    component="span"
                    className="text-s-cream"
                  >
                    <PhotoCamera />
                  </IconButton>
                )}
                <span
                  className={`font-s-text mx-1 ${
                    maxImgs > 15 && "animate__animated animate__rubberBand"
                  } `}
                >
                  {translator.addimage}
                </span>
              </Button>
            </label>
            <input
              accept="image/*"
              className={classes.input}
              id="icon-button-file"
              type="file"
            />
          </div>
          <span
            className={`text-${
              maxImgs > 15 ? "s-candy" : "muted"
            } font-s-text font-s-xsmall  `}
          >
            {translator.maxImg}
          </span>
          {selectedImages.length > 0 ? (
            <div className="row  bg-s-cream rounded">
              <ImagesPreview />
            </div>
          ) : (
            <div className="my-50">

              <i className="far fa-images text-s-cream display-1 my-50"></i>
            </div>
          )}

          {validationActive && maxImgs === 0 && (
            <div className="bg-white d-flex justify-content-center">
              <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
                <i className="fas fa-exclamation-circle"></i>{" "}
                {translator.imgRequired}
              </span>
            </div>
          )}
        </div>
      </div>
      <StepperButtonsBar
        handleBack={handleBack}
        activeStep={activeStep}
        handleReset={handleReset}
        steps={steps}
        submitForm={submitForm}
      />
      <div
        className="row mt-4 mx-1 p-0"
        style={{ position: "absolute", bottom: "10px" }}
      >
        <p className="text-muted font-weight-normal">
          <i className="fa fa-exclamation-circle text-s-secondary"></i>{" "}
          {translator.imgDisclaimer}
        </p>
      </div>
    </Fragment>
  );
};

export default Media;
