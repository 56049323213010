import React from "react";
import { GridList, GridListTile } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  img: {
    height: "90px",
  },
}));

export const MaterialGridList = ({ images, handleSetImageOnDisplay }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={2.5}>
          {images.map((image) => (
            <GridListTile key={Math.random()}>
              <img
                className="pointer"
                src={image}
                onClick={() => handleSetImageOnDisplay(image)}
                alt="property-img"
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </React.Fragment>
  );
};
