import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import TranslationContext from "../../contexts/translation-context";
import ProfileContext from "../../contexts/profile-context";
import { Button, LinearProgress } from "@material-ui/core";
import { login, socialLogin } from "../../services/data-request-center";
import { Fingerprint } from "@material-ui/icons";
import ErrorHandler from "../../helpers/error-handler";
import { useLoadStyle } from "../../hooks/load-style";
import InternationalCodes from "../../components/international-codes";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const SignIn = () => {
  useLoadStyle("/assets/css/custom/select-picker.css");
  const translator = useContext(TranslationContext).sign;
  const { setProfile } = useContext(ProfileContext);
  const [internationalCode, setInternationalCode] = useState("68");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [displayStatus, setDisplayStatus] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [signing, setSigning] = useState(false);
  const [logged, setLogged] = useState(false);

  const handleSignInputs = (event, inputType) => {
    inputType === "code" && setInternationalCode(event.target.value);
    inputType === "mobile" && setMobile(event.target.value);
    inputType === "password" && setPassword(event.target.value);
  };

  const responseGoogle = async (response) => {
    if (response.tokenId) {
      setLogged(true);
      const { data, status, profileData } = await socialLogin(
        2,
        response.profileObj.googleId,
        response.tokenId
      );
      if (status === 200) {
        await displayMessage(status, translator.loginSuccess, 4000);
        setProfile(profileData);
      } else if (status === 422) {
        await displayMessage(status, data.message, 4000);
        setSigning(false);
        setLogged(false);
      } else {
        await displayMessage(status, translator.loginFailed, 4000);
        setSigning(false);
        setLogged(false);
      }
    }
  };

  const responseFacebook = async (response) => {
    if (response.accessToken) {
      setLogged(true);
      const { data, status, profileData } = await socialLogin(
        1,
        response.id,
        response.accessToken
      );
      if (status === 200) {
        await displayMessage(status, translator.loginSuccess, 4000);
        setProfile(profileData);
      } else if (status === 422) {
        await displayMessage(status, data.message, 4000);
        setSigning(false);
        setLogged(false);
      } else {
        await displayMessage(status, translator.loginFailed, 4000);
        setSigning(false);
        setLogged(false);
      }
    }
  };

  const getLogin = async () => {
    setSigning(true);
    const { data, status, profileData } = await login(
      internationalCode,
      mobile,
      password
    );
    if (status === 200) {
      await displayMessage(status, translator.loginSuccess, 4000);
      setProfile(profileData);
    } else if (status === 422) {
      await displayMessage(status, data.message, 4000);
      resetInputs();
      setSigning(false);
    } else {
      await displayMessage(status, translator.loginFailed, 4000);
      resetInputs();
      setSigning(false);
    }
  };

  const displayMessage = (status, message, time) => {
    return new Promise((resolve) => {
      setResponseStatus(status);
      setResponseMessage(message);
      setDisplayStatus(true);
      setTimeout(() => {
        setDisplayStatus(false);
        setResponseStatus("");
        setResponseMessage("");
        resolve();
      }, time);
    });
  };

  const resetInputs = () => {
    setPassword("");
  };

  return (
    <React.Fragment>
      <div
        style={{
          background: 'url("assets/images/customizer/pattern/3.png")',
        }}
        className="d-inline-block w-100 page-content-background"
      >
        <div className="container-fluid pt-115 pb-60">
          <div className="card bg-gray text-center card-limit-size">
            <div className="bg-gray shadow-lg" style={{ padding: "5px 20px" }}>
              <div className="pt-5">
                <img
                  src="assets/images/bg/qahraman-sign-in.svg"
                  height="200px"
                  className="card-img-top"
                  alt="qahrman-sign"
                />
              </div>
              <div className="card-body">
                {signing ? (
                  <React.Fragment>
                    <div
                      className="d-flex flex-column"
                      style={{ backgroundColor: "rgba(31, 34, 62, 0.92)" }}
                    >
                      <div className="my-5">
                        <h4 className="text-s-secondary">
                          {logged ? translator.apiSign : translator.signing}
                        </h4>
                      </div>
                      <LinearProgress color="secondary" />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="mb-4">
                      <h2 className="text-s-secondary">{translator.welcome}</h2>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-12 col-md-6 my-1">
                        <FacebookLogin
                          appId="644200572859841"
                          callback={responseFacebook}
                          onClick={() => setSigning(true)}
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              id="fa-sign-btn"
                              className="btn btn-block"
                            >
                              <div className="d-flex justify-content-center align-items-baseline">
                                <i className="fa fa-facebook-f fa-lg"></i>
                                <span className="font-s-medium font-w-s-light mx-1 btn-text">
                                  {translator.facebook}
                                </span>
                              </div>
                            </button>
                          )}
                        />
                        {document.getElementById("demo")}
                      </div>
                      <div className="col-sm-12 col-md-6 my-1">
                        <GoogleLogin
                          clientId="703391828421-rr4dbnethe0ciecqa05mv3jj2usvf9d3.apps.googleusercontent.com"
                          onRequest={() => setSigning(true)}
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              id="go-sign-btn"
                              className="btn btn-block bg-white"
                            >
                              <div className="d-flex justify-content-center align-items-baseline">
                                <img
                                  src="https://developers.google.com/identity/images/g-logo.png"
                                  height="20px"
                                  className="align-self-center"
                                />
                                <span className="text-s-primary font-s-medium font-w-s-light mx-1 btn-text align-self-center">
                                  {translator.google}
                                </span>
                              </div>
                            </button>
                          )}
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        />
                        {document.getElementById("googleButton")}
                      </div>
                    </div>
                    <div
                      className="my-4"
                      style={{
                        height: "60px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="bg-gray"
                        style={{
                          position: "absolute",
                          borderRadius: "50%",
                          padding: "5px",
                          height: "60px",
                          width: "60px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h2 className="m-1 text-s-secondary">
                          {translator.alt}
                        </h2>
                      </div>

                      <div className="w-100">
                        <hr className="border-top "></hr>
                      </div>
                    </div>
                    <div>
                      <h4 className="mb-2 text-danger font-weight-bolder">
                        {translator.signNormal}
                      </h4>
                      <div className="form justify-content-center my-3">
                        <div className="input-group my-2">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="fas fa-globe fa-lg text-s-secondary"
                                style={{ width: "40px" }}
                              ></i>
                            </span>
                          </div>
                          <select
                            className="selectpicker form-control"
                            title={translator.category}
                            data-live-search="true"
                            data-container="body"
                            data-size="10"
                            onChange={(event) =>
                              handleSignInputs(event, "code")
                            }
                          >
                            <InternationalCodes />
                          </select>
                        </div>
                        <div className="input-group my-2">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="fa fa-mobile fa-lg text-s-secondary"
                                style={{ width: "40px" }}
                              ></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control bg-white"
                            placeholder={translator.mobile}
                            value={mobile}
                            onChange={(event) =>
                              handleSignInputs(event, "mobile")
                            }
                          />
                        </div>
                        <div className="input-group my-2">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="fa fa-unlock-alt fa-lg text-s-secondary"
                                style={{ width: "40px" }}
                              ></i>
                            </span>
                          </div>
                          <input
                            type="password"
                            className="form-control bg-white"
                            placeholder={translator.password}
                            value={password}
                            onChange={(event) =>
                              handleSignInputs(event, "password")
                            }
                          />
                        </div>
                        <div className="my-3">
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={() => {
                              !signing && getLogin();
                            }}
                          >
                            <div className="d-flex">
                              <Fingerprint />
                              <span className="btn-text text-capitalize  mx-1">
                                {translator.signIn}
                              </span>
                            </div>
                          </Button>
                        </div>
                        <div className="row justify-content-between mt-3 mx-2 p-0">
                          <Link to="/">
                            <p className="text-danger font-s-text font-w-s-medium">
                              {translator.forget}
                            </p>
                          </Link>
                          <Link to="/sign-up">
                            <p className="text-s-secondary font-s-text font-w-s-medium">
                              {translator.register}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        {displayStatus && (
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
              <ErrorHandler status={responseStatus} message={responseMessage} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SignIn;
