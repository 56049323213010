import { apiEndpoint } from "./config";
import http from "./httpService";

const getHeaders = (contentType = "application/json") => {
  const accessToken = localStorage.getItem("accessToken");
  const authorization = accessToken
    ? { Authorization: `Bearer ${accessToken}` }
    : null;
  const headers = {
    headers: {
      Accept: "application/json",
      "Content-type": contentType,
      "accept-language": localStorage.getItem("language"),
      ...authorization,
    },
  };
  return headers;
};

export const getCategories = async () => {
  const url = apiEndpoint + "/PropertiesCategories";
  const { data, status } = await http.get(url, getHeaders());
  return { data, status };
};

export const getCategoryFeatures = async (categoryId) => {
  const url = apiEndpoint + "/PropertiesCategories/" + categoryId;
  const { data, status } = await http.get(url, getHeaders());
  return { data, status };
};

export const getSearchableCategories = async () => {
  const url = apiEndpoint + "/searchable-categories";
  const { data, status } = await http.get(url, getHeaders());
  return { data, status };
};

export const getAdvancedSearchableCategories = async () => {
  const url = apiEndpoint + "/advanced-searchable-categories";
  const { data, status } = await http.get(url, getHeaders());
  return { data, status };
};

export const getGovernorates = async () => {
  const url = apiEndpoint + "/web/GovernoratesAndDistricts";
  const { data, status } = await http.get(url, getHeaders());
  return { data, status };
};

export const getSearchResults = async (searchParams, paginate) => {
  const url = apiEndpoint + "/search-properties-ads" + paginate;
  const { data, status } = await http.get(url, {
    headers: getHeaders().headers,
    params: searchParams,
  });
  return { data, status };
};

export const firebaseCodeVerification = async (
  confirmationResponse,
  verificationCode
) => {
  const result = await confirmationResponse.confirm(verificationCode);
  const user = result.user;
  const idToken = await user.getIdToken();
  const url = apiEndpoint + "/web/verifyByToken";
  const accessToken = localStorage.getItem("accessToken");
  const token = `Bearer ${accessToken}`;
  const headers = {
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
  };
  const requestData = { token: idToken };
  const { status } = await http.post(url, requestData, headers);
  return status;
};

export const getFirebaseVerificatiosStatus = () => {};

export const register = async (
  countryCode,
  mobile,
  password,
  firstName,
  lastName,
  userType
) => {
  const apiUrl = `${apiEndpoint}/web/users`;
  const requestData = {
    country_id: countryCode,
    mobile,
    password,
    first_name: firstName,
    last_name: lastName,
    user_Type: userType,
  };
  const { data, status } = await http.post(apiUrl, requestData, getHeaders());
  let profileData = {};
  if (status === 200) {
    profileData = prepareProfileData(data.user, data.user.profile);
    localStorage.setItem("accessToken", data.token);
  }
  return { data, status, profileData };
};

export const login = async (internationalCode, mobile, password) => {
  const apiUrl = `${apiEndpoint}/login`;
  const requestData = {
    country_id: internationalCode,
    identifier: mobile,
    password,
  };
  const { data, status } = await http.post(apiUrl, requestData, getHeaders());
  let profileData = {};
  if (status === 200) {
    profileData = prepareProfileData(data.user, data.user.profile);
    localStorage.setItem("accessToken", data.token);
  }
  return { data, status, profileData };
};

export const socialLogin = async (type, id, token) => {
  const apiUrl = apiEndpoint + "/socialLogin";
  const requestData = { type, id, token };
  const { data, status } = await http.post(apiUrl, requestData, getHeaders());
  let profileData = {};
  if (status === 200) {
    profileData = prepareProfileData(data.user, data.user.profile);
    localStorage.setItem("accessToken", data.token);
  }
  return { data, status, profileData };
};

export const loadProfile = async () => {
  const apiUrl = `${apiEndpoint}/web/users`;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const updateProfile = async (profile) => {
  const apiUrl = `${apiEndpoint}/web/users/update`;
  const requestData = profile;
  const { data, status } = await http.post(
    apiUrl,
    requestData,
    getHeaders("multipart/form-data")
  );
  let profileData = {};
  if (status === 200) {
    profileData = prepareProfileData(data.user, data.user.profile);
  }
  return { data, status, profileData };
};

export const prepareProfileData = (user, profile) => {
  const profileData = {
    id: user.id,
    userTypeId: user.userTypeId,
    firstName: profile.firstName,
    lastName: profile.lastName,
    code: user.code,
    phone: user.phone ? user.phone : null,
    mobile: user.mobile,
    email: user.email,
    profileImgUrl: profile.profileImgUrl,
    isVerified: user.isVerified && true,
    isProfileCompleted: user.is_profile_completed,
    activeAgent: user.is_active_agent,
    language: user.lang,
    contacts: user.contacts,
    isLogged: true,
  };
  return profileData;
};

export const logout = async () => {
  const apiUrl = `${apiEndpoint}/logout`;
  const { status } = await http.post(apiUrl, {}, getHeaders());
  localStorage.removeItem("accessToken");
  return { status };
};

export const getInternationalCodes = async () => {
  const apiUrl = apiEndpoint + "/web/countries-codes";
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const getPropertyAd = async (adId) => {
  const isUser = localStorage.getItem("accessToken") !== null ? "/web" : "";
  const apiUrl = apiEndpoint + isUser + "/PropertiesAds/" + adId;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const toggleFavorite = async (isFavorited, adId) => {
  const apiUrl = apiEndpoint + "/web/toggleFavorite";
  const requestData = { ad_id: adId };
  const { data, status } = await http.post(apiUrl, requestData, getHeaders());
  return { data, status };
};

export const getMyProperties = async (paginate) => {
  const apiUrl = apiEndpoint + "/web/my-properties" + paginate;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const createAd = async (requestData) => {
  const apiUrl = apiEndpoint + "/web/PropertiesAds";
  const { data, status } = await http.post(
    apiUrl,
    requestData,
    getHeaders("multipart/form-data")
  );
  return { data, status };
};

export const getContactsMethods = async () => {
  const apiUrl = apiEndpoint + "/web/contactsMethods";
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const addNewContacts = async (requestData) => {
  const apiUrl = apiEndpoint + "/web/contacts";
  const { data, status } = await http.post(apiUrl, requestData, getHeaders());
  return { data, status };
};

export const getUserContacts = async () => {
  const apiUrl = apiEndpoint + "/web/contacts";
};

export const deleteUserContacts = async (contactId) => {
  const apiUrl = apiEndpoint + "/web/contacts/" + contactId;
  const { data, status } = await http.delete(apiUrl, getHeaders());
  return { data, status };
};

export const getUserFavorites = async (paginate) => {
  const apiUrl = apiEndpoint + "/web/getAllFavorites" + paginate;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const authChannelSubscription = async (socketId, channel) => {
  const apiUrl = apiEndpoint + "/broadcasting/auth";
  const { data, status } = await http.post(
    apiUrl,
    {
      socket_id: socketId,
      channel_name: channel.name,
    },
    getHeaders()
  );
  return { data, status };
};

export const getArticlesTypes = async () => {
  const apiUrl = apiEndpoint + "/get-articles-types";
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const getArticles = async () => {
  const apiUrl = apiEndpoint + "/get-articles";
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const getArticle = async (articleId) => {
  const apiUrl = apiEndpoint + "/get-article/" + articleId;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const getArticlesByType = async (typeId) => {
  const apiUrl = apiEndpoint + "/get-articles-by-type/" + typeId;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const getLastAddedAds = async () => {
  const apiUrl = apiEndpoint + "/web/get-last-added-ads";
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const getLastAgents = async () => {
  const apiUrl = apiEndpoint + "/get-last-agents";
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const getAllAgents = async (paginate) => {
  const apiUrl = apiEndpoint + "/get-all-agents" + paginate;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};

export const showAgent = async (agentId, paginate) => {
  const apiUrl = apiEndpoint + "/show-agent/" + agentId + paginate;
  const { data, status } = await http.get(apiUrl, getHeaders());
  return { data, status };
};
