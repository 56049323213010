import React, {useContext} from 'react';
import TranslationContext from "../../../contexts/translation-context";

const PriceRange = ({adPurpose}) => {
    const translator = useContext(TranslationContext).findBox;
    return (
      <React.Fragment>
        {adPurpose === "rent" && (
          <React.Fragment>
            <option value="1">{translator.rent_L_1k}</option>
            <option value="2">{translator.rent_B_1k_2k}</option>
            <option value="3">{translator.rent_B_2k_3k}</option>
            <option value="4">{translator.rent_B_3k_5k}</option>
            <option value="5">{translator.rent_B_5k_10k}</option>
            <option value="6">{translator.rent_B_10k_20k}</option>
            <option value="7">{translator.rent_B_20k_50k}</option>
            <option value="8">{translator.rent_M_50k}</option>
          </React.Fragment>
        )}
        {adPurpose === "sale" && (
          <React.Fragment>
            <option value="1">{translator.sale_L_100k}</option>
            <option value="2">{translator.sale_B_100k_200k}</option>
            <option value="3">{translator.sale_B_200k_300k}</option>
            <option value="4">{translator.sale_B_300k_500k}</option>
            <option value="5">{translator.sale_B_500k_1m}</option>
            <option value="6">{translator.sale_B_1m_2m}</option>
            <option value="7">{translator.sale_B_2m_3m}</option>
            <option value="8">{translator.sale_B_3m_5m}</option>
            <option value="9">{translator.sale_B_5m_10m}</option>
            <option value="10">{translator.sale_M_10m}</option>
          </React.Fragment>
        )}
      </React.Fragment>
    );
}
 
export default PriceRange;