import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TranslationContext from "../../contexts/translation-context";
import { Tooltip } from "@material-ui/core";

const AdsColumnsView = ({ propertiesAds, handleToggleFavorite }) => {
  const translator = useContext(TranslationContext).ads;

  return (
    <React.Fragment>
      {propertiesAds.length > 0 ? (
        <div className="row">
          {propertiesAds.map((propertyAd) => (
            <div className="col-md-6 col-12">
              <div className="flat-item">
                <div className="flat-item-image">
                  <span
                    className={`for-sale bg-s-${
                      propertyAd.ad_purpose === 0 ? "candy" : "deepWarning"
                    } text-white font-s-text font-s-regular font-w-s-medium text-nowrap p-1`}
                  >
                    {propertyAd.ad_purpose === 0
                      ? translator.sale
                      : translator.rent}
                  </span>
                  <Link
                    to={`/show-property-ad/${propertyAd.id}`}
                    style={{
                      height: "250px",
                    }}
                  >
                    <img src={propertyAd.default_image} className="h-100" alt="" />
                    {
                    <div
                      className="column-ad-image-fav p-2 d-flex"
                      style={{
                        zIndex: "2",
                        borderRadius: "50%",
                        backgroundColor: "rgba(0,0,0,0.7)",
                      }}
                    >
                        <Tooltip title={translator.is_favorited} aria-label="add">
                          <i
                            className={`${
                              propertyAd.is_favorited ? "fas" : "far"
                            } fa-heart text-s-candy font-s-medium pointer`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleToggleFavorite(
                                propertyAd.is_favorited,
                                propertyAd.id
                              );
                            }}
                          ></i>
                        </Tooltip>
                    </div>
                      }
                  </Link>
                  <div className="flat-link">
                    <Link to={`/show-property-ad/${propertyAd.id}`}>
                      <span className="font-s-text font-s-regular font-w-s-light ">
                        {translator.details}
                      </span>
                    </Link>
                  </div>
                  <ul className="flat-desc py-1">
                    <li>
                      <i class="fas fa-ruler-combined text-s-secondary font-s-medium"></i>
                      <span className="text-white font-s-regular mx-2">
                        {`${propertyAd.area} ${translator.meter}`}
                      </span>
                    </li>
                    {
                      propertyAd.presentable_features.length > 0 && (
                        <li>
                      <i
                        className={`${propertyAd.presentable_features[0].icon} text-s-secondary font-s-medium`}
                      ></i>
                      <span className="text-white font-s-regular mx-2">
                        {propertyAd.presentable_features[0].value}
                      </span>
                    </li>
                      )
                    }
                    {
                      propertyAd.presentable_features.length > 1 && (
                        <li>
                      <i
                        className={`${propertyAd.presentable_features[1].icon} text-s-secondary font-s-medium`}
                      ></i>
                      <span className="text-white font-s-regular mx-2">
                        {propertyAd.presentable_features[1].value}
                      </span>
                    </li>
                      )
                    }
                  </ul>
                </div>
                <div className="flat-item-info py-1">
                  <div className="d-flex justify-content-center flat-title-price bg-s-secondary  py-1 px-2">
                    <span className="text-white font-s-regular font-s-text font-w-s-medium">
                      {propertyAd.category.name}
                    </span>
                    <span className="text-white font-s-medium font-s-text font-w-s-medium mx-2">
                      |
                    </span>
                    <span className="text-light font-s-regular font-s-text font-w-s-medium">
                      {propertyAd.district.name}
                    </span>
                  </div>

                  <div className="d-flex justify-content-center flat-title-price bg-s-primary  py-1 px-2">
                    <span className="text-white font-s-medium font-s-text font-w-s-medium">{`${propertyAd.price} ${translator.egp}`}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center w-100">
          <h3 className="text-s-deepWarning font-s-title">
            {translator.noResults}
          </h3>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdsColumnsView;
