import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TranslationContext from "../../../contexts/translation-context";

const FinishAd = ({ adId }) => {
  const translator = useContext(TranslationContext).ads;
  return (
    <React.Fragment>
      <section id="page-content" className="page-wrapper">
        <div
          style={{
            background: 'url("assets/images/customizer/pattern/3.png")',
          }}
          className="d-inline-block w-100"
        >
          <div className="container-fluid  pt-115 pb-60">
            <div className="card text-center py-5  px-5 card-limit-size">
              <div className="my-5">
                <i className="fas fa-check-circle text-s-secondary fa-5x mb-3"></i>
                <h3 className="font-s-title text-s-primary font-w-s-fat">
                  {translator.adSuccess}
                </h3>
              </div>
              <div className="bg-s-primary px-5 py-3 rounded">
                <ul>
                  <li
                    className="my-4"
                    style={{ listStyleType: "disc", textAlign: "start" }}
                  >
                    <p className="text-white font-s-text font-s-medium font-w-s-ultra-thin">
                      {translator.adReviewP}
                    </p>
                  </li>
                  <li
                    className="my-4"
                    style={{ listStyleType: "disc", textAlign: "start" }}
                  >
                    <p className="text-white font-s-text font-s-medium font-w-s-ultra-thin">
                      {translator.publishNotifyP}
                      <Link to="/">
                        <span className="text-s-secondary">
                          {" "}
                          {translator.modifyProfile}
                        </span>
                      </Link>
                    </p>
                  </li>
                  <li
                    className="my-4"
                    style={{ listStyleType: "disc", textAlign: "start" }}
                  >
                    <p className="text-white font-s-text font-s-medium font-w-s-ultra-thin">
                      {translator.watchAdP}
                      <Link to={`/show-property-ad/${adId}`}>
                        <span className="text-s-secondary">
                          {" "}
                          {translator.wathcAd}
                        </span>
                      </Link>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default FinishAd;
