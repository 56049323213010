import React from "react";

const PaginatorElement = ({
  pageNumber,
  currentDisplayedPage,
  setPaginate,
}) => {
  return (
    <li className={`page-item `}>
      <a
        className={`page-link font-s-medium font-s-text  ${
          pageNumber === currentDisplayedPage
            ? "bg-s-primary text-white"
            : "text-muted"
        }`}
        onClick={() => setPaginate("?page=" + pageNumber)}
        
      >
        {pageNumber}
      </a>
    </li>
  );
};

const PaginationBar = ({
  pagesCount,
  currentPage,
  firstElement,
  lastElement,
  total,
  direction,
  translator,
  setPaginate,
}) => {
  const oppositeDirection = direction === "right" ? "left" : "right";
  const firstPage = currentPage - 2 <= 1 ? 1 : currentPage - 2;
  const lastPage = firstPage + 4 > pagesCount ? pagesCount : firstPage + 4;
  const paginatorGroupElements = [];
  for (let i = firstPage; i <= lastPage; i++) {
    paginatorGroupElements.push(
      <PaginatorElement
        key={i}
        pageNumber={i}
        currentDisplayedPage={currentPage}
        setPaginate={setPaginate}
      />
    );
  }
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-wrap py-2 mr-3">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${currentPage == 1 && "disabled"} `}>
                <a
                  className="page-link font-s-medium text-muted"
                  aria-label="Previous"
                  onClick={() => setPaginate("?page=1")}
                >
                  <span aria-hidden="true">
                    <i className={`fas fa-angle-double-${direction}`}></i>
                  </span>
                </a>
              </li>
              <li className={`page-item ${currentPage == 1 && "disabled"} `}>
                <a className="page-link font-s-medium text-muted" onClick={() => setPaginate("?page=" + (currentPage - 1))}>
                  <span aria-hidden="true">
                    <i className={`fas fa-angle-${direction}`}></i>
                  </span>
                </a>
              </li>
              {[...paginatorGroupElements]}
              <li
                className={`page-item  ${
                  currentPage == pagesCount && "disabled"
                } `}
              >
                <a className="page-link font-s-medium text-muted" onClick={() => setPaginate("?page=" + (currentPage + 1))}>
                  <span aria-hidden="true">
                    <i className={`fas fa-angle-${oppositeDirection}`}></i>
                  </span>
                </a>
              </li>
              <li
                className={`page-item  ${
                  currentPage == pagesCount && "disabled"
                } `}
              >
                <a
                  className="page-link font-s-medium text-muted"
                  onClick={() => setPaginate("?page=" + pagesCount)}
                >
                  <span aria-hidden="true">
                    <i
                      className={`fas fa-angle-double-${oppositeDirection}`}
                    ></i>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="d-flex align-items-center py-3">
          <span className="text-muted font-s-text">
            {` ${translator.displayItems} ${firstElement} : ${lastElement} ${translator.fromTotal} ${total}`}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaginationBar;
