import React, { useState, useContext, useRef, useEffect } from "react";
import { useLoadStyle } from "../../../hooks/load-style";
import { loadScript } from "../../../hooks/load-script";
import TranslationContext from "../../../contexts/translation-context";
import AdsCreationContext from "../../../contexts/not-public/ads-creation-context";
import { colorsPalette, resources } from "../../../services/config";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import StepperButtonsBar from "./stepper-buttons-bar";

const BasicInfo = ({
  handleBack,
  handleNext,
  handleReset,
  activeStep,
  steps,
  setAllFormData,
}) => {
  const translator = useContext(TranslationContext).ads;
  const { categories, governorates, callGetCategoryFeatures } = useContext(
    AdsCreationContext
  );
  useLoadStyle(resources.selectpickerStyle);

  const [adPurpose, setAdPurpose] = useState("0");
  const [propertyCategory, setPropertyCategory] = useState("");
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyType, setPropertyType] = useState(null);
  const [governorate, setGovernorate] = useState();
  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState(null);
  const latPositionRef = useRef();
  const longPositionRef = useRef();
  const [map, setMap] = useState(false);
  const [locationName, setLocationName] = useState();
  const [negotiable, setNegotiable] = useState(0);
  const [price, setPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("3");
  const [area, setArea] = useState(0);
  let basicInfoForm = {
    ad_purpose: adPurpose,
    category_id: propertyType,
    district_id: district,
    lat: 0,
    lng: 0,
    price,
    is_negotiable: negotiable,
    payment_method: paymentMethod,
    area,
  };
  const [validationActive, setValidationActive] = useState(false);
  let validations = {
    propertyTypeValid: propertyType !== null,
    districtValid: district !== null,
    priceValid: price > 0,
    areaValid: area > 0,
    mapValid: map,
  };
  useEffect(() => {
    setTimeout(() => {
      loadScript(resources.mapPollyfill, false);
      loadScript(resources.mapPlaces, false);
      loadScript(resources.mapLauncher, true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (propertyCategory) {
      getPropertyTypes(propertyCategory);
    }
  }, [propertyCategory]);

  useEffect(() => {
    adPurpose === "1" ? setPaymentMethod("1") : setPaymentMethod("3");
  }, [adPurpose]);

  const getPropertyTypes = (propertyCategoryId) => {
    const propertyCategory = categories.find(
      (category) => category.uuid === propertyCategoryId
    );
    setPropertyTypes(propertyCategory.children);
  };

  useEffect(() => {
    propertyTypes.length > 0 && window.refreshAllSelectPickers();
  }, [propertyTypes]);

  useEffect(() => {
    districts.length > 0 && window.refreshAllSelectPickers();
  }, [districts]);

  useEffect(() => {
    governorate && getDistricts(governorate);
  }, [governorate]);

  const getDistricts = (governorateId) => {
    const governorateDistricts = governorates.find(
      (governorate) => governorate.id == governorateId
    );
    setDistricts(governorateDistricts.districts);
  };

  const handleInput = (event, inputName) => {
    const value = event.target.value;
    if (inputName === "adPurpose") {
      setAdPurpose(value);
      basicInfoForm.adPurpose = adPurpose;
    } else if (inputName === "propertyCategory") {
      setPropertyCategory(value);
    } else if (inputName === "propertyType") {
      setPropertyType(value);
    } else if (inputName === "governortes") {
      setGovernorate(value);
    } else if (inputName === "district") {
      setDistrict(value);
    } else if (inputName === "paymentMethod") {
      setPaymentMethod(value);
    } else if (inputName === "price") {
      setPrice(value);
    } else if (inputName === "area") {
      setArea(value);
    }
  };
  const submitForm = () => {
    latPositionRef.current.value !== "" && setMap(true);
    basicInfoForm.lat = latPositionRef.current.value;
    basicInfoForm.lng = longPositionRef.current.value;
    let checkValidationsResult = checkValidations();
    if (checkValidationsResult) {
      setAllFormData("basic", basicInfoForm);
      callGetCategoryFeatures(propertyType);
      handleNext();
    }
  };

  const checkValidations = () => {
    const validationsStatus =
      validations.propertyTypeValid &&
      validations.districtValid &&
      validations.priceValid &&
      validations.areaValid;
    validationsStatus ? setValidationActive(false) : setValidationActive(true);
    return validationsStatus;
  };

  return (
    <React.Fragment>
      <div className="my-4">
        <RadioGroup
          row
          aria-label="position"
          value={adPurpose}
          onChange={(event) => handleInput(event, "adPurpose")}
        >
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label={translator.sale}
            labelPlacement="start"
            style={{ marginRight: "0px" }}
          />
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label={translator.rent}
            labelPlacement="start"
            style={{ marginRight: "0px" }}
          />
        </RadioGroup>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6 my-4">
          <select
            className="selectpicker form-control"
            title={translator.category}
            data-hide-disabled="true"
            data-live-search="true"
            style={{ backgroundColor: colorsPalette.skyBlue + "!important" }}
            onChange={(event) => handleInput(event, "propertyCategory")}
          >
            {categories.map((category) => (
              <option key={category.uuid} value={category.uuid}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 col-md-6 my-4">
          <select
            id="property-type"
            className="selectpicker form-control"
            title={translator.type}
            data-hide-disabled="true"
            data-live-search="true"
            onChange={(event) => handleInput(event, "propertyType")}
          >
            {propertyTypes.map((type) => (
              <option key={type.uuid} value={type.uuid}>
                {type.name}
              </option>
            ))}
          </select>
          {validationActive && !validations.propertyTypeValid && (
            <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
              <i className="fas fa-exclamation-circle"></i>{" "}
              {translator.required}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 my-4">
          <select
            className="selectpicker form-control"
            title={translator.governorate}
            data-hide-disabled="true"
            data-live-search="true"
            onChange={(event) => handleInput(event, "governortes")}
          >
            {governorates.map((governate) => (
              <option key={governate.id} value={governate.id}>
                {governate.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-sm-12 col-md-6 my-4">
          <select
            id="districts"
            className="selectpicker form-control"
            title={translator.district}
            data-hide-disabled="true"
            data-live-search="true"
            onChange={(event) => {
              handleInput(event, "district");
              let districtName =
                event.target.options[event.target.selectedIndex].dataset
                  .district;
              setLocationName(districtName);
            }}
          >
            {districts.map((district) => (
              <option
                key={district.id}
                value={district.id}
                data-district={district.name}
              >
                {district.name}
              </option>
            ))}
          </select>
          {validationActive && !validations.districtValid && (
            <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
              <i className="fas fa-exclamation-circle"></i>{" "}
              {translator.required}
            </span>
          )}
        </div>
      </div>
      <div className="p-2 rounded bg-s-primary  my-4">
        <input type="hidden" id="position-lat" ref={latPositionRef} />
        <input type="hidden" id="position-long" ref={longPositionRef} />
        <input
          id="pac-input"
          className="pac-controls"
          type="text"
          value={locationName}
          placeholder="Search Box"
        />
        <div id="map" style={{ height: "400px" }}></div>
        {validationActive && !validations.mapValid && (
          <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
            <i className="fas fa-exclamation-circle"></i>{" "}
            {translator.mapRequired}
          </span>
        )}
      </div>
      <div className="d-flex my-4">
        <div className="form-group" style={{ textAlign: "start" }}>
          <label>{translator.price}</label>
          <input
            type="number"
            className="form-control"
            min="0"
            value={price}
            onChange={(event) => handleInput(event, "price")}
          />
          {validationActive && !validations.priceValid && (
            <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
              <i className="fas fa-exclamation-circle"></i>{" "}
              {translator.required}
            </span>
          )}
        </div>
        <div className="form-group align-contents-start">
          <label>{translator.negotiable}</label>
          <Checkbox
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            className="form-control"
            style={{ padding: "0" }}
            onClick={() =>
              negotiable === 0 ? setNegotiable(1) : setNegotiable(0)
            }
          />
        </div>
      </div>
      <div className="form-group my-4" style={{ textAlign: "start" }}>
        <label>{translator.payment}</label>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          value={paymentMethod}
          onChange={(event) => handleInput(event, "paymentMethod")}
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label={translator.cash}
            labelPlacement="start"
            style={{ marginRight: "0px" }}
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label={translator.premiums}
            labelPlacement="start"
            style={{ marginRight: "0px" }}
            disabled={adPurpose === "1"}
          />
          <FormControlLabel
            value="3"
            control={<Radio color="primary" />}
            label={translator.both}
            labelPlacement="start"
            style={{ marginRight: "0px" }}
            disabled={adPurpose === "1"}
          />
        </RadioGroup>
      </div>
      <div className="d-flex my-4">
        <div className="form-group" style={{ textAlign: "start" }}>
          <label>{translator.area}</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              min="0"
              value={area}
              onChange={(event) => handleInput(event, "area")}
            />
            <div className="input-group-append">
              <span className="input-group-text">{translator.meter}</span>
            </div>
          </div>
          {validationActive && !validations.areaValid && (
            <span className="form-text text-s-start text-s-candy font-s-text font-s-xsmall font-w-s-regular">
              <i className="fas fa-exclamation-circle"></i>{" "}
              {translator.required}
            </span>
          )}
        </div>
      </div>
      <StepperButtonsBar
        handleBack={handleBack}
        activeStep={activeStep}
        handleReset={handleReset}
        steps={steps}
        submitForm={submitForm}
      />
    </React.Fragment>
  );
};

export default BasicInfo;
