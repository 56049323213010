const platformAr = 'مو للتداول العقارى';
const platformEn = 'Mo Real Estate';
export const header = {
  ar: {
    sign: "دخول الموقع",
    signIn: "تسجيل الدخول",
    signUp: "تسجيل حساب",
    home: "الرئيسية",
    showLang: "English",
    qahraman: platformAr,
    agents: "الوكلاء",
    partners: "الشركاء",
    developers: "المطورون",
    offers: "العروض",
    articles: "مقالات عقارية",
    welcome: "أهلاً",
    myAccount: "حسابى",
    createAd: "أعلن عن عقار",
    myAds: "إعلاناتى",
    myNotifications: "الإشعارات",
    logout: "الخروج",
    phoneActivation: "تفعيل الحساب",
    welcomeTo: "مرحباً بك في "+platformAr,
    completeProfile: "إستكمال البيانات",
    addMobile: "إضافة رقم الهاتف",
    searchProperty: "إبحث عن عقار",
    agentRegistration: "تسجيل الوكلاء",
    agentsView: "عرض الوكلاء",
  },
  en: {
    sign: "website sign",
    signIn: "Sign in",
    signUp: "Sign up",
    home: "home",
    showLang: "العربية",
    agents: "Agents",
    qahraman: platformEn,
    partners: "Partners",
    developers: "Developers",
    offers: "Deals",
    articles: "Properties Articles",
    welcome: "hello",
    myAccount: "my account",
    createAd: "place an ad",
    myAds: "my advertisments",
    myNotifications: "notifications",
    logout: "Sign out",
    phoneActivation: "account activation",
    welcomeTo: "Welcome to "+platformEn,
    completeProfile: "Profile completion",
    addMobile: "add mobile no",
    searchProperty: "Search For Property",
    agentRegistration: "agents registration",
    agentsView: "agents preview",
  },
};
