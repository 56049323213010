import React, { useContext } from "react";
import TranslationContext from "../../contexts/translation-context";
import {capitalizeFirstChar} from '../../helpers/helpers-methods';

const Features = () => {
  const translator = useContext(TranslationContext).home;
  return (
    <React.Fragment>
      <div style={{ height: "fit-content" }} className="my-100">
        <div className="features-area fix">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 offset-lg-5">
                <div className="features-info bg-gray">
                  <div className="section-title mb-10">
                    <h3 className="font-s-title text-s-secondary text-uppercase">{translator.why}</h3>
                    <h2 className="h1 font-s-title text-s-primary text-capitalize">
                      {translator.platformName}
                    </h2>
                  </div>
                  <div className="features-desc">
                    <span className="font-s-text font-s-xsmall text-muted">
                      {capitalizeFirstChar(translator.appDescText)}
                    </span>
                  </div>
                  <div className="features-include">
                    <div className="row">
                      <div className="col-xl-4 col-lg-6 col-md-4">
                        <div className="features-include-list d-flex flex-column">
                        <div>
                            <i className="fas fa-handshake text-s-secondary font-s-medium"></i>
                            <span className="font-s-title font-s-medium font-w-s-medium text-s-primary mx-2 text-capitalize">
                            {translator.credibility}
                          </span>
                          </div>
                          <span className="font-s-text text-muted font-s-small font-w-s-light">
                            {capitalizeFirstChar(translator.credibilityText)}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-4">
                        <div className="features-include-list d-flex flex-column">
                          <div>
                            <i className="fas fa-shield-alt text-s-secondary font-s-medium"></i>
                            <span className="font-s-title font-s-medium font-w-s-medium text-s-primary mx-2 text-capitalize">
                              {translator.safety}
                            </span>
                          </div>
                          <span className="font-s-text text-muted font-s-small font-w-s-light">
                          {capitalizeFirstChar(translator.safetyText)}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-4">
                        <div className="features-include-list d-flex flex-column">
                          <div>
                            <i className="far fa-lightbulb text-s-secondary font-s-medium"></i>
                            <span className="font-s-title font-s-medium font-w-s-medium text-s-primary mx-2 text-capitalize">
                              {translator.transparency}
                            </span>
                          </div>
                          <span className="font-s-text text-muted font-s-small font-w-s-light">
                            {capitalizeFirstChar(translator.transparencyText)}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-4">
                        <div className="features-include-list d-flex flex-column">
                        <div>
                            <i className="fas fa-glasses text-s-secondary font-s-medium"></i>
                            <span className="font-s-title font-s-medium font-w-s-medium text-s-primary mx-2 text-capitalize">
                            {translator.monitoring}
                          </span>
                          </div>
                          <span className="font-s-text text-muted font-s-small font-w-s-light">
                          {capitalizeFirstChar(translator.monitoringText)}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-4">
                        <div className="features-include-list d-flex flex-column">
                        <div>
                            <i className="fas fa-book-reader text-s-secondary font-s-medium"></i>
                            <span className="font-s-title font-s-medium font-w-s-medium text-s-primary mx-2 text-capitalize">
                            {translator.awareness}
                          </span>
                          </div>
                          <span className="font-s-text text-muted font-s-small font-w-s-light">
                          {capitalizeFirstChar(translator.awarenessText)}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-4">
                        <div className="features-include-list d-flex flex-column">
                        <div>
                            <i className="far fa-map text-s-secondary font-s-medium"></i>
                            <span className="font-s-title font-s-medium font-w-s-medium text-s-primary mx-2 text-capitalize">
                            {translator.coverage}
                          </span>
                          </div>
                          <span className="font-s-text text-muted font-s-small font-w-s-light">
                          {capitalizeFirstChar(translator.coverageText)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Features;
