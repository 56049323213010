import { Fragment, useContext, useEffect, useState } from "react";
import TranslationContext from "../../contexts/translation-context";
import MasterDataContext from "../../contexts/master-data-context";
import { Link } from "react-router-dom";

const Articles = ({ screenWidth }) => {
  const translator = useContext(TranslationContext).articles;
  const { masterData } = useContext(MasterDataContext);
  const { articles } = masterData;
  const [groupElementsNo, setGroupElementsNo] = useState(0);
  const [articlesGroups, setarticlesGroups] = useState([]);

  useEffect(() => setGroupsElementsNumber(), [screenWidth]);

  useEffect(() => showActiveArticles(), [groupElementsNo]);

  const setGroupsElementsNumber = () => {
    if (screenWidth >= 992) setGroupElementsNo(3);
    else if (screenWidth < 768) setGroupElementsNo(1);
    else setGroupElementsNo(2);
  };

  const showActiveArticles = () => {
    const articlesCount = Array.isArray(articles) ? articles.length : 0;
    const modulusRest = articlesCount % groupElementsNo;
    //const totalGroups = (modulusRest === 0 ? 0 : 1) + (articlesCount - modulusRest) / groupElementsNo; //use this when need to add rest ads to last group
    const totalGroups = (articlesCount - modulusRest) / groupElementsNo;
    const articlesGroupsShadow = [];
    var subGroup = [];
    for (var i = 1; i <= totalGroups; i++) {
      for (
        var n = i * groupElementsNo - groupElementsNo;
        n < i * groupElementsNo;
        n++
      ) {
        n < articlesCount && subGroup.push(n);
      }
      articlesGroupsShadow.push(subGroup);
      subGroup = [];
    }
    setarticlesGroups([...articlesGroupsShadow]);
  };

  return (
    <Fragment>
      <div className="featured-flat-area pb-30">
        <div
          className={`${screenWidth >= 768 ? "container" : "container-fluid"}`}
        >
          <div className="row">
            <div className="col-12">
              <div className="section-title-2 text-center">
                <div className="d-flex justify-content-center align-items-baseline mb-10">
                  <i
                    className={`fas fa-book-reader text-s-secondary ${
                      screenWidth >= 768 ? "display-4" : "font-s-xlarge"
                    }`}
                  ></i>
                  <span
                    className={`text-s-primary font-s-title ${
                      screenWidth >= 768 ? "display-4" : "font-s-xlarge"
                    } mx-2`}
                  >
                    {translator.articlesDept}
                  </span>
                </div>
                <p
                  className={`font-s-text ${
                    screenWidth >= 768 ? "font-s-regular" : "font-s-xsmall"
                  } font-w-s-light`}
                >
                  {translator.articlesText}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-ride="carousel"
              style={{ maxWidth: `${screenWidth >= 768 ? "100%" : "450px"}` }}
            >
              <div className="carousel-inner">
                {articlesGroups.map((group, index) => (
                  <div
                    key={`carousel-item-${index + 1}`}
                    className={`carousel-item ${index === 0 && "active"} `}
                  >
                    <div className="row">
                      {typeof articles !== "undefined" &&
                        articles.length > 0 &&
                        group.map((article) => {
                          const articleTypeName =
                            articles[article].article_type_name;
                          const articleText = articles[
                            article
                          ].article_text.replace(/<[^>]+>/g, "");
                          return (
                            <div
                              key={articles[article].id}
                              style={{ height: "520px" }}
                              className="col-lg-4 col-md-6 col-12  "
                            >
                              <div className="bg-s-primary d-flex flex-column justify-content-center">
                                <Link
                                  to={`/show-article/${articles[article].id}`}
                                >
                                  <div
                                    style={{
                                      height: "240px",
                                      backgroundImage: `url(${articles[article].article_image})`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                    className="bg-s-primary d-flex align-items-center "
                                  ></div>
                                </Link>

                                <div
                                  style={{ height: "60px" }}
                                  className="bg-s-cream d-flex flex-column justify-content-center"
                                >
                                  <div
                                    style={{ height: "50px" }}
                                    className="d-flex flex-column justify-content-center align-items-center px-2"
                                  >
                                    <span className="text-s-primary font-s-title font-s-regular overflow-auto">
                                      {articles[article].title}
                                    </span>
                                  </div>
                                </div>
                                <Link
                                  to={`/show-articles/${articles[article].article_type}`}
                                >
                                  <div
                                    style={{ height: "40px" }}
                                    className="mx-2 d-flex  align-items-center"
                                  >
                                    <div>
                                      <span className="text-s-secondary font-s-small font-s-regular">
                                        #{articleTypeName.replace(" ", "_")}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                                <div
                                  style={{ height: "130px" }}
                                  className="px-2 py-2  overflow-hidden"
                                >
                                  <span className="text-white font-s-text font-s-small font-w-s-light">
                                    {articleText.substr(1, 220)}{" "}
                                    <span className="text-s-secondary font-s-xlarge">
                                      ...
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{ height: "50px" }}
                                  className="d-flex align-items-end mx-2  pb-2"
                                >
                                  <Link
                                    to={`/show-article/${articles[article].id}`}
                                  >
                                    <span className="text-s-deepWarning font-s-text text-s-small">
                                      {translator.readMore}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Articles;
