export const errors = {
  ar: {
    success: "تم تنفيذ طلبك بنجاح",
    badRequest: "خطأ في الطلب. لا يمكن تنفيذ طلبك",
    unauthorized: "فشل في عملية التحقق. بيانات التحقق من هويتك غير صحيحة",
    forbidden: "غير مصرح لحسابك باستخدام هذا المصدر",
    notFound: "الصفحة التي تحاول الوصول إليها غير موجودة",
    server: "خطأ في الإتصال بالخادم. برجاء إعادة المحاولة لاحقاً",
    unExpected: "خطأ غير معروف. برجاء التواصل مع المنصة في حالة تكرار الخطأ",
  },
  en: {
    success: "Your request has submitted successfully.",
    badRequest: "Bad rquest, Your request can not be submitted",
    unauthorized: "Login process failed, The credentials are incorrect.",
    forbidden: "Your account has not autorized to use this feature.",
    notFound: "The page you have tried to reach not exist.",
    server: "Connection with server has failed, try again later.",
    unExpected:
      "Unexpected error. please contact our platform team if repeated.",
  },
};
