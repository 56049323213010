import React, { Fragment, useContext } from "react";
import TranslationContext from "../../../contexts/translation-context";
import { NavLink } from "react-router-dom";

const SideBarItems = (props) => {
  const translator = useContext(TranslationContext).sign;
  return (
    <Fragment>
      <tbody className="bg-white text-s-start font-s-text mx-auto">
        <tr>
          <th className="px-0">
            <NavLink
              to="/my-profile"
              className={`text-s-${
                props.routeInfo.location.pathname === "/my-profile"
                  ? "secondary"
                  : "primary"
              } `}
            >
              <div className="d-flex px-2 pointer">
                <i className="fas fa-user-cog fa-fw font-s-medium"></i>
                <span className="font-s-regular font-w-s-medium font-s-text mx-2">
                  {translator.myData}
                </span>
              </div>
            </NavLink>
          </th>
        </tr>
        <tr>
          <th className="px-0">
            <NavLink
              to="/my-properties-ads"
              className={`text-s-${
                props.routeInfo.location.pathname === "/my-properties-ads"
                  ? "secondary"
                  : "primary"
              }  `}
            >
              <div className="d-flex px-2 pointer">
                <i className="fas fa-tv fa-fw font-s-medium"></i>
                <span className="font-s-regular font-w-s-medium font-s-text mx-2">
                  {translator.myAds}
                </span>
              </div>
            </NavLink>
          </th>
        </tr>
        <tr>
          <th className="px-0">
            <NavLink
              to="/my-favorites"
              className={`text-s-${
                props.routeInfo.location.pathname === "/my-favorites"
                  ? "secondary"
                  : "primary"
              }  `}
            >
              <div className="d-flex px-2 pointer">
                <i className="fas fa-heart fa-fw font-s-medium"></i>
                <span className="font-s-regular font-w-s-medium mx-2">
                  {translator.myFavs}
                </span>
              </div>
            </NavLink>
          </th>
        </tr>
        <tr>
          <th className="px-0">
            <NavLink
              to="/my-contacts"
              className={`text-s-${
                props.routeInfo.location.pathname === "/my-contacts"
                  ? "secondary"
                  : "primary"
              }  `}
            >
              <div className="d-flex px-2 pointer">
                <i className="fas fa-phone-volume fa-fw fa-flip-horizontal font-s-medium"></i>
                <span className="font-s-regular font-w-s-medium font-s-text mx-2">
                  {translator.myContacts}
                </span>
              </div>
            </NavLink>
          </th>
        </tr>
        <tr>
          <th className="px-0">
            <NavLink
              to="/change-password"
              className={`text-s-${
                props.routeInfo.location.pathname === "/change-password"
                  ? "secondary"
                  : "primary"
              }  `}
            >
              <div className="d-flex px-2 pointer">
                <i className="fas fa-lock fa-fw font-s-medium"></i>
                <span className="font-s-regular font-w-s-medium font-s-text mx-2">
                  {translator.password}
                </span>
              </div>
            </NavLink>
          </th>
        </tr>
      </tbody>
    </Fragment>
  );
};

export default SideBarItems;
