import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ScreenSizeContext from "../../contexts/screen-size-context";
import TranslationContext from "../../contexts/translation-context";

const MobileMenu = () => {
  const screenWidth = useContext(ScreenSizeContext).screenWidth;
  const translator = {
    ...useContext(TranslationContext).header,
    ...useContext(TranslationContext).articles,
  };

  return (
    <React.Fragment>
      <div
        className="mobile-menu-area d-block d-md-none"
        style={{ position: "relative", zIndex: "1" }}
      >
        <div className={`${screenWidth >= 768 ? 'container' : 'container-fluid mean-container'}`}>
          <div className="row">
            <div className="col-12">
              <div className="mobile-menu">
                <nav id="dropdown">
                  <ul>
                    <li>
                      <Link to="/">
                        <span className="font-s-title font-s-regular font-w-s-light">
                          {translator.home}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <div style={{padding:'1em 5%'}}>
                        <span className="font-s-title font-s-regular font-w-s-light">
                          {translator.agents}
                        </span>
                      </div>
                      <ul>
                        <li>
                          <Link
                            to="/register-agent"
                            className="font-s-title font-s-small font-w-s-light text-s-primary text-capitalize"
                          >
                            {translator.agentRegistration}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/agents"
                            className="font-s-title font-s-small font-w-s-light text-s-primary text-capitalize"
                          >
                            {translator.agentsView}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div style={{padding:'1em 5%'}}>
                        <span className="font-s-title font-s-regular font-w-s-light">
                          {translator.articlesDept}
                        </span>
                      </div>
                      <ul>
                        <li>
                          <Link
                            to="/show-articles/1"
                            className="font-s-title font-s-small font-w-s-light text-s-primary text-capitalize"
                          >
                            {translator.articleTips}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/show-articles/2"
                            className="font-s-title font-s-small font-w-s-light text-s-primary text-capitalize"
                          >
                            {translator.articleNews}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/show-articles/3"
                            className="font-s-title font-s-small font-w-s-light text-s-primary text-capitalize"
                          >
                            {translator.articleFinishes}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileMenu;
