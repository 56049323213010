import React, { useContext } from "react";
import TranslationContext from "../../../contexts/translation-context";

const AreaRange = () => {
  const translator = useContext(TranslationContext).findBox;
  return (
    <React.Fragment>
      <option value="1">{translator.areaL_100}</option>
      <option value="2">{translator.areaB_100_200}</option>
      <option value="3">{translator.areaB_200_300}</option>
      <option value="4">{translator.areaB_300_500}</option>
      <option value="5">{translator.areaB_500_1000}</option>
      <option value="6">{translator.areaM_1000}</option>
    </React.Fragment>
  );
};

export default AreaRange;
