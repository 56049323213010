import React, { useContext } from "react";
import TranslationContext from "../contexts/translation-context";
import AdSideSearchBox from './ad-side-search-box';

const AdSide = ({handleSearchParams}) => {
  const translator = useContext(TranslationContext).ads;
  return (
    <React.Fragment>
      <aside className="widget widget-search-property mb-30 px-3">
        <AdSideSearchBox handleSearchParams={handleSearchParams} />
      </aside>

      <aside className="widget widget-featured-property mb-30 px-3">
      <h4 className="font-s-title text-s-primary mb-4">{translator.featuredAProp}</h4>
        <div className="row">
          <div className="col-lg-12 col-md-6 col-12">
            <div className="flat-item">
              <div className="flat-item-image">
                <span className="for-sale">For Sale</span>
                <a href="#">
                  <img src="/assets/images/flat/1.jpg" alt="" />
                </a>
                <div className="flat-link">
                  <a href="#">More Details</a>
                </div>
                <ul className="flat-desc">
                  <li>
                    <img src="/assets/images/icons/4.png" alt="" />
                    <span>450 sqft</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/5.png" alt="" />
                    <span>5</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/6.png" alt="" />
                    <span>3</span>
                  </li>
                </ul>
              </div>
              <div className="flat-item-info">
                <div className="flat-title-price">
                  <h5>
                    <a href="#">Masons de Villa </a>
                  </h5>
                  <span className="price">$52,350</span>
                </div>
                <p>
                  <img src="/assets/images/icons/location.png" alt="" />
                  568 E 1st Ave, Ney Jersey
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-6 col-12">
            <div className="flat-item">
              <div className="flat-item-image">
                <span className="for-sale">For Sale</span>
                <a href="#">
                  <img src="/assets/images/flat/2.jpg" alt="" />
                </a>
                <div className="flat-link">
                  <a href="#">More Details</a>
                </div>
                <ul className="flat-desc">
                  <li>
                    <img src="/assets/images/icons/4.png" alt="" />
                    <span>450 sqft</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/5.png" alt="" />
                    <span>5</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/6.png" alt="" />
                    <span>3</span>
                  </li>
                </ul>
              </div>
              <div className="flat-item-info">
                <div className="flat-title-price">
                  <h5>
                    <a href="#">Masons de Villa </a>
                  </h5>
                  <span className="price">$52,350</span>
                </div>
                <p>
                  <img src="/assets/images/icons/location.png" alt="" />
                  568 E 1st Ave, Ney Jersey
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 d-none d-lg-block">
            <div className="flat-item">
              <div className="flat-item-image">
                <span className="for-sale">For Sale</span>
                <a href="#">
                  <img src="/assets/images/flat/3.jpg" alt="" />
                </a>
                <div className="flat-link">
                  <a href="#">More Details</a>
                </div>
                <ul className="flat-desc">
                  <li>
                    <img src="/assets/images/icons/4.png" alt="" />
                    <span>450 sqft</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/5.png" alt="" />
                    <span>5</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/6.png" alt="" />
                    <span>3</span>
                  </li>
                </ul>
              </div>
              <div className="flat-item-info">
                <div className="flat-title-price">
                  <h5>
                    <a href="#">Masons de Villa </a>
                  </h5>
                  <span className="price">$52,350</span>
                </div>
                <p>
                  <img src="/assets/images/icons/location.png" alt="" />
                  568 E 1st Ave, Ney Jersey
                </p>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </React.Fragment>
  );
};

export default AdSide;
