import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TranslationContext from "../../contexts/translation-context";
import MasterDataContext from "../../contexts/master-data-context";

const LastAddedAds = ({ screenWidth }) => {
  const translator = useContext(TranslationContext).ads;
  const { masterData } = useContext(MasterDataContext);
  const { propertiesAds } = masterData;
  const [groupElementsNo, setGroupElementsNo] = useState(0);
  const [adsGroups, setAdsGroups] = useState([]);

  useEffect(() => setGroupsElementsNumber(), [screenWidth]);

  useEffect(() => showActiveLastAds(), [groupElementsNo]);

  const setGroupsElementsNumber = () => {
    if (screenWidth >= 992) setGroupElementsNo(3);
    else if (screenWidth < 768) setGroupElementsNo(1);
    else setGroupElementsNo(2);
  };

  const showActiveLastAds = () => {
    const adsCount =
      typeof propertiesAds === "object" && "ads" in propertiesAds
        ? propertiesAds.ads.length
        : 0;
    const modulusRest = adsCount % groupElementsNo;
    //const totalGroups = (modulusRest === 0 ? 0 : 1) + (adsCount - modulusRest) / groupElementsNo; use this when need to add rest ads to last group
    const totalGroups = (adsCount - modulusRest) / groupElementsNo;
    const adsGroupsShadow = [];
    var subGroup = [];
    for (var i = 1; i <= totalGroups; i++) {
      for (
        var n = i * groupElementsNo - groupElementsNo;
        n < i * groupElementsNo;
        n++
      ) {
        n < adsCount && subGroup.push(n);
      }
      adsGroupsShadow.push(subGroup);
      subGroup = [];
    }
    setAdsGroups([...adsGroupsShadow]);
  };

  return (
    <React.Fragment>
      <div className="featured-flat-area pt-60 pb-30">
        <div
          className={`${screenWidth >= 768 ? "container" : "container-fluid"}`}
        >
          <div className="row">
            <div className="col-12">
              <div className="section-title-2 text-center">
                <div className="d-flex justify-content-center align-items-baseline mb-10">
                  <i
                    className={`fas fa-award text-s-candy ${
                      screenWidth >= 768 ? "display-4" : "font-s-xlarge"
                    }`}
                  ></i>
                  <span
                    className={`text-s-primary font-s-title ${
                      screenWidth >= 768 ? "display-4" : "font-s-xlarge"
                    }  mx-2`}
                  >
                    {translator.lastProperties}
                  </span>
                </div>
                <p
                  className={`font-s-text ${
                    screenWidth >= 768 ? "font-s-regular" : "font-s-xsmall"
                  } font-w-s-light`}
                >
                  {translator.featuredText}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide w-100"
              data-ride="carousel"
              style={{ maxWidth: `${screenWidth >= 768 ? "" : "450px"}` }}
            >
              <div className="carousel-inner">
                {adsGroups.map((group, index) => (
                  <div
                    key={`carousel-item-${index + 1}`}
                    className={`carousel-item ${index === 0 && "active"} `}
                  >
                    <div className="row">
                      {group.map((element) => {
                        const ad = propertiesAds.ads;
                        return (
                          <div
                            key={ad[element].id}
                            className="col-lg-4 col-md-6 col-12"
                          >
                            <div className="flat-item d-flex flex-column">
                              <div
                                className="flat-item-image"
                                style={{
                                  backgroundImage: `url(${ad[element].default_image})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                                <span
                                  className={`for-sale ${
                                    ad[element].ad_purpose === 0
                                      ? "bg-s-candy"
                                      : "bg-s-deepWarning"
                                  } font-s-title text-white font-s-regular font-w-s-medium`}
                                >
                                  {ad[element].ad_purpose_readable}
                                </span>
                                <Link
                                  to={`/show-property-ad/${ad[element].id}`}
                                >
                                  <img
                                    style={{ height: "235px", opacity: "0" }}
                                    src={ad[element].default_image}
                                    alt=""
                                  />
                                </Link>
                                <div className="flat-link ">
                                  <Link
                                    to={`/show-property-ad/${ad[element].id}`}
                                  >
                                    <span className="font-s-text font-s-medium font-w-s-medium text-white">
                                      {translator.details}
                                    </span>
                                  </Link>
                                </div>
                                <ul className="flat-desc  d-flex justify-content-between px-4">
                                  <li>
                                    <i className="fas fa-ruler-combined text-s-secondary font-s-medium mx-1"></i>
                                    <span className="font-s-text font-s-regular text-white">
                                      {ad[element].area} {translator.meter}
                                    </span>
                                  </li>
                                  {ad[element].presentable_features.map(
                                    (feature) => {
                                      return (
                                        <li key={feature.id}>
                                          <i
                                            className={`${feature.icon} text-s-secondary font-s-medium mx-1`}
                                          ></i>
                                          <span className="font-s-text font-s-regular text-white">
                                            {feature.value}
                                          </span>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                              <div className="flat-item-info">
                                <div className="flat-title-price ">
                                  <h5>
                                    <span className="font-s-text font-s-medium font-w-s-medium text-s-primary">
                                      {ad[element].category.name}
                                    </span>
                                  </h5>
                                  <span className="price font-s-text font-s-medium font-w-s-medium text-s-secondary">
                                    {ad[element].price} {translator.egp}
                                  </span>
                                </div>
                                <p>
                                  <img
                                    src="assets/images/icons/location.png"
                                    alt=""
                                  />
                                  <span className="font-s-text font-s-regular font-w-s-medium text-s-candy">
                                    {ad[element].city.name}
                                  </span>
                                  {" - "}
                                  <span className="font-s-text font-s-regular font-w-s-medium text-s-primary">
                                    {ad[element].district.name}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
                <div key="carousel-item-0" className="carousel-item">
                  <div className="row">
                    <div className="col-12">
                      <div className="flat-item pointer">
                        <div className="flat-item-image">
                          <div
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{ height: "379px" }}
                          >
                            <span
                              className={`font-s-text text-s-primary ${
                                screenWidth >= 768
                                  ? "font-s-xxlarge font-w-s-bold"
                                  : "font-s-large font-w-s-medium"
                              }  text-capitalize`}
                            >
                              {translator.moreNewAds}
                            </span>

                            <div className="w-25 mt-5">
                              <button className="btn btn-block rounded p-5 align-items-center d-flex justify-content-center">
                                <i
                                  className={`fas fa-glasses text-s-secondary fa-3x animate__animated animate__swing animate__slower animate__infinite`}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-flat">
            <div className="row">
              <div className="col-12">
                <div
                  className="bg-s-primary d-flex justify-content-center align-items-center"
                  style={{ height: "120px" }}
                >
                  <div className="d-flex justify-content-between align-items-center w-100 px-5">
                    <i
                      className={`fas fa-ad ${
                        screenWidth >= 768 ? "fa-3x" : "fa-2x"
                      } text-s-lightBlue`}
                    ></i>
                    <span
                      className={`${
                        screenWidth >= 768 ? "font-s-xxlarge" : "font-s-xlarge"
                      } text-s-secondary font-s-title mx-2`}
                    >
                      {translator.sponsorship}
                    </span>
                    <i
                      className={`fas fa-ad ${
                        screenWidth >= 768 ? "fa-3x" : "fa-2x"
                      } text-s-lightBlue`}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LastAddedAds;
