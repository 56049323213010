import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import ProfileContext from "../contexts/profile-context";

export const RouteSigned = ({ path, component: Component }) => {
  const { profile } = useContext(ProfileContext);
  return profile.isLogged ? (
    <Route path={path} exact component={Component} />
  ) : (
    <Redirect to="/sign-in" />
  );
};

export const RouteUnSigned = ({ path, component: Component }) => {
  const { profile } = useContext(ProfileContext);
  return !profile.isLogged ? (
    <Route path={path} exact component={Component} />
  ) : (
    <Redirect to="/" />
  );
};

export const RouteActivated = ({ path, component: Component }) => {
  const { profile } = useContext(ProfileContext);
  
  if (profile.userTypeId === 4) {
    if (profile.activeAgent) {
      return <Route path={path} exact component={Component} />;
    } else {
      if (!profile.isVerified) return (<Redirect to="/phone-authentication" />);
      else return (<Redirect to="/my-profile" />);
      
    }
  }
  return profile.isVerified ? (
    <Route path={path} exact component={Component} />
  ) : profile.mobile ? (
    <Redirect to="/phone-authentication" />
  ) : (
    <Redirect to="/my-profile" />
  );
};

export const RouteCompleted = ({ path, component: Component }) => {
  const { profile } = useContext(ProfileContext);
  return profile.isProfileCompleted ? (
    <Route path={path} exact component={Component} />
  ) : (
    <Redirect to="/my-profile" />
  );
};

export const RouteNonActivated = ({ path, component: Component }) => {
  const { profile } = useContext(ProfileContext);
  return profile.isLogged && !profile.isVerified ? (
    <Route
      path={path}
      exact
      render={(props) => <Component {...props} profile={profile} />}
    />
  ) : profile.isLogged ? (
    <Redirect to="/" />
  ) : (
    <Redirect to="/sign-in" />
  );
};
