import { Fragment, useState, useEffect, useContext } from "react";
import { getArticle } from "../../services/data-request-center";
import TranslationContext from "../../contexts/translation-context";
import LoadingComponent from "../../components/loading-component";
import {Link} from 'react-router-dom';

const ArticleScreen = (props) => {
  const articleId = props.match.params.id;
  const translator = useContext(TranslationContext).articles;
  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    callGetArticle();
  }, []);
  useEffect(() => {
    article !== null && setLoading(false);
  }, [article]);

  const callGetArticle = async () => {
    const { data, status } = await getArticle(articleId);
    console.log(data);
    status === 200 && setArticle(data.article);
  };
  return (
    <Fragment>
      {loading ? (
        <LoadingComponent />
      ) : (
        <section>
          <div
            className="d-inline-block w-100"
            style={{
              background: 'url("/assets/images/customizer/pattern/3.png")',
            }}
          >
            <div className="container-fluid  pt-115 pb-60">
              <div className="card text-center py-5  px-5 card-limit-size">
              <Link to={`/show-articles/${article.article_type}`}>
                <div
                  className="bg-s-primary my-2 py-0 px-2 rounded"
                  style={{ width: "fit-content" }}
                >
                  <span className="text-s-secondary font-s-title font-s-medium font-w-s-medium text-nowrap overflow-hidden">
                    {article.article_type_name}
                  </span>
                </div>
                </Link>
                <div className="d-flex my-5">
                  <i className="fas fa-book-reader font-s-xlarge text-s-secondary"></i>{" "}
                  <h3 className="mx-2 text-capitalize font-s-title">
                    {article.title}
                  </h3>
                </div>
                <div className="d-flex mb-2 align-items-center justify-content-center">
                  <img
                    src={article.article_image}
                    style={{ width: "100%", height: "auto" }}
                    className="rounded"
                  />
                </div>
                <div className="d-flex mb-5">
                  <span className="text-muted font-s-title font-s-regular font-w-s-light text-capitalize">
                    {translator.articleDate}:
                  </span>{" "}
                  <span className="text-s-primary font-s-title font-s-regular font-w-s-medium">
                    {article.create_at}
                  </span>
                </div>

                <div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: article.article_text }}
                  />
                </div>
                <hr className="border-s-cream"></hr>
                <div className="d-flex justify-content-center align-items-center">
                  <i className="fas fa-heart text-s-candy "></i>
                  <span className="font-s-title font-w-s-medium text-capitalize mx-2">
                    {translator.articlesSerial}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default ArticleScreen;
