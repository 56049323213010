import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import ScreenSizeContext from "../../contexts/screen-size-context";
import TranslationContext from "../../contexts/translation-context";
import ProfileContext from "../../contexts/profile-context";
import Button from "@material-ui/core/Button";
import { useLoadStyle } from "../../hooks/load-style";
import { resources, DomainName } from "../../services/config";
import {
  logout,
  authChannelSubscription,
} from "../../services/data-request-center";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import axios from "axios";

const Header = () => {
  useLoadStyle(resources.animateCss);
  const { toggleDirection } = useContext(LayoutDirectionContext);
  const screenWidth = useContext(ScreenSizeContext).screenWidth;
  const translator = {
    ...useContext(TranslationContext).header,
    ...useContext(TranslationContext).articles,
  };
  const { profile, setProfile } = useContext(ProfileContext);
  const [animation, setAnimation] = useState("animate__pulse");

  useEffect(() => {
    setInterval(() => {
      const animations = [
        "animate__bounce",
        "animate__flash",
        "animate__pulse",
        "animate__shakeX",
        "animate__shakeY",
        "animate__shakeY",
        "animate__swing",
        "animate__tada",
        "animate__wobble",
        "animate__wobble",
        "animate__heartBeat",
      ];
      setAnimation("");
      setAnimation(animations[Math.floor(Math.random() * 11)]);
    }, 3000);
  }, []);

  useEffect(() => {
    if (profile.isLogged) {
      let echo = new Echo({
        broadcaster: "pusher",
        key: "myKey",
        wsHost: DomainName,
        wssPort: 6001,
        forceTLS: true,
        cluser: "mt1",
        disableStats: true,

        authorizer: (channel, options) => {
          return {
            authorize: async (socketId, callback) => {
              const { data, status } = await authChannelSubscription(
                socketId,
                channel
              );
              console.log(status);
              console.log(data.auth);
              callback(false, data);
            },
          };
        },
      });
      echo
        .private(`App.Model.User.${profile.id}`)
        .listen("AdStatusChangeEvent", (message) => {
          console.log(message);
        })
        .notification((notification) => {
          console.log(notification);
        });
    }
  }, [profile]);

  const callLogout = async () => {
    const { status } = await logout();
    setProfile({ isLogged: false });
  };

  return (
    <React.Fragment>
      <header
        className="header-area header-wrapper"
      >
        <div
          className="header-middle-area bg-s-pine w-100 "
          style={{
            height: "45px",
            position: "absloute",
            top: "0",
          }}
        >
          <div className={`${screenWidth >= 768 ? 'container' : 'container-fluid px-5'}`}>
            <div className="full-width-mega-drop-menu">
              <div className="row">
                <div className="col-12">
                  <nav id="primary-menu">
                    <ul className="main-menu text-center row justify-content-between">
                      <li
                        className="align-items-center d-flex justify-content-start"
                        style={{ height: "45px" }}
                      >
                        <div
                          style={{ height: "36px", width: "36px" }}
                          className="bg-dark p-1 rounded-circle border border-warning d-flex justify-content-center align-items-center pointer"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={translator.createAd}
                        >
                          <span className="text-warning">
                            <i className="fas fa-tv animate__animated animate__pulse animate__slower animate__infinite"></i>
                          </span>
                        </div>
                        <div className="mx-1"></div>
                        <div
                          style={{ height: "36px", width: "36px" }}
                          className="bg-dark p-1 rounded-circle border border-warning d-flex justify-content-center align-items-center pointer"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={translator.searchProperty}
                        >
                          <span className="text-s-secondary">
                            <i className="fas fa-home animate__animated animate__heartBeat animate__slower animate__infinite"></i>
                          </span>
                        </div>
                      </li>
                      <li
                        className="align-items-center d-flex"
                        style={{ height: "45px" }}
                      >
                        <Button
                          onClick={toggleDirection}
                          style={{ padding: "0", height: "25px" }}
                        >
                          <span className="text-warning btn-language">
                            {translator.showLang}
                          </span>
                        </Button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-top-bar bg-white">
          <div className={`${screenWidth >= 768 ? 'container' : 'container-fluid px-5'}`}>
            <div className="row d-flex justify-content-between align-items-center">
              <div
                className={`${
                  screenWidth >= 768 ? "col-lg-3 col-md-6 col-12" : ""
                }`}
              >
                <div className="logo d-flex align-items-center py-0">
                  <a href="index.html">
                    <img
                      src="/assets/images/logo/mo-house-logo.png"
                      alt="mo-house-logo"
                      height={`${screenWidth >= 768 ? "110" : "60"}`}
                    />
                  </a>
                  {/* {screenWidth >= 768 && (
                    <a href="index.html">
                      <img
                        src="/assets/images/logo/aqarmax-logo-text.jpg"
                        alt="mo-house-logo"
                        height="75px"
                      />
                    </a>
                  )} */}
                </div>
              </div>
              {profile.isLogged ? (
                <div
                  className={`${
                    screenWidth >= 768 ? "col-lg-3 col-md-6 col-12" : ""
                  } px-0`}
                >
                  <div className="d-flex justify-content-center company-info clearfix header-account-box">
                    <div
                      className="btn-group"
                      style={{
                        position: `${
                          screenWidth >= 768 ? "absolute" : "relative"
                        }`,
                      }}
                    >
                      {(!profile.mobile ||
                        !profile.isProfileCompleted ||
                        !profile.isVerified) && (
                        <i
                          className={`fas fa-bell font-s-xlarge text-s-deepWarning notification-icon animate__animated ${animation}`}
                        ></i>
                      )}

                      <button
                        type="button"
                        className="btn bg-s-primary dropdown-toggle d-flex align-items-center justify-content-between text-white font-s-text font-w-s-medium font-s-regular overflow-hidden text-capitalize"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          height: "40px",
                          width: `${screenWidth >= 768 ? "250px" : "200px"}`,
                        }}
                      >
                        <i className="fas fa-user text-s-secondary"></i>{" "}
                        <span className={`text-white font-s-text font-w-s-${screenWidth >= 768 ? 'medium' : 'light'} font-s-regular align-self-end text-nowrap overflow-hidden mx-1`}>
                          {translator.welcome + " " + profile.firstName}
                        </span>
                      </button>
                      <div className="dropdown-menu" style={{ width: `${screenWidth >= 768 ? "250px" : "200px"}` }}>
                        {!profile.isProfileCompleted && (
                          <div className="dropdown-item text-white font-s-text font-w-s-light font-s-regular pointer bg-s-mint">
                            <div className="d-flex">
                              <div style={{ width: "30px" }}>
                                <i className="far fa-id-badge"></i>
                              </div>
                              <Link
                                to="/my-profile"
                                className="animate__animated animate__pulse animate__infinite"
                              >
                                <span className="text-white font-w-s-medium text-capitalize">
                                  {translator.completeProfile}
                                </span>
                              </Link>
                            </div>
                          </div>
                        )}
                        {!profile.mobile && profile.isProfileCompleted && (
                          <Link
                            to="/my-profile"
                            className="animate__animated animate__pulse animate__infinite"
                          >
                            <div className="dropdown-item text-white font-s-text font-w-s-light font-s-regular pointer bg-s-mint">
                              <div className="d-flex">
                                <div style={{ width: "30px" }}>
                                  <i className="fas fa-mobile-alt"></i>
                                </div>
                                <span className="text-white font-w-s-medium text-capitalize">
                                  {translator.addMobile}
                                </span>
                              </div>
                            </div>
                          </Link>
                        )}
                        {!profile.isVerified &&
                          profile.isProfileCompleted &&
                          profile.mobile && (
                            <div className="dropdown-item text-white font-s-text font-w-s-light font-s-regular pointer bg-s-mint">
                              <div className="d-flex">
                                <div style={{ width: "30px" }}>
                                  <i className="fas fa-sms"></i>
                                </div>
                                <Link
                                  to="/phone-authentication"
                                  className="animate__animated animate__pulse animate__infinite"
                                >
                                  <span className="text-white font-w-s-medium text-capitalize">
                                    {translator.phoneActivation}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          )}
                        {(!profile.mobile ||
                          !profile.isProfileCompleted ||
                          !profile.isVerified) && (
                          <div className="dropdown-divider"></div>
                        )}

                        <Link to="/my-profile">
                          <div className="dropdown-item text-s-primary font-s-text font-w-s-light font-s-regular pointer">
                            <div className="d-flex">
                              <div style={{ width: "30px" }}>
                                <i className="fas fa-user"></i>
                              </div>

                              <span className="text-s-primary font-w-s-medium">
                                {translator.myAccount}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <Link to="/create-property-ad">
                          <div className="dropdown-item text-s-primary font-s-text font-w-s-light font-s-regular pointer">
                            <div className="d-flex">
                              <div style={{ width: "30px" }}>
                                <i className="far fa-newspaper"></i>
                              </div>
                              <span className="text-s-candy font-w-s-medium">
                                {translator.createAd}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <Link to="/my-properties-ads">
                          <div className="dropdown-item text-s-primary font-s-text font-w-s-light font-s-regular pointer">
                            <div className="d-flex">
                              <div style={{ width: "30px" }}>
                                <i className="fas fa-tv"></i>
                              </div>
                              <span className="text-s-primary font-w-s-medium">
                                {translator.myAds}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <div className="dropdown-item text-s-primary font-s-text font-w-s-light font-s-regular pointer">
                          <div className="d-flex">
                            <div style={{ width: "30px" }}>
                              <i className="fas fa-bell"></i>
                            </div>
                            <span className="text-s-primary font-w-s-medium">
                              {translator.myNotifications}
                            </span>
                          </div>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item text-s-primary font-s-text font-w-s-light font-s-regular pointer">
                          <div className="d-flex" onClick={callLogout}>
                            <div style={{ width: "30px" }}>
                              <i className="fas fa-sign-out-alt"></i>
                            </div>
                            <span className="text-s-primary font-w-s-medium">
                              {translator.logout}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    screenWidth >= 768 ? "col-lg-3 col-md-6 col-12" : ""
                  } px-0`}
                >
                  <div className="d-flex justify-content-center company-info clearfix header-account-box">
                    <div className="btn-group">
                      <button
                        className="btn bg-s-primary dropdown-toggle d-flex align-items-center justify-content-between text-white font-s-text font-w-s-medium font-s-regular overflow-hidden text-capitalize"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ height: "40px", width: `${screenWidth >= 768 ? "250px" : "200px"}`, }}
                      >
                        <i className="fas fa-user-lock text-s-secondary"></i>{" "}
                        <span className={`text-white font-s-text font-w-s-${screenWidth >= 768 ? 'medium' : 'light'} font-s-regular align-self-end text-nowrap overflow-hidden mx-1`}>
                          {translator.sign}
                        </span>
                      </button>
                      <div className="dropdown-menu" style={{ width: "250px" }}>
                        <div className="text-center my-2 py-2 bg-s-primary">
                          <span className={`text-s-secondary text-s-title ${screenWidth >= 768 ? 'font-s-medium font-w-s-medium' : 'font-s-regular font-w-s-light'} text-capitalize`}>
                            {translator.welcomeTo}
                          </span>
                        </div>
                        <Link to="/sign-in">
                          <div className="dropdown-item text-s-primary font-s-text font-w-s-light font-s-regular pointer">
                            <div className="d-flex">
                              <div style={{ width: "30px" }}>
                                <i className="fas fa-fingerprint"></i>
                              </div>
                              <span className="text-s-primary font-w-s-medium">
                                {translator.signIn}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <Link to="/sign-up">
                          <div className="dropdown-item text-s-primary font-s-text font-w-s-light font-s-regular pointer">
                            <div className="d-flex">
                              <div style={{ width: "30px" }}>
                                <i className="fas fa-user-plus"></i>
                              </div>
                              <span className="text-s-primary font-w-s-medium">
                                {translator.signUp}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          id="sticky-header"
          className="header-middle-area transparent-header d-none d-md-block"
        >
          <div className="container">
            <div className="full-width-mega-drop-menu">
              <div className="row">
                <div className="col-12">
                  <div className="sticky-logo">
                    <a href="index.html">
                      <img src="/assets/images/logo/mo-house-logo.png" alt="" />
                    </a>
                  </div>
                  <nav id="primary-menu">
                    <ul className="main-menu text-center row justify-content-around">
                      <li>
                        <Link to="/">
                          <span className="font-s-title font-s-regular font-w-s-bold">
                            {translator.home}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <a href="http://modesigns.studio">
                          {translator.qahraman}
                        </a>
                        <ul className="drop-menu">
                          <li>
                            <a href="elements-accordion.html">Accordion</a>
                          </li>
                          <li>
                            <a href="elements-agent.html">Agent</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a className="font-s-title font-s-regular font-w-s-bold">
                          {translator.agents}
                        </a>
                        <ul className="drop-menu">
                          <li>
                            <a className="px-0 pt-1 pb-0">
                              <Link
                                to="/register-agent"
                                className="font-s-title font-s-regular font-w-s-medium text-s-primary text-capitalize"
                              >
                                {translator.agentRegistration}
                              </Link>
                            </a>
                          </li>
                          <li>
                            <a className="px-0 pt-1 pb-0">
                              <Link
                                to="/agents"
                                className="font-s-title font-s-regular font-w-s-medium text-s-primary text-capitalize"
                              >
                                {translator.agentsView}
                              </Link>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="properties.html">{translator.partners}</a>
                        <ul className="drop-menu">
                          <li>
                            <a href="properties.html">Properties</a>
                          </li>

                          <li>
                            <a href="properties-details.html">
                              Properties details
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a>{translator.developers}</a>
                        <ul className="drop-menu">
                          <li>
                            <a href="agent.html">Agent</a>
                          </li>
                          <li>
                            <a href="agent-details.html">Agent details</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="http://modesigns.studio">
                          {translator.offers}
                        </a>
                        <ul className="drop-menu menu-right">
                          <li>
                            <a href="about.html">About</a>
                          </li>
                          <li>
                            <a href="agent.html">Agent</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a className="font-s-title font-s-regular font-w-s-bold">
                          {translator.articlesDept}
                        </a>
                        <ul className="drop-menu menu-right">
                          <li>
                            <a className="px-0 pt-1 pb-0">
                              <Link
                                to="/show-articles/1"
                                className="font-s-title font-s-regular font-w-s-medium text-s-primary text-capitalize"
                              >
                                {translator.articleTips}
                              </Link>
                            </a>
                          </li>
                          <li>
                            <a className="px-0 pt-1 pb-0">
                              <Link
                                to="/show-articles/2"
                                className="font-s-title font-s-regular font-w-s-medium text-s-primary text-capitalize"
                              >
                                {translator.articleNews}
                              </Link>
                            </a>
                          </li>
                          <li>
                            <a className="px-0 pt-1 pb-0">
                              <Link
                                to="/show-articles/3"
                                className="font-s-title font-s-regular font-w-s-medium text-s-primary text-capitalize"
                              >
                                {translator.articleFinishes}
                              </Link>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
