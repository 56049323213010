import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getMyProperties } from "../../services/data-request-center";
import TranslationContext from "../../contexts/translation-context";
import LayoutDirectionContext from "../../contexts/layout-direction-context";
import ProfileContext from "../../contexts/profile-context";
import LoadingComponent from "../../components/loading-component";
import MyAccount from "./my-account";
import PaginationBar from "../../components/ui-components/pagination-bar";

const MyProperties = (props) => {
  const translator = useContext(TranslationContext).ads;
  const { direction } = useContext(LayoutDirectionContext);
  const { profile } = useContext(ProfileContext);
  const [myProperties, setMyProperties] = useState({});
  const [loading, setLoading] = useState(true);
  const [paginate, setPaginate] = useState("");
  const [viewerDetails, setViewerDetails] = useState([]);

  useEffect(() => {
    callGetMyProperties();
  }, []);

  useEffect(() => {
    myProperties.ads && setLoading(false);
  }, [myProperties]);

  useEffect(() => {
    paginate !== "" && callGetMyProperties();
  }, [paginate]);

  const callGetMyProperties = async () => {
    const { data, status } = await getMyProperties(paginate);
    status === 200 && setMyProperties(data.propertiesAds);
  };

  return (
    <React.Fragment>
      <MyAccount routeInfo={props}>
        {loading ? (
          <LoadingComponent />
        ) : myProperties.ads.length > 0 ? (
          <React.Fragment>
            <div className="d-flex">
              <h1 className="font-s-title">{translator.myAds}</h1>
            </div>
            <div className="d-flex align-items-baseline bg-s-primary py-1 my-3">
              <div style={{ flex: 1 }}>
                <i className="fas fa-camera text-s-cream font-s-medium"></i>
              </div>
              <div style={{ flex: 3 }}>
                <span className="text-s-cream font-s-title font-s-medium font-w-s-medium text-capitalize">
                  {translator.title}
                </span>
              </div>
              <div style={{ flex: 1 }}>
                <span className="text-s-cream font-s-title font-s-medium font-w-s-medium text-capitalize">
                  {translator.status}
                </span>
              </div>
              <div style={{ flex: 1 }}>
                <span className="text-s-cream font-s-title font-s-medium font-w-s-medium text-capitalize">
                  {translator.views}
                </span>
              </div>
              <div style={{ flex: 1 }}>
                <span className="text-s-cream font-s-title font-s-medium font-w-s-medium text-capitalize">
                  {translator.expiry}
                </span>
              </div>
            </div>
            {myProperties.ads.map((property) => {
              const exDate = String(property.expiry_date);
              const expiryDate = new Date(property.expiry_date);
              const today = new Date();
              const expired = expiryDate.getTime() > today.getTime();
              return (
                <React.Fragment>
                  <div className="d-flex align-items-baseline border-bottom my-3">
                    <Link to={`/show-property-ad/${property.id}`}>
                      <div
                        className="bg-s-primary"
                        style={{ height: "75px", width: "125px", flex: 1 }}
                      >
                        <img src={property.default_image} className="h-100" />
                      </div>
                    </Link>

                    <div
                      className="flex-3 mx-2 text-s-start overflow-hidden"
                      style={{ flex: 3, maxHeight: "60px" }}
                    >
                      <Link to={`/show-property-ad/${property.id}`}>
                        <span className="text-s-primary font-s-text">
                          {property.title}
                        </span>
                      </Link>
                    </div>
                    <div style={{ flex: 1 }}>
                      <span
                        className={`font-s-regular font-s-text
                      ${
                        property.ad_status === 1
                          ? "text-s-secondary"
                          : property.ad_status === 0
                          ? "text-s-deepWarning"
                          : "text-s-candy"
                      }`}
                      >
                        {property.readable_ad_status}
                      </span>
                    </div>
                    <div style={{ flex: 1 }}>
                      {profile.userTypeId === 4 ? (
                        <button
                          className="btn btn-outline-warning rounded"
                          data-toggle="modal"
                          data-target="#staticBackdrop"
                          onClick={() => {
                            setViewerDetails(property.viewers);
                          }}
                        >
                          <span className="text-s-primary font-s-medium font-s-text font-w-s-medium">
                            {property.views}
                          </span>
                        </button>
                      ) : (
                        <span className="text-s-primary font-s-medium font-s-text font-w-s-medium">
                          {property.views}
                        </span>
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      {property.expiry_date &&
                        !expired &&
                        property.ad_status === 1 && (
                          <i
                            className="fas fa-thumbs-down text-s-candy font-s-medium"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={translator.expired}
                          ></i>
                        )}{" "}
                      {property.expiry_date &&
                        expired &&
                        property.ad_status === 1 && (
                          <span className="text-s-secondary font-s-text font-s-regular">
                            {property.expiry_date &&
                              String(property.expiry_date).slice(0, 10)}
                          </span>
                        )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            {myProperties.last_page > 1 && (
              <PaginationBar
                pagesCount={myProperties.last_page}
                currentPage={myProperties.current_page}
                firstElement={myProperties.first_item}
                lastElement={myProperties.last_item}
                total={myProperties.total}
                direction={direction}
                translator={translator}
                setPaginate={setPaginate}
              />
            )}

            {/* Viewers Info Modal */}
            <div
              class="modal fade"
              id="staticBackdrop"
              data-backdrop="static"
              data-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title font-s-title text-s-deepWarning text-capitalize"
                      id="staticBackdropLabel"
                    >
                      {translator.viewers}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {viewerDetails.map((viewer) => {
                      return(
                      <div className="d-flex flex-column  my-3 px-2 py-2 border rounded">
                        <div
                          className="d-flex align-items-baseline"
                          style={{ height: "35px" }}
                        >
                          <div className="rounded" style={{ width: "35px" }}>
                            <img src={viewer.profileImage} />
                          </div>
                          <div
                            className="d-flex px-2 align-items-end"
                            style={{ width: "200px" }}
                          >
                            <span className="font-s-text text-s-primary font-s-regular">
                              {viewer.name}
                            </span>
                          </div>
                          <div className="d-flex px-2 align-items-end">
                            {viewer.is_interested ? (
                              <div
                                data-toggle="tooltip"
                                data-placement="top"
                                title={translator.viewerInterested}
                              >
                                <i className="fas fa-thumbs-up text-s-secondary font-s-medium"></i>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-column mt-1">
                          {viewer.contacts.map((contact) => (
                            <div className="d-flex align-items-baseline px-2">
                              <div style={{ width: "35px" }}></div>
                              <div
                                data-toggle="tooltip"
                                data-placement="top"
                                title={contact.method.name}
                              >
                                <i
                                  className={`${contact.method.icon} font-s-regular text-s-secondary`}
                                ></i>
                              </div>
                              <div className="px-1"></div>
                              <span
                                className="font-s-text font-s-small font-w-s-light text-s-primary text-s-start"
                                style={{ direction: "ltr" }}
                              >
                                {contact.value}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )})}
                  </div>
                  <div className="modal-footer bg-s-cream"></div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <span className="text-s-secondary font-s-text font-s-large">
            {translator.noProperties}
          </span>
        )}
      </MyAccount>
    </React.Fragment>
  );
};

export default MyProperties;
