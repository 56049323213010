import { sign } from "./sign";
import { findBox } from "./find-box";
import { errors } from "./errors";
import { header } from "./header";
import { ads } from "./ads";
import { articles } from "./articles";
import { home } from "./home";
import { others } from './others';

export const translations = {
  ar: {
    findBox: findBox.ar,
    header: header.ar,
    sign: sign.ar,
    errors: errors.ar,
    ads: ads.ar,
    articles: articles.ar,
    home: home.ar,
    others: others.ar,
    validations: {
      empty: "هذا الحقل فارغ ",
    },
  },
  en: {
    findBox: findBox.en,
    header: header.en,
    sign: sign.en,
    errors: errors.en,
    ads: ads.en,
    articles: articles.en,
    home: home.en,
    others: others.en,
    validations: {
      empty: "This field is empty",
    },
  },
};
